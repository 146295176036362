import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {
  PaginatedWorkspaceUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUsers';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceUsersPaginateResolver extends AbstractResolver<PaginatedWorkspaceUsers> {

  private initialPage: number = 0;
  private step: number = 10;

  constructor(spinnerService: SpinnerService,
              private workspaceUserService: WorkspaceUserService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedWorkspaceUsers> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap(currentUser => {
        if (currentUser.isAgentAdmin()) {
          return this.workspaceUserService.findUsers(new WorkspaceUserSearchCriteria(this.initialPage, this.step));
        } else {
          return of(PaginatedWorkspaceUsers.emptyResult());
        }
      }),
      take(1)
    );
  }

}
