import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyWithAgents} from '../../../models/entity/companies/CompanyWithAgents';

@Component({
  selector: 'tr[m-company-with-agents]',
  templateUrl: './m-company-with-agents.component.html',
  styleUrls: ['./m-company-with-agents.component.scss']
})
export class MCompanyWithAgentsComponent {

  @Input()
  public companyWithAgents: CompanyWithAgents;

  @Input()
  public isAgentAdmin: boolean = false;

  private workspaceCode: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  public goToCompanyEditionPage(companyId: string): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'companies', companyId]);
  }
}
