import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {PaginatedWorkspaces} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {WorkspaceService} from '../../services/workspace.service';
import {WorkspacesSearchCriteria} from '../../../shared/models/criterias/WorkspacesSearchCriteria';
import {AbstractResolver} from '../abstract-resolver';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspacesPaginateResolver extends AbstractResolver<PaginatedWorkspaces> {

  private initialPage: number = 0;
  private step: number = 10;

  constructor(spinnerService: SpinnerService, private workspaceService: WorkspaceService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedWorkspaces> {
    return this.workspaceService.search(new WorkspacesSearchCriteria(this.initialPage, this.step)).pipe(take(1));
  }
}
