import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Stats} from '../../shared/models/Stats';

@Injectable()
export class StatsService {

  private baseUrl = '/api/stats';

  constructor(private http: HttpClient) {}

  public getSettings(): Observable<Stats> {
    return this.http.get<Stats>(this.baseUrl + '/settings')
      .pipe(map((principal: any) => plainToClass(Stats, principal as Object)));
  }
}
