import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'a-button-cancel',
    templateUrl: './a-button-cancel.component.html',
    styleUrls: ['./a-button-cancel.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class AButtonCancelComponent implements OnInit {

  // Expanded host binding
  @HostBinding('class._expanded') isExpanded: boolean = false;

  @Output()
  public btnClick = new EventEmitter<void>();

  @Input()
  public loading: false;

  @Input()
  public labelKey: string;

  @Input()
  public expanded: boolean = false;

  @Input()
  public disabled: boolean = false;

  ngOnInit(): void {
    if (this.expanded) {
      this.isExpanded = true;
    }
  }
}
