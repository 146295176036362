import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Directive, Injectable, Input} from '@angular/core';

@Directive({
  selector: '[codeValid]',
  providers: [{provide: NG_VALIDATORS, useExisting: CodeValidValidatorDirective, multi: true}]
})
@Injectable()
export class CodeValidValidatorDirective implements Validator {

  @Input('codeValid') email = '';

  validate(control: AbstractControl) {
    if (!control.value || !control.value.match(new RegExp(/^[A-Za-z0-9]+$/))) {
      const error = {};
      error['codeIsNotValid'] = true;
      return error;
    }
    return true;
  }
}
