import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {AdministrationUserSearchCriteria} from '../../shared/models/criterias/AdministrationUserSearchCriteria';
import {PaginatedAdministrationUsers} from '../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {User, UserWithEmail} from '../../shared/models/entity/users/User';

@Injectable()
export class AdministrationUserService {

  private baseUrl = '/api/administration/users';

  constructor(private http: HttpClient) {}

  public findAll(): Observable<Array<User>> {
    return this.http.get<Array<User>>(this.baseUrl + '/all')
      .pipe(map((principal: any) => plainToClass(User, principal as [Object])));
  }

  public search(administrationUserSearchCriteria: AdministrationUserSearchCriteria): Observable<PaginatedAdministrationUsers> {
    return this.http.get<PaginatedAdministrationUsers>(this.baseUrl, {params: administrationUserSearchCriteria.toHttpParams()})
      .pipe(map((principal: any) => plainToClass(PaginatedAdministrationUsers, principal as Object)));
  }

  public findById(userId: string): Observable<UserWithEmail> {
    return this.http.get<UserWithEmail>(this.baseUrl + `/${userId}`)
      .pipe(map((principal: any) => plainToClass(UserWithEmail, principal as Object)));
  }

  public save(user: UserWithEmail): Observable<UserWithEmail> {
    return user.id ? this.update(user) : this.create(user);
  }

  private update(user: UserWithEmail): Observable<UserWithEmail> {
    return this.http.put<UserWithEmail>(this.baseUrl + `/${user.id}`, user)
      .pipe(map((principal: any) => plainToClass(UserWithEmail, principal as Object)));
  }

  private create(user: UserWithEmail): Observable<UserWithEmail> {
    return this.http.post<UserWithEmail>(this.baseUrl, user)
      .pipe(map((principal: any) => plainToClass(UserWithEmail, principal as Object)));
  }

  public isExistingEmail(email: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + `/emails/${email}/exists`);
  }

  public getSuperAdminEmail(): Observable<string> {
    return this.http.get<string>(this.baseUrl + `/admin/email`);
  }

  public disable(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }
}
