import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordService} from '../../../../app-root/services/password.service';
import {AlertService} from '../../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {noop} from 'rxjs';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {WorkspaceUserWithCompanies} from '../../../models/entity/users/WorkspaceUserWithCompanies';

@Component({
  selector: 'tr[m-agent]',
  templateUrl: './m-agent.component.html',
  styleUrls: ['./m-agent.component.scss']
})
export class MAgentComponent {

  @Input()
  public agent: WorkspaceUserWithCompanies;

  @Input()
  public isAgentAdmin: boolean = false;

  @Input()
  public editable: boolean = false;

  @Output()
  public onAgentDisabled: EventEmitter<void> = new EventEmitter<void>();

  private workspaceCode: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private passwordService: PasswordService,
    private alertService: AlertService,
    private workspaceUserService: WorkspaceUserService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService
  ) {
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code)
  }

  public goToAgentEditionPage(agentId: string): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'agents', agentId]);
  }

  public onDisableAgent(agent: WorkspaceUserWithCompanies): void {
      this.alertService.confirmWithCustomBtn(
        this.translateService.instant('disabled.alert.title.agent'),
        this.translateService.instant('disabled.alert.message.agent', {agent: agent.getFullName()}),
        this.translateService.instant('action.validate'),
        this.translateService.instant('action.cancel')
      ).subscribe(
        () => this.disableAgent(agent),
        () => noop()
      );
  }

  private disableAgent(agent: WorkspaceUserWithCompanies): void {
    this.spinner.show(agent.id);
    this.workspaceUserService.disable(agent.id).subscribe(
      () => {
        this.notificationService.success('disabled.agent.success');
        this.spinner.hide(agent.id);
        this.onAgentDisabled.emit();
      },
      () => {
        this.notificationService.error('disabled.agent.error');
        this.spinner.hide(agent.id);
      }
    );
  }

  public onResetPassword(agent: WorkspaceUserWithCompanies): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('forgotten.password.title'),
      this.translateService.instant('forgotten.password.message', {agent: agent.getFullName()}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')
    ).subscribe(
      () => this.resetPassword(agent),
      () => noop()
    );
  }

  private resetPassword(agent: WorkspaceUserWithCompanies): void {
    this.spinner.show(agent.id);
    this.passwordService.renewUserPassword(agent.email).subscribe(
      () => {
        this.notificationService.success('forgotten.password.success');
        this.spinner.hide(agent.id);
      },
      () => {
        this.notificationService.error('forgotten.password.error');
        this.spinner.hide(agent.id);
      }
    );
  }
}
