import {WorkspaceUserSearchCriteria} from './WorkspaceUserSearchCriteria';
import {Role} from '../entity/enums/Role';
import {HttpParams} from '@angular/common/http';

export class WorkspaceAgentSearchCriteria extends WorkspaceUserSearchCriteria {

  constructor(public page?: number,
              public step?: number,
              public companyId?: string,
              public roles?: Array<Role>,
              public q?: string) {
    super(page, step, companyId, q);
  }

  static newEmptyWithPagination(page: number, step: number) {
    return new WorkspaceAgentSearchCriteria(page, step);
  }

  public toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.roles) {
      this.roles.forEach(el => params = params.append('roles', el));
    }
    return params;
  }
}

export type AGENTS_OR_USER_SEARCH_CRITERIA = WorkspaceAgentSearchCriteria | WorkspaceUserSearchCriteria;
