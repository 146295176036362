import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeResourceUrlPipe} from './safe-resource-url.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';


@NgModule({
  declarations: [
    SafeResourceUrlPipe,
    SafeHtmlPipe
  ],
  exports: [
    SafeResourceUrlPipe,
    SafeHtmlPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule {
}
