import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {AdministrationPageComponent} from './pages/administration/administration-page.component';
import {MatTabsModule} from '@angular/material/tabs';
import {SharedModule} from '../shared/shared.module';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {AdministrationWorkspacePageComponent} from './pages/administration-workspace/administration-workspace-page.component';
import {FormsModule} from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';
import {AdministrationWorkspacesListComponent} from './components/administration-workspaces-list/administration-workspaces-list.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {RouterModule} from '@angular/router';
import {AdministrationUserPageComponent} from './pages/administration-user/administration-user-page.component';
import {AdministrationUsersListComponent} from './components/administration-users-list/administration-users-list.component';
import {DirectivesModule} from '../shared/directives/directives.module';

@NgModule({
  declarations: [
    AdministrationPageComponent,
    AdministrationWorkspacePageComponent,
    AdministrationWorkspacesListComponent,
    AdministrationUserPageComponent,
    AdministrationUsersListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatTabsModule,
    SharedModule,
    BaseComponentsModule,
    FormsModule,
    MatSortModule,
    NgxSpinnerModule,
    RouterModule,
    DirectivesModule
  ]
})
export class AdministrationModule { }
