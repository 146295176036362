import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  Type,
  ViewChild
} from '@angular/core';
import {StepFormComponent} from '../step-form-component';

@Component({
  selector: 'm-multi-step-form',
  templateUrl: './m-multi-step-form.component.html',
  styleUrls: ['./m-multi-step-form.component.scss']
})
export class MMultiStepFormComponent implements OnChanges {

  @Input()
  public stepsConfig: FormStep[];

  @Input()
  public data: any;

  @Input()
  public isValidateShown: boolean;

  @Output()
  public dataChange: EventEmitter<any> = new EventEmitter();

  @Output()
  public validatedValue: EventEmitter<any> = new EventEmitter();

  @Output()
  public resetData: EventEmitter<any> = new EventEmitter();

  @ViewChild('topPage')
  public topPage: ElementRef;

  public activeFormStep: FormStep;
  public activeFormStepIndex = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stepsConfig && changes.stepsConfig.currentValue) {
      this.activeFormStep = this.stepsConfig[0];
      this.activeFormStepIndex = 0;
    }
  }

  public setPreviousStep(): void {
    this.topPage.nativeElement.scrollIntoView({behavior: 'smooth'});

    if (this.activeFormStepIndex > 0) {
      this.activeFormStepIndex--;
      this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
    }
  }

  public setNextStep(): void {
    this.topPage.nativeElement.scrollIntoView({behavior: 'smooth'});

    if (this.activeFormStepIndex < this.stepsConfig.length - 1) {
      this.activeFormStepIndex++;
      this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
    }
  }

  public validateForm(): void {
    this.validatedValue.emit(this.data);
  }

  public resetForm(): void {
    this.resetData.emit();
    this.activeFormStepIndex = 0;
    this.activeFormStep = this.stepsConfig[this.activeFormStepIndex];
  }

  public dataChanged(data: any): void {
    this.dataChange.emit(data);
  }
}

export class FormStep {
  constructor(public labelKey: string, public component: Type<StepFormComponent>) {
  }
}
