import {Component, Input, OnInit} from '@angular/core';
import {PaginatedWorkspaces} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {WorkspacesSearchCriteria} from '../../../shared/models/criterias/WorkspacesSearchCriteria';
import {Sort} from '@angular/material/sort';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {Router} from '@angular/router';
import {WorkspaceService} from '../../../app-root/services/workspace.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {WorkspaceSearchResult} from '../../../shared/models/entity/workspaces/WorkspaceSearchResult';

@Component({
  selector: 'administration-workspaces-list',
  templateUrl: './administration-workspaces-list.component.html',
  styleUrls: ['./administration-workspaces-list.component.scss']
})
export class AdministrationWorkspacesListComponent implements OnInit {

  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;

  @Input()
  private initialWorkspaces: PaginatedWorkspaces;
  public workspaces: Array<WorkspaceSearchResult> = [];
  public workspacesCount: number;

  public workspacesSearchCriteria: WorkspacesSearchCriteria = new WorkspacesSearchCriteria();
  public pageCount: number = 0;
  public currentPage: number = this.DEFAULT_STARTING_PAGE;

  constructor(private router: Router, private workspaceService: WorkspaceService, private spinner: NgxSpinnerService) {}

  public ngOnInit(): void {
    this.workspacesSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
    this.workspacesSearchCriteria.step = this.DEFAULT_STEP;

    if (this.initialWorkspaces) {
      this.workspaces = this.initialWorkspaces.result;
      this.workspacesCount = this.initialWorkspaces.metadata.count;
      this.pageCount = Math.ceil(this.initialWorkspaces.metadata.count / this.DEFAULT_STEP);
    } else {
      this.loadWorkspaces(this.workspacesSearchCriteria, false);
    }
  }

  public sortWorkspaces(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.workspacesSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.workspacesSearchCriteria.sort = sort.active;

    this.loadWorkspaces(this.workspacesSearchCriteria, false);
  }

  public goToAddWorkspacePage(): void {
    this.router.navigate(['/administration/workspaces/new']);
  }

  public goToWorkspaceEditionPage(workspaceId: string): void {
    this.router.navigate(['/administration/workspaces/' + workspaceId]);
  }

  public filter(qSearchCriteria: string): void {
    this.workspacesSearchCriteria.q = qSearchCriteria;
    this.loadWorkspaces(this.workspacesSearchCriteria, true);
  }

  private loadWorkspaces(workspacesSearchCriteria: WorkspacesSearchCriteria, resetPagination: Boolean): void {
    if (resetPagination) {
      this.currentPage = this.DEFAULT_STARTING_PAGE;
      workspacesSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
      workspacesSearchCriteria.step = this.DEFAULT_STEP;
    }
    this.spinner.show();
    this.workspaceService.search(workspacesSearchCriteria).subscribe(
      paginatedWorkspaces => {
        this.workspaces = paginatedWorkspaces.result;
        this.workspacesCount = paginatedWorkspaces.metadata.count;
        this.pageCount = Math.ceil(paginatedWorkspaces.metadata.count / this.DEFAULT_STEP);
        this.spinner.hide();
      }, _ => this.spinner.hide());
  }

  public pageChanged(workspacesSearchCriteria: WorkspacesSearchCriteria): void {
    this.currentPage = workspacesSearchCriteria.page;
    this.loadWorkspaces(this.workspacesSearchCriteria, false);
  }
}
