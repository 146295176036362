import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {SessionService} from '../../../app-root/services/session.service';
import {WorkspaceModification} from '../../../shared/models/entity/workspaces/WorkspaceModification';
import {User} from '../../../shared/models/entity/users/User';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

@Component({
  templateUrl: './administration-workspace-page.component.html',
  styleUrls: ['./administration-workspace-page.component.scss']
})
export class AdministrationWorkspacePageComponent {

  public workspace: WorkspaceModification;
  public currentUser: CompleteCurrentUser;
  public users: Array<User>;

  constructor(route: ActivatedRoute, private router: Router, sessionService: SessionService) {
    combineLatest([
      route.data,
      sessionService.getCurrentUser(),
    ]).subscribe(([data, currentUser]: [{ workspace: WorkspaceModification, users: Array<User> }, CompleteCurrentUser]) => {
      this.workspace = data.workspace;
      this.users = data.users;
      this.currentUser = currentUser;
    });
  }

  public goToAdministrationPage(): void {
    this.router.navigate(['administration'], {fragment: 'workspaces'});
  }
}
