import {HttpParams} from '@angular/common/http';

export class PaginatedCriteria {
  constructor(public page?: number, public step?: number, public sort?: string, public sortOrder?: SortOrder) {}

  public toHttpParams(): HttpParams {
    let params = new HttpParams();

    if (this.page !== null && this.page !== undefined
      && this.step !== null && this.step !== undefined) {
      params = params.set('page', this.page.toString()).set('step', this.step.toString());
    }

    if (this.sort != null) {
      params = params.set('sort', this.sort.toString());
    }

    if (this.sortOrder != null) {
      params = params.set('sortOrder', this.sortOrder.toString());
    }

    return params;
  }
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}
