export interface TokenContext {

  backUrl: string;
  originApp: string;
}

export class ServiceDeskSession {

  public login: string;
  public token: ServiceDeskAuthToken;
  public context: TokenContext;

  constructor(login: string, token: ServiceDeskAuthToken, context: TokenContext = null) {
    this.login = login;
    this.token = token;
    this.context = context;
  }

  public toBearerHeaderValue(): string {
    return btoa(`${this.login}:${this.token.token}`);
  }
}

export class ServiceDeskAuthToken {

  public token: string;
  public timestamp: Date;
  public validUntil: Date;

  constructor(token: string, timestamp: Date, validUntil: Date) {
    this.token = token;
    this.timestamp = timestamp;
    this.validUntil = validUntil;
  }
}

export class ServiceDeskAuthTokenWithLogin extends ServiceDeskAuthToken {

  public login: string;
}
