import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {FeedbackSearchResult} from '../../shared/models/entity/feedbacks/FeedbackSearchResult';
import {CompleteFeedback} from '../../shared/models/entity/feedbacks/CompleteFeedback';

@Injectable()
export class FeedbackService {

  private baseUrl = '/api/feedbacks';

  constructor(private http: HttpClient) {}

  public findCurrent(): Observable<Array<FeedbackSearchResult>> {
    return this.http.get<Array<FeedbackSearchResult>>(this.baseUrl)
      .pipe(map((principal: any) => plainToClass(FeedbackSearchResult, principal as [Object])));
  }

  public findById(feedbackId: string): Observable<CompleteFeedback> {
    return this.http.get<CompleteFeedback>(`${this.baseUrl}/${feedbackId}`)
      .pipe(map((principal: any) => plainToClass(CompleteFeedback, principal as Object)));
  }

  public delete(feedbackId: string): Observable<string> {
    return this.http.delete<string>(`${this.baseUrl}/${feedbackId}`);
  }
}
