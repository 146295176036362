import { Component, ElementRef, HostListener, Input } from '@angular/core';

@Component({
    selector: 'm-add-item',
    templateUrl: './m-add-item.component.html',
    styleUrls: ['./m-add-item.component.scss'],
})
export class MAddItemComponent {

  public actionsOpened = false;

  @Input()
  public disabledBtn: boolean = false;

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public btnIconOpened: string;

  @Input()
  public btnIconClosed: string = 'fas fa-angle-up';

  @Input()
  public faIcon: string;

  @Input()
  public positionDropdown: PositionDropdown = 'BOTTOMLEFT';
  public fromPositionDropdown = fromPositionDropdown;

  constructor(private elementRef: ElementRef) {}

  public toggleActions(): void {
    this.actionsOpened = !this.actionsOpened;
  }

  @HostListener('document:click', ['$event'])
  public onClickOut(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)
      && (event.target.classList == null
        || (!event.target.classList.contains('ng-option') && !event.target.classList.contains('fa-trash')))) {
      this.actionsOpened = false;
    }
  }
}

export type PositionDropdown = 'TOPRIGHT' | 'TOPLEFT' | 'TOPCENTER' | 'BOTTOMRIGHT' | 'BOTTOMLEFT' | 'BOTTOMCENTER' | 'LEFT';

export function fromPositionDropdown(positionDropdown: PositionDropdown): string {
  switch (positionDropdown) {
    case 'TOPRIGHT':
      return '_topRight';
    case 'TOPLEFT':
      return '_topLeft';
    case 'TOPCENTER':
      return '_topCenter';
    case 'BOTTOMRIGHT':
      return '_bottomRight';
    case 'BOTTOMLEFT':
      return '_bottomLeft';
    case 'BOTTOMCENTER':
      return '_bottomCenter';
    case 'LEFT':
      return '_left';
  }
}
