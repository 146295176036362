import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClassificationService} from '../../../../../app-root/services/classification.service';
import {CompanyService} from '../../../../../app-root/services/company.service';
import {Domain} from '../../../../models/entity/classifications/Domain';

@Component({
  selector: 'a-select-domain-multiple-companies',
  templateUrl: './a-select-domain-multiple-companies.component.html',
  styleUrls: ['./a-select-domain-multiple-companies.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectDomainMultipleCompaniesComponent),
      multi: true
    }
  ]
})
export class ASelectDomainMultipleCompaniesComponent implements ControlValueAccessor {

  public domains: Array<Domain> = [];
  public selectedDomain: Domain;
  private onNgChange: (values: Domain) => void;
  private onNgTouched: () => void;
  private _companiesId: Array<string>;

  @Input()
  public name: string = 'domain';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  @Input()
  get companiesId(): Array<string> {
    return this._companiesId;
  }

  set companiesId(companiesId: Array<string>) {
    this._companiesId = companiesId;
    this.injectDomains(companiesId);
  }

  public injectDomains(companiesId: Array<string>): void {
    if (companiesId != null) {
      this.classificationService.findMutualDomainsByCompanies(companiesId)
        .subscribe(domains =>
          this.domains = domains);
    }
  }

  constructor(public classificationService: ClassificationService, public companyService: CompanyService) {}

  public registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  public writeValue(domain: Domain): void {
    this.selectedDomain = domain;
  }

  public onChange(domain: Domain): void {
    this.selectedDomain = domain;
    this.onNgChange(domain);
  }

  public compare(domain1: Domain, domain2: Domain): boolean {
    return domain1 != null && domain2 != null && domain1.id === domain2.id;
  }
}
