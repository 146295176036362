import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService} from '../../../app-root/services/notification.service';
import {AttachmentService} from '../../../app-root/services/attachment.service';
import {NewsService} from '../../../app-root/services/news.service';
import {forkJoin, Observable, of} from 'rxjs';
import {SharedCollection} from '../../../shared/models/entity/enums/SharedCollection';
import {switchMap, tap} from 'rxjs/operators';
import {NgForm} from '@angular/forms';
import {TagService} from '../../../app-root/services/tag.service';
import {News} from '../../../shared/models/entity/News';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {Attachment} from '../../../shared/models/entity/Attachment';

@Component({
  templateUrl: './news-details-page.component.html',
  styleUrls: ['./news-details-page.component.scss']
})
export class NewsDetailsPageComponent implements OnInit {

  public maxFileSize: number = 30000000;

  @ViewChild('form')
  private form: NgForm;

  public news: News;
  private filesToUpload: Array<File> = [];
  private user: CompleteCurrentUser;
  public existingTags: string[] = [];
  public filesChanged: boolean = false;

  constructor(private sessionService: SessionService,
              private route: ActivatedRoute,
              private attachmentService: AttachmentService,
              private notificationService: NotificationService,
              private router: Router,
              private newsService: NewsService,
              private tagService: TagService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(res => this.news = res.news);
    this.sessionService.getCurrentUser().subscribe(user => this.user = user);
    this.tagService.getExistingTagsForNews().subscribe(tags => this.existingTags = tags);
  }

  public fileFromAttachment(attachment: Attachment): File {
    return new File([], attachment.name);
  }

  public addFileToUpload(value: File): void {
    this.filesChanged = true;

    this.news.attachments.push(new Attachment().attachmentFromFile(value));

    // Tricks to detect news update state and trigger setter un m-news component
    this.news = Object.assign({}, this.news);
    this.filesToUpload.push(value);
  }

  public onTagsChange(newTags: Array<string>): void {
    this.news.tags = newTags;
  }

  public onFileRemove(image: File | Attachment): void {
    this.filesChanged = true;

    const iFileToUpload = this.filesToUpload.findIndex((element) => element.name === image.name);
    this.filesToUpload.splice(iFileToUpload, 1);

    const iAttachment = this.news.attachments.findIndex((element) => element.name === image.name);
    this.news.attachments.splice(iAttachment, 1);
  }

  private uploadFiles(newsId: string): Observable<Attachment[]> {
    return this.filesToUpload.length > 0 ?
      forkJoin(this.filesToUpload.map(file =>
        this.attachmentService.uploadAttachment({collection: SharedCollection.NEWS, id: newsId}, file, this.user))) : of([]);
  }

  public submitNews(): void {
    const newsCopyWithoutAttachments = Object.assign({}, this.news);
    newsCopyWithoutAttachments.attachments = [];
    const newsObservable: Observable<News> = !this.news.id ?
      this.newsService.createNews(newsCopyWithoutAttachments).pipe(tap(news => this.news.id = news.id)) :  of(this.news);

    newsObservable.pipe(
      switchMap(news => this.uploadFiles(news.id)),
      tap(() => this.news.attachments = this.news.attachments.filter(item => !item.localFileUrl)),
      tap(attachments => this.news.attachments = this.news.attachments.concat(attachments)),
      switchMap(() => this.newsService.updateNews(this.news))
    ).subscribe(
      () => {
        this.notificationService.success(!newsCopyWithoutAttachments.id ? 'news.create.success' : 'news.edit.success');
        this.goToSettingsPage();
      },
      error => {
        if (error && error.status === 413) {
          this.notificationService.error('notification.attachment.error.REQUEST_ENTITY_TOO_LARGE',
            {error: error.error || error.statusText});
        } else {
          this.notificationService.error(!newsCopyWithoutAttachments.id ? 'news.create.error' : 'news.edit.error');
        }
      });
  }

  public goToSettingsPage(): void {
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'settings', 'news']);
  }
}
