import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-result-number',
  templateUrl: './a-result-number.component.html',
  styleUrls: ['./a-result-number.component.scss']
})
export class AResultNumberComponent {

  @Input()
  public numberKey: string;

}
