export type Size = 'XLARGE' | 'LARGE' | 'DEFAULT' | 'SMALL' | 'TINY';

export function fromSize(size: Size): string {
  switch (size) {
    case 'XLARGE':
      return '_xlarge';
    case 'LARGE':
      return '_large';
    case 'DEFAULT':
      return '_default';
    case 'SMALL':
      return '_small';
    case 'TINY':
      return '_tiny';
  }
}
