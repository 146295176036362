import {Component} from '@angular/core';
import {Domain} from "../../../../shared/models/entity/classifications/Domain";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-customization-tab',
  templateUrl: './customization-tab.component.html',
  styleUrls: ['./customization-tab.component.scss']
})
export class CustomizationTabComponent {

  public domains: Array<Domain>;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.domains = data['domains'];
    });
  }

}
