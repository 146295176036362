import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgForm} from '@angular/forms';
import {AInputMailValidatorComponent} from '../../atoms/forms/a-input-mail-validator/a-input-mail-validator.component';
import {UtilsService} from '../../../../app-root/services/utils.service';
import {AttachmentService} from '../../../../app-root/services/attachment.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {map, switchMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {SharedCollection} from '../../../models/entity/enums/SharedCollection';
import {SessionService} from '../../../../app-root/services/session.service';
import {PaginatedCriteria} from '../../../models/entity/paginated/PaginatedCriteria';
import {RequestService} from '../../../../app-root/services/request.service';
import {AlertService} from '../../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../../models/entity/enums/Role';
import {WorkspaceUserService} from '../../../../app-root/services/workspace-user.service';
import {Company} from '../../../models/entity/companies/Company';
import {WorkspaceUserWithCompanies} from '../../../models/entity/users/WorkspaceUserWithCompanies';
import {WorkspaceUserWithCompaniesModification} from '../../../models/entity/users/WorkspaceUserModification';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {WorkspaceUser} from '../../../models/entity/users/WorkspaceUser';
import {SearchResultRequest} from '../../../models/entity/requests/RequestSearchResult';

@Component({
  selector: 'm-user-form',
  templateUrl: './m-user-form.component.html',
  styleUrls: ['./m-user-form.component.scss']
})
export class MUserFormComponent {

  @ViewChild('emailInputComponent')
  private emailInputComponent: AInputMailValidatorComponent;

  @Input()
  public disabled: boolean = false;

  @Input()
  public title: string = '';

  @Input()
  public companies: Array<Company>;

  @Output()
  public userSaved = new EventEmitter<[WorkspaceUserWithCompanies, PaginatedCriteria]>();

  @Output()
  public userAvatarSaved = new EventEmitter<void>();

  @ViewChild('form')
  public form: NgForm;

  public formSpinnerName: string = 'mUserFormSpinner';
  public initialEmail: string;
  public avatarChanged: boolean = false;
  public avatarToUploadPreview: string;
  public avatarToUpload: File;
  public companiesWithAutoAffectationSorted: Array<Company>; private currentUser: CompleteCurrentUser;
  public userModification: WorkspaceUserWithCompaniesModification;
  public initialCompanies: Array<Company>;
  public isUser: boolean = true;

  private _user: WorkspaceUserWithCompanies;
  private initialAutoAffectedCompanies: Array<Company>;

  @Input()
  get user(): WorkspaceUserWithCompanies {
    return this._user;
  }

  set user(user: WorkspaceUserWithCompanies) {
    this._user = user;
    if (user) {
      this.isUser = this.user.role === Role.USER;
      this.userModification = WorkspaceUserWithCompaniesModification.from(user);
      this.initialCompanies = [ ...this.userModification.companies];
      this.initialAutoAffectedCompanies = [ ...this.userModification.companiesAutoAffected];
      this.companiesWithAutoAffectationSorted = this.userModification.companies
        .sort((a, b) => this.utilsService.compareStrings(a.name, b.name, true));
      this.initialEmail = user.email;
    }
  }

  constructor(private notificationService: NotificationService,
              private spinner: NgxSpinnerService,
              private attachmentService: AttachmentService,
              private workspaceUserService: WorkspaceUserService,
              private utilsService: UtilsService,
              private sessionService: SessionService,
              private requestService: RequestService,
              private alertService: AlertService,
              private translateService: TranslateService) {
    sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  public onUpdate(): void {
    if (this.disabled) {
      this.uploadAvatar(this.userModification.toWorkspaceUserWithCompanies()).subscribe(
        () => {
          this.spinner.hide(this.formSpinnerName);
          this.notificationService.success('settings.me.form.avatar.upload.success');
          this.userAvatarSaved.emit();
        },
        () => this.notificationService.error('settings.me.form.avatar.upload.error')
      );
    } else {
      this.update();
    }
  }

  public update(): void {
    let userTmp: WorkspaceUserWithCompanies;
    this.spinner.show(this.formSpinnerName);
    this.save(this.userModification.toWorkspaceUserWithCompanies()).pipe(
      tap(savedUser => userTmp = savedUser),
      switchMap(() => this.uploadAvatar(userTmp)),
      switchMap(() => this.workspaceUserService.getByUserIdInCurrentWorkspace(userTmp.userId))
    ).subscribe(
      finalUser => {
        this.spinner.hide(this.formSpinnerName);
        this.notificationService.success(this.userModification.workspaceUser.userId ? 'notification.user.updated' : 'notification.user.saved');
        this.user = finalUser;
        this.form.form.markAsPristine();
        this.userSaved.emit([finalUser, new PaginatedCriteria()]);
      },
      error => {
        this.spinner.hide(this.formSpinnerName);
        this.showGenericError(error);
      }
    );
  }

  public save(user: WorkspaceUserWithCompanies): Observable<WorkspaceUserWithCompanies> {
    return this.user.id ? this.workspaceUserService.update(user) : this.workspaceUserService.create(user);
  }

  private uploadAvatar(user: WorkspaceUserWithCompanies): Observable<any> {
    if (this.avatarChanged && this.avatarToUpload) {
        return this.attachmentService.uploadAttachment({
          collection: SharedCollection.USERS,
          id: user.userId
        }, this.avatarToUpload, this.currentUser);
    } else if (this.avatarChanged && !this.avatarToUpload) {
      return this.attachmentService.removeAvatar(user.userId);
    } else {
      return of(null);
    }
  }

  public onAutoAffectedCompaniesChange(companies: Array<Company>): void {
    if (this.companyHasBeenRemoved(this.userModification.companiesAutoAffected, companies)) {
      const removedCompany = this.retrieveDeletedCompany(this.userModification.companiesAutoAffected, companies);

      if (!this.initialAutoAffectedCompanies.map(company => company.id).includes(removedCompany.id)) {
        this.userModification.companiesAutoAffected = companies;
        return;
      }

      this.workspaceUserService.findAutoAffectedAgentsForCompany(removedCompany.id)
        .subscribe(res => {
          if (this.agentIsLastAutoAffected(res, this.user)) {
            this.notificationService.error('user.form.company.deleteError');
            this.revertModificationCompaniesChange();
          } else {
            this.userModification.companiesAutoAffected = companies;
          }
        });
    } else {
      this.userModification.companiesAutoAffected = companies;
    }
  }

  // Needed because the component (select-companies) keeps track of its own dataset,
  // causing incoherences between ours and theirs.
  // Reallocating the variable launches the ngModelChange event, which in turns changes the component dataSet
  // according to ours.
  public revertModificationCompaniesChange(): void {
    this.userModification.companies = [...this.userModification.companies];
    this.userModification.companiesAutoAffected = [...this.userModification.companiesAutoAffected];
  }

  public companyHasBeenRemoved(initial: Array<Company>, newSet: Array<Company>): boolean {
    const initialId = initial.map(company => company.id);
    return newSet.filter(company => initialId.includes(company.id)).length < initial.length;
  }

  public retrieveDeletedCompany(initial: Array<Company>, newSet: Array<Company>): Company {
    const deletedId = initial.map(company => company.id).find(companyId => !newSet.map(company => company.id).includes(companyId));
    return initial.find(company => company.id === deletedId);
  }

  public agentIsLastAutoAffected(agents: Array<WorkspaceUser>, agent: WorkspaceUser): boolean {
    return agents.length === 1 && agents[0].userId === agent.userId;
  }

  public showGenericError(error: any): void {
    this.notificationService.error(this.userModification.workspaceUser.userId ?
      'notification.user.updated.error' : 'notification.user.saved.error', {error: error.error || error.statusText});
  }

  public onCompaniesChange(companies: Array<Company>): void {
    if (this.companyHasBeenRemoved(this.userModification.companies, companies)) {
      const removedCompany = this.retrieveDeletedCompany(this.userModification.companies, companies);

      if (!this.initialCompanies.map(company => company.id).includes(removedCompany.id)) {
        this.updateUserModificationCompanies(companies);
        return;
      }

      forkJoin([
        this.requestService.findRelatedRequestsForCompanyAgent(removedCompany.id, this.user.userId),
        this.workspaceUserService.findAutoAffectedAgentsForCompany(removedCompany.id),
        this.workspaceUserService.findAgentsForCompany(removedCompany.id)
          .pipe(
            map(agents => agents.filter(agent => agent.userId !== this.user.userId))
          )
      ]).subscribe(([relatedRequests, relatedAutoAffectedAgents, agentsOnDeletedCompany]
                            : [Array<SearchResultRequest>, Array<WorkspaceUser>, Array<WorkspaceUser>]) => {
        if (this.agentIsLastAutoAffected(relatedAutoAffectedAgents, this.user)) {
          this.notificationService.error('user.form.company.deleteError');
          this.revertModificationCompaniesChange();
        } else if (relatedRequests.length > 0) {
          this.alertService.confirmWithSelectInput(
            this.translateService.instant('agent.reassignation.title'),
            this.translateService.instant('agent.reassignation.message'),
            this.translateService.instant('agent.reassignation.validate'),
            this.translateService.instant('agent.reassignation.cancel'),
            new Map(agentsOnDeletedCompany.map(agent => [agent.userId, agent.getFullName()]))
          ).subscribe(
            userId => {
              this.workspaceUserService.getByUserIdInCurrentWorkspace(userId).pipe(
                switchMap(user => this.requestService.substituteAgentOnCompany(removedCompany.id, this.user.userId, user.userId))
              ).subscribe(
                _ => {
                  this.notificationService.success('agent.reassignation.success');
                  this.updateUserModificationCompanies(companies);
                  },
                err => this.showGenericError(err)
              );
            },
            () => this.revertModificationCompaniesChange()
          );
        } else {
          this.updateUserModificationCompanies(companies);
        }
      });
    } else {
      this.updateUserModificationCompanies(companies);
    }
  }

  public updateUserModificationCompanies(companies: Array<Company>): void {
    this.userModification.companies = companies;
    this.userModification.companiesAutoAffected =
      this.userModification.companiesAutoAffected.filter(company => companies.map(it => it.id).includes(company.id));
    this.companiesWithAutoAffectationSorted = companies
      .sort((a, b) => this.utilsService.compareStrings(a.name, b.name, true));
  }

  public emailIsValid(): boolean {
    return this.emailInputComponent && this.emailInputComponent.inputNgModel.errors == null;
  }

  public onAvatarAdded(value: any): void {
    this.avatarChanged = true;
    this.avatarToUpload = value as File;
    this.form.form.markAsDirty();
    const reader = new FileReader();
    reader.readAsDataURL(value as Blob);
    reader.onload = (event) => this.avatarToUploadPreview = event.target.result.toString();
  }

  public onAvatarRemoved(): void {
    this.avatarChanged = true;
    this.avatarToUpload = null;
    this.avatarToUploadPreview = null;
    this.user.avatar = null;
    this.form.form.markAsDirty();
  }
}
