import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../app-root/services/session.service';
import {tap} from 'rxjs/operators';
import {CompanyService} from '../../../app-root/services/company.service';
import {CompanySearchCriteria} from '../../../shared/models/criterias/CompanySearchCriteria';
import {PaginatedCompanies} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedCompanies';
import {Observable} from 'rxjs/internal/Observable';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {CompanyWithAgents} from '../../../shared/models/entity/companies/CompanyWithAgents';

@Component({
  selector: 'companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent implements OnInit {

  public currentUser: CompleteCurrentUser;
  public PAGINATOR_STEP: number = 10;
  public companySearchCriteria: CompanySearchCriteria = new CompanySearchCriteria();
  public numberOfCompanyPages: number = 0;
  public DEFAULT_STARTING_PAGE: number = 0;
  public currentSort: Sort = {active: 'BY_NAME', direction: 'asc'};

  @Input()
  public companiesWithAgents: Array<CompanyWithAgents> = [];

  @Input()
  public initialCompanies: PaginatedCompanies;

  public currentPage: number = this.DEFAULT_STARTING_PAGE;

  constructor(private router: Router,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private sessionService: SessionService,
              private companyService: CompanyService
  ) {
    this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  public goToAddCompaniesPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'companies', 'new']);
  }

  public pageChanged(companySearchCriteria: CompanySearchCriteria): void {
    this.currentPage = companySearchCriteria.page;
    this.retrieveCompanies(companySearchCriteria)
      .pipe(
        tap(paginatedCompanies => this.companiesWithAgents = paginatedCompanies.result),
        tap(paginatedCompanies => this.numberOfCompanyPages = Math.ceil(paginatedCompanies.metadata.count / this.PAGINATOR_STEP)),
        tap(_ => this.sortCompaniesWithAgents(this.currentSort)))
      .subscribe();
  }

  public filter(qSearchCriteria: string): void {
    this.companySearchCriteria.q = qSearchCriteria;
    this.loadCompanies(this.companySearchCriteria, true);
  }

  public ngOnInit(): void {
    this.companySearchCriteria.page = this.DEFAULT_STARTING_PAGE;
    this.companySearchCriteria.step = this.PAGINATOR_STEP;
    if (this.initialCompanies) {
      this.companiesWithAgents = this.initialCompanies.result;
      this.numberOfCompanyPages = Math.ceil(this.initialCompanies.metadata.count / this.PAGINATOR_STEP);
    } else {
      this.loadCompanies(this.companySearchCriteria, false);
    }
  }

  public retrieveCompanies(companySearchCriteria: CompanySearchCriteria): Observable<PaginatedCompanies> {
    return this.companyService.findWithAgentsAttachments(companySearchCriteria);
  }

  public loadCompanies(companySearchCriteria: CompanySearchCriteria, resetPagination: boolean): void {
    this.retrieveCompanies(companySearchCriteria)
      .pipe(
        tap(paginatedCompanies => this.companiesWithAgents = paginatedCompanies.result),
        tap(paginatedCompanies => this.numberOfCompanyPages = Math.ceil(paginatedCompanies.metadata.count / this.PAGINATOR_STEP)),
        tap(_ => { if (resetPagination) {
          this.currentPage = this.DEFAULT_STARTING_PAGE;
          this.companySearchCriteria.page = this.DEFAULT_STARTING_PAGE;
          this.companySearchCriteria.step = this.PAGINATOR_STEP;
        }}))
      .subscribe();
  }

  public sortCompaniesWithAgents(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.companySearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.companySearchCriteria.sort = sort.active;
    this.loadCompanies(this.companySearchCriteria, false);
  }
}
