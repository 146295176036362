import {Type} from 'class-transformer';
import {SimpleRequest} from './RequestSimple';
import {RequestCommon} from './RequestCommon';
import {SimpleClassification} from '../classifications/SimpleClassification';

export class SearchResultRequest extends RequestCommon {

  @Type(() => SimpleClassification)
  public classification?: SimpleClassification;
  @Type(() => SimpleRequest)
  public relatedRequests: Array<SimpleRequest> = [];
  public lastMessageDate: Date;
}
