import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'popup',
  styleUrls: ['./popup.component.scss'],
  templateUrl: './popup.component.html'
})
export class PopupComponent {

  @Input()
  public size?: 'full' | 'large' | 'small' | 'tiny' = 'large';

  @Input()
  public unclosable?: boolean = false;

  @Output()
  public onClose = new EventEmitter();

  public close(): void {
    this.onClose.emit();
  }

}
