import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {buildEntityAttachmentUrls} from '../../shared/models/entity/enums/SharedCollection';
import {map} from 'rxjs/operators';
import {Attachment, Reference} from '../../shared/models/entity/Attachment';
import {CompleteCurrentUser} from '../../shared/models/entity/users/CompleteCurrentUser';

@Injectable()
export class AttachmentService {

  constructor(private http: HttpClient) {}

  public uploadAttachment(targetRef: Reference, file: File, user: CompleteCurrentUser): Observable<Attachment> {
    const formData: FormData = new FormData();
    formData.append('authorId', user.id);
    formData.append('file', file, file.name);
    return this.http.post<Attachment>(buildEntityAttachmentUrls(targetRef.collection, targetRef.id).postUrl(), formData);
  }

  public getAttachment(attachment: Attachment): Observable<File> {
    const url = (attachment && !attachment.localFileUrl) ?
      buildEntityAttachmentUrls(attachment.targetRef.collection, attachment.targetRef.id, attachment.id).getUrl() :
      attachment.localFileUrl;

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('responseType', 'arrayBuffer');

    return this.http.get(url, {observe: 'response', responseType: 'arraybuffer', headers: headers})
      .pipe(map(response => new File([response.body], attachment.name, {type: attachment.contentType})));
  }

  public removeAvatar(userId): Observable<void>  {
    return this.http.delete<void>(`/api/attachments/avatar/${userId}`);
  }
}
