import {AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Directive, Injectable, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {WorkspaceService} from '../../app-root/services/workspace.service';

@Directive({
  selector: '[uniqueWorkspaceCode]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueWorkspaceCodeValidatorDirective, multi: true}]
})
@Injectable()
export class UniqueWorkspaceCodeValidatorDirective implements AsyncValidator {

  @Input('uniqueWorkspaceCode') initialCode: string;

  constructor(private workspaceService: WorkspaceService) {
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.existingWorkspaceCode(this.workspaceService)(control);
  }

  existingWorkspaceCode(workspaceService: WorkspaceService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return workspaceService.isExistingCode(control.value)
        .pipe(first())
        .pipe(
          map((isExisting: boolean) => {
            if (isExisting && this.initialCode !== control.value) {
              const error = {};
              error['alreadyExistingCode'] = true;
              return error;
            }
            return null;
          })
        );
    };
  }
}
