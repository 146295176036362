import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CompanySimple} from '../../../../models/entity/companies/CompanySimple';

@Component({
  selector: 'a-select-simple-companies',
  templateUrl: './a-select-simple-companies.component.html',
  styleUrls: ['./a-select-simple-companies.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectSimpleCompaniesComponent),
      multi: true
    }
  ]
})
export class ASelectSimpleCompaniesComponent implements ControlValueAccessor {

  @Input()
  public companies: Array<CompanySimple>;
  public selectedCompanies: Array<CompanySimple>;
  private onNgChange: (values: Array<CompanySimple>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'companies';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  constructor() {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(companies: Array<CompanySimple>): void {
    this.selectedCompanies = companies;
  }

  public onChange(companies: Array<CompanySimple>): void {
    this.selectedCompanies = companies;
    this.onNgChange(companies);
  }

  public compare(company1: CompanySimple, company2: CompanySimple): boolean {
    return company1 != null && company2 != null && company1.id === company2.id;
  }
}
