import { Component, Input } from '@angular/core';

@Component({
    selector: 'a-message',
    templateUrl: './a-message.component.html',
    styleUrls: ['./a-message.component.scss']
})
export class AMessageComponent {

    @Input()
    public position: string = '';
}
