import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SettingsPageComponent} from './pages/settings/settings-page.component';
import {MatTabsModule} from '@angular/material/tabs';
import {SharedModule} from '../shared/shared.module';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {AgentDetailsPageComponent} from './pages/agent-details/agent-details-page.component';
import {FormsModule} from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';
import {CurrentUserProfilePageComponent} from './pages/current-user-profile/current-user-profile-page.component';
import {CompanyDetailsPageComponent} from './pages/company-details/company-details-page.component';
import {NewsDetailsPageComponent} from './pages/news-details/news-details-page.component';
import {AgentsListComponent} from './components/agents-list/agents-list.component';
import {UsersListComponent} from './components/users-list/users-list.component';
import {CompaniesListComponent} from './components/companies-list/companies-list.component';
import {NewsListComponent} from './components/news-list/news-list.component';
import {WorkspaceCustomizationComponent} from './components/workspace-customization/workspace-customization.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {DomainDetailsPageComponent} from './pages/domain-details/domain-details-page.component';
import {ResponseTemplatesListComponent} from './components/response-templates-list/response-templates-list.component';
import {ResponseTemplateDetailsPageComponent} from './pages/response-template-details/response-template-details-page.component';
import {RouterModule} from '@angular/router';
import {UserDetailsPageComponent} from './pages/user-details/user-details-page.component';
import {UserAccountTabComponent} from './pages/tabs/user-account/user-account-tab.component';
import {AgentsTabComponent} from './pages/tabs/agents/agents-tab.component';
import {UsersTabComponent} from './pages/tabs/users/users-tab.component';
import {CompaniesTabComponent} from './pages/tabs/companies/companies-tab.component';
import {NewsTabComponent} from './pages/tabs/news/news-tab.component';
import {TemplatesTabComponent} from './pages/tabs/templates/templates-tab.component';
import {CustomizationTabComponent} from './pages/tabs/customization/customization-tab.component';
import {DirectivesModule} from '../shared/directives/directives.module';

@NgModule({
  declarations: [
    SettingsPageComponent,
    AgentDetailsPageComponent,
    CurrentUserProfilePageComponent,
    CompanyDetailsPageComponent,
    NewsDetailsPageComponent,
    AgentsListComponent,
    UsersListComponent,
    CompaniesListComponent,
    NewsListComponent,
    WorkspaceCustomizationComponent,
    DomainDetailsPageComponent,
    ResponseTemplatesListComponent,
    ResponseTemplateDetailsPageComponent,
    UserDetailsPageComponent,
    UserAccountTabComponent,
    AgentsTabComponent,
    UsersTabComponent,
    CompaniesTabComponent,
    NewsTabComponent,
    TemplatesTabComponent,
    CustomizationTabComponent,
  ],
    imports: [
        CommonModule,
        TranslateModule,
        MatTabsModule,
        SharedModule,
        BaseComponentsModule,
        FormsModule,
        MatSortModule,
        NgxSpinnerModule,
        RouterModule,
        DirectivesModule
    ]
})
export class SettingsModule {
}
