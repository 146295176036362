import {Component, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AttachmentService} from '../../../../app-root/services/attachment.service';
import {NewsPriority} from '../../../models/entity/enums/NewsPriority';
import {News} from '../../../models/entity/News';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'tr[m-news-list-item]',
  templateUrl: './m-news-list-item.component.html',
  styleUrls: ['./m-news-list-item.component.scss']
})
export class MNewsListItemComponent {

  public HIGH_PRIORITY: NewsPriority = NewsPriority.HIGH;

  @Input()
  public isAgentAdmin: boolean = false;

  @Input()
  public news: News;

  private workspaceCode: string;

  constructor(private route: ActivatedRoute, private router: Router, private attachmentService: AttachmentService) {
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  public goToNewsEditionPage(newsId: string): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'news', newsId]);
  }

  public onAttachmentClick(attachment: Attachment): void {
    this.attachmentService.getAttachment(attachment).subscribe(file => {
      const anchor = document.createElement('a');
      anchor.download = file.name;
      anchor.href = URL.createObjectURL(file);
      anchor.click();
    });
  }
}
