import {WorkspaceSimple} from './Workspace';

export class WorkspaceSimpleWithUserState extends WorkspaceSimple {

  public userDisabled: boolean;

  public getNameWithUserState(): string {
    return this.userDisabled ? `${this.name} (Désactivé)` : this.name;
  }

}
