import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ARadioComponent} from './a-radio/a-radio.component';
import {ACheckboxComponent} from './a-checkbox/a-checkbox.component';
import {AInputComponent} from './a-input/a-input.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {DirectivesModule} from '../../../directives/directives.module';
import {AToggleComponent} from './a-toggle/a-toggle.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const components = [
  ACheckboxComponent,
  ARadioComponent,
  AInputComponent,
  AToggleComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    DirectivesModule,
    MatSlideToggleModule
  ],
  declarations: [
    components
  ],
  exports: [
    components
  ]
})
export class UiFormsModule {
}
