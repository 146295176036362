import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {CompleteRequest} from '../../../models/entity/requests/CompleteRequest';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-request-message-text',
  templateUrl: './m-message-text.component.html',
  styleUrls: ['./m-message-text.component.scss']
})
export class MMessageTextComponent {

  @Input()
  set messageText(message: string) {
    this._messageText = message;
    this.useIframe = requiresIframe(this.messageText);
    this.messageLoading = this.useIframe;
    if (this.useIframe) {
      this.spinner.show('message-spinner');
    } else {
      this.spinner.hide('message-spinner');
    }
  }

  get messageText(): string {
    return this._messageText;
  }

  private _messageText: string;

  public useIframe: boolean;
  public messageLoading: boolean;

  @Input()
  public request: CompleteRequest;

  @ViewChild('shadowQuill') shadowQuill: any;
  @ViewChild('shadowDiv') shadowDiv: ElementRef;
  @ViewChild('iframe') iframe: ElementRef;
  public iframeHeight: number = 0;

  constructor(
    private spinner: NgxSpinnerService
  ) {
  }

  iframeLoaded(val: any) {
    this.iframeHeight = val.target.contentDocument.body.scrollHeight;
    this.messageLoading = false;
    this.spinner.hide('message-spinner');
  }
}

function requiresIframe(text: string): boolean {
  return text.includes('<style>');
}
