import {NgModule} from '@angular/core';
import {WorkspacesChoicePageComponent} from './pages/workspaces-choice/workspaces-choice-page.component';
import {SharedModule} from '../shared/shared.module';
import {LinksModule} from '../shared/lib-components/atoms/links/links.module';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonsModule} from '../shared/lib-components/atoms/buttons/buttons.module';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {DirectivesModule} from '../shared/directives/directives.module';

@NgModule({
  declarations: [WorkspacesChoicePageComponent],
  imports: [
    SharedModule,
    LinksModule,
    TranslateModule,
    ButtonsModule,
    BaseComponentsModule,
    DirectivesModule
  ],
  providers: []
})
export class WorkspacesChoiceModule {
}
