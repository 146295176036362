import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
  name: 'resourceSecure'
})
export class ResourceSecurePipe implements PipeTransform {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(url, blobType: string = null): Observable<SafeUrl> {
    return this.http.get(url, {responseType: 'blob'})
      .pipe(
        map(val => new Blob([val], {type: blobType ? blobType : 'image/png'})),
        map(val => this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(val)))
      );
  }
}
