import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {User} from "../../../../models/entity/users/User";

@Component({
  selector: 'a-select-private-message',
  templateUrl: './a-select-private-message.component.html',
  styleUrls: ['./a-select-private-message.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectPrivateMessageComponent),
      multi: true
    }
  ]
})
export class ASelectPrivateMessageComponent implements ControlValueAccessor {

  private _users: Array<User>;
  public displayedUsers: { user: User; fullName: string }[];
  public selectedUsers: { user: User; fullName: string }[];

  private onNgChange: (values: User[]) => void;
  private onNgTouched: () => void;

  private AGENTS_PM_USER: User = new User();

  constructor(translateService: TranslateService) {
    this.AGENTS_PM_USER.id = '-1';
    this.AGENTS_PM_USER.firstName = translateService.instant('request.private.agentsOnly');
    this.AGENTS_PM_USER.lastName = '';
    this.AGENTS_PM_USER.disabled = false;
    this.AGENTS_PM_USER.superAdmin = false;
  }

  @Input()
  get users(): Array<User> {
    return this._users;
  }

  set users(users: Array<User>) {
    this._users = users;
    if (users) {
      this.displayedUsers = [
        {user: this.AGENTS_PM_USER, fullName: this.AGENTS_PM_USER.getFullName()}
      ].concat(users.map(user => (
        {user: user, fullName: user.getFullName()}
      )));
    }
  }

  @Input()
  public name: string = 'user';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(users: User[]): void {
    this.selectedUsers = users ? users.map(user => ({user: user, fullName: user.getFullName()})) : undefined;
  }

  public onChange(usersWithFullName: { user: User; fullName: string }[]): void {
    if (usersWithFullName.find(u => u.user.id === this.AGENTS_PM_USER.id)) {
      if (this.selectedUsers.length === 1 && this.selectedUsers.find(u => u.user.id === this.AGENTS_PM_USER.id)) {
        this.selectedUsers = usersWithFullName.filter(u => u.user.id != this.AGENTS_PM_USER.id);
      } else {
        this.selectedUsers = [{user: this.AGENTS_PM_USER, fullName: this.AGENTS_PM_USER.getFullName()}];
      }
    } else {
      this.selectedUsers = usersWithFullName;
    }
    this.selectedUsers ? this.onNgChange(this.selectedUsers.map(u => u.user)) : this.onNgChange(undefined);
  }
}
