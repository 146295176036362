import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {ButtonsModule} from './atoms/buttons/buttons.module';
import {UiFormsModule} from './atoms/forms/ui-forms.module';
import {LinksModule} from './atoms/links/links.module';
import {ATitleViewComponent} from './atoms/a-title-view/a-title-view.component';
import {AResultNumberComponent} from './atoms/a-result-number/a-result-number.component';
import {ABreadcrumbItemComponent} from './atoms/a-breadcrumb-item/a-breadcrumb-item.component';
import {ATitleSectionComponent} from './atoms/a-title-section/a-title-section.component';
import {ASelectComponent} from './atoms/forms/a-select/a-select.component';
import {ATabComponent} from './atoms/a-tab/a-tab.component';
import {ABadgeComponent} from './atoms/a-badge/a-badge.component';
import {AMessageComponent} from './atoms/a-message/a-message.component';
import {AReadOnlyComponent} from './atoms/a-read-only/a-read-only.component';
import {AAvatarComponent} from './atoms/a-avatar/a-avatar.component';
import {AMentionComponent} from './atoms/a-mention/a-mention.component';
import {AStatusBulletComponent} from './atoms/a-status-bullet/a-status-bullet.component';
import {AStatusPriorityComponent} from './atoms/a-status-priority/a-status-priority.component';
import {MBreadcrumbComponent} from './molecules/m-breadcrumb/m-breadcrumb.component';
import {MTabsComponent} from './molecules/m-tabs/m-tabs.component';
import {MDropdownListComponent} from './molecules/m-dropdown-list/m-dropdown-list.component';
import {MMultiStepFormComponent} from './molecules/multi-step-form/m-multi-step-form/m-multi-step-form.component';
import {MFormStepThreadComponent} from './molecules/multi-step-form/m-form-step-thread/m-form-step-thread.component';
import {MFormStepDetailComponent} from './molecules/multi-step-form/m-form-step-detail/m-form-step-detail.component';
import {MAddItemComponent} from './molecules/m-add-item/m-add-item.component';
import {SpacerComponent} from './spacer/spacer.component';
import {PopupComponent} from './molecules/m-editable-text/popup/popup.component';
import {RouterModule} from '@angular/router';
import {MEditableTextComponent} from './molecules/m-editable-text/m-editable-text.component';
import {MCalloutComponent} from './molecules/m-callout/m-callout.component';
import {ASelectSimpleCompanyComponent} from './atoms/forms/a-select-simple-company/a-select-simple-company.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MMessageComponent} from './molecules/m-message/m-message.component';
import {MRequestComponent} from './molecules/m-request/m-request.component';
import {TranslateModule} from '@ngx-translate/core';
import {ASelectPriorityComponent} from './atoms/forms/a-select-priority/a-select-priority.component';
import {ASelectNewsStatusComponent} from './atoms/forms/a-select-news-status/a-select-news-status.component';
import {ASelectTagsComponent} from './atoms/forms/a-select-tags/a-select-tags.component';
import {MUserFormComponent} from './molecules/m-user-form/m-user-form.component';
import {ASelectAgentRoleComponent} from './atoms/forms/a-select-agent-role/a-select-agent-role.component';
import {ASelectCompaniesComponent} from './atoms/forms/a-select-companies/a-select-companies.component';
import {MAgentComponent} from './molecules/m-agent/m-agent.component';
import {DirectivesModule} from '../directives/directives.module';
import {SecurePipe} from '../../app-root/services/pipes/secure.pipe';
import {AInputMailValidatorComponent} from './atoms/forms/a-input-mail-validator/a-input-mail-validator.component';
import {ASelectUserComponent} from './atoms/forms/a-select-user/a-select-user.component';
import {MNewsComponent} from './molecules/m-news/m-news.component';
import {MAttachmentComponent} from './molecules/m-attachment/m-attachment.component';
import {MAttachmentPreviewComponent} from './molecules/m-attachment-preview/m-attachment-preview.component';
import {MAttachmentButtonComponent} from './molecules/m-attachment-button/m-attachment-button.component';
import {AAttachmentImageComponent} from './atoms/a-attachment-image/a-attachment-image.component';
import {MFileUploadComponent} from './molecules/m-file-upload/m-file-upload.component';
import {MNewsListItemComponent} from './molecules/m-news-list-item/m-news-list-item.component';
import {ASelectNewsPriorityComponent} from './atoms/forms/a-select-news-priority/a-select-news-priority.component';
import {ASelectRequestComponent} from './atoms/forms/a-select-request/a-select-request.component';
import {ASelectStatusComponent} from './atoms/forms/a-select-status/a-select-status.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MUserComponent} from './molecules/m-user/m-user.component';
import {QuillModule} from 'ngx-quill';
import {ATextEditorComponent} from './atoms/forms/a-text-editor/a-text-editor.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MCompanyWithAgentsComponent} from './molecules/m-company-with-agents/m-company-with-agents.component';
import {MCompanyAgentsFormComponent} from './molecules/m-company-agents-form/m-company-agents-form.component';
import {
  AInputCompanyCodeValidatorComponent
} from './atoms/forms/a-input-company-code-validator/a-input-company-code-validator.component';
import {
  ASelectWorkspaceUsersComponent
} from './atoms/forms/a-select-workspace-users/a-select-workspace-users.component';
import {MAvatarListComponent} from './molecules/m-avatar-list/m-avatar-list.component';
import {ASelectStatusesComponent} from './atoms/forms/a-select-statuses/a-select-statuses.component';
import {MFeedbackComponent} from './molecules/m-feedback/m-feedback.component';
import {MDomainFormComponent} from './molecules/m-domain-form/m-domain-form.component';
import {
  ASelectFontawesomeIconComponent
} from './atoms/forms/a-select-fontawesome-icon/a-select-fontawesome-icon.component';
import {ASelectDomainsComponent} from './atoms/forms/a-select-domains/a-select-domains.component';
import {ASelectCategoriesComponent} from './atoms/forms/a-select-categories/a-select-categories.component';
import {ASelectDomainComponent} from './atoms/forms/a-select-domain/a-select-domain.component';
import {ASelectCategoryComponent} from './atoms/forms/a-select-category/a-select-category.component';
import {MDomainIconListComponent} from './molecules/m-domain-icon-list/m-domain-icon-list.component';
import {ADomainIconComponent} from './atoms/a-domain-icon/a-domain-icon.component';
import {MAttachmentListImageComponent} from './molecules/m-attachment-list-image/m-attachment-list-image.component';
import {
  AAttachmentImageOverlayComponent
} from './atoms/a-attachment-image-overlay/a-attachment-image-overlay.component';
import {MPaginatorComponent} from './molecules/m-paginator/m-paginator.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MInputSearchComponent} from './molecules/m-input-search/m-input-search.component';
import {MInputSearchUsersComponent} from './molecules/m-input-search-users/m-input-search-users.component';
import {ASelectDomainIdsComponent} from './atoms/forms/a-select-domain-ids/a-select-domain-ids.component';
import {ASelectCompanyIdComponent} from './atoms/forms/a-select-company-id/a-select-company-id.component';
import {MEventComponent} from './molecules/m-event/m-event.component';
import {MTemplateFormComponent} from './molecules/m-template-form/m-template-form.component';
import {APopupComponent} from './atoms/a-popup/a-popup.component';
import {ModalDirective} from './atoms/a-popup/modal.directive';
import {AAttachmentPreviewComponent} from './atoms/a-attachment-preview/a-attachment-preview.component';
import {ResourceSecurePipe} from '../../app-root/services/pipes/resourceSecure.pipe';
import {MTemplateListItemComponent} from './molecules/m-template/m-template-list-item.component';
import {
  MInputSearchResponseTemplatesComponent
} from './molecules/m-input-search-response-templates/m-input-search-response-templates.component';
import {
  ASelectDomainMultipleCompaniesComponent
} from './atoms/forms/a-select-domain-multiple-companies/a-select-domain-multiple-companies.component';
import {ASelectWorkspaceComponent} from './atoms/forms/a-select-workspace/a-select-workspace.component';
import {MWorkspaceFormComponent} from './molecules/m-workspace-form/m-workspace-form.component';
import {
  AInputWorkspaceCodeValidatorComponent
} from './atoms/forms/a-input-workspace-code-validator/a-input-workspace-code-validator.component';
import {
  MInputSearchAdministrationUsersComponent
} from './molecules/m-input-search-administration-users/m-input-search-administration-users.component';
import {
  AInputMailAdministrationValidatorComponent
} from './atoms/forms/a-input-mail-administration-validator/a-input-mail-administration-validator.component';
import {ASelectWorkspacesComponent} from './atoms/forms/a-select-workspaces/a-select-workspaces.component';
import {
  ASelectSimpleCompaniesComponent
} from './atoms/forms/a-select-simple-companies/a-select-simple-companies.component';
import {ASelectCompanyComponent} from './atoms/forms/a-select-company/a-select-company.component';
import {AAvatarAttachmentComponent} from './atoms/a-avatar-attachment/a-avatar-attachment.component';
import {
  ASelectPrivateMessageComponent
} from './atoms/forms/a-select-private-message/a-select-private-message.component';
import {PipesModule} from './atoms/pipes/pipes.module';
import {MMessageTextComponent} from './molecules/m-message-text/m-message-text.component';

const components: any[] = [
  ASelectCompanyIdComponent,
  ASelectDomainIdsComponent,
  AResultNumberComponent,
  ATitleViewComponent,
  ABreadcrumbItemComponent,
  ATitleSectionComponent,
  ASelectComponent,
  ATabComponent,
  MAttachmentComponent,
  ABadgeComponent,
  MNewsComponent,
  AMessageComponent,
  AMentionComponent,
  AReadOnlyComponent,
  AAvatarComponent,
  ASelectSimpleCompanyComponent,
  AStatusPriorityComponent,
  MDropdownListComponent,
  MBreadcrumbComponent,
  MTabsComponent,
  MMultiStepFormComponent,
  MFormStepThreadComponent,
  MFormStepDetailComponent,
  MEditableTextComponent,
  MNewsListItemComponent,
  MCalloutComponent,
  MAddItemComponent,
  SpacerComponent,
  PopupComponent,
  MMessageComponent,
  MAttachmentPreviewComponent,
  MRequestComponent,
  ASelectPriorityComponent,
  ASelectNewsStatusComponent,
  ASelectNewsPriorityComponent,
  MInputSearchResponseTemplatesComponent,
  ASelectTagsComponent,
  MUserFormComponent,
  ASelectAgentRoleComponent,
  AAttachmentImageComponent,
  ASelectCompaniesComponent,
  AStatusBulletComponent,
  MAgentComponent,
  AInputMailAdministrationValidatorComponent,
  AInputMailValidatorComponent,
  AInputCompanyCodeValidatorComponent,
  ASelectWorkspaceUsersComponent,
  ASelectUserComponent,
  MAttachmentButtonComponent,
  MUserComponent,
  ASelectRequestComponent,
  ASelectStatusComponent,
  ASelectStatusesComponent,
  ATextEditorComponent,
  MCompanyWithAgentsComponent,
  MCompanyAgentsFormComponent,
  MFeedbackComponent,
  MAvatarListComponent,
  MDomainFormComponent,
  ASelectFontawesomeIconComponent,
  ASelectDomainsComponent,
  ASelectDomainComponent,
  ASelectDomainMultipleCompaniesComponent,
  ASelectCategoryComponent,
  ASelectCategoriesComponent,
  MDomainIconListComponent,
  ADomainIconComponent,
  MAttachmentListImageComponent,
  AAttachmentImageOverlayComponent,
  MPaginatorComponent,
  MEventComponent,
  MInputSearchComponent,
  MFileUploadComponent,
  APopupComponent,
  ModalDirective,
  MTemplateFormComponent,
  ASelectWorkspaceComponent,
  ASelectWorkspacesComponent,
  MWorkspaceFormComponent,
  AInputWorkspaceCodeValidatorComponent,
  MInputSearchAdministrationUsersComponent,
  ASelectSimpleCompaniesComponent,
  ASelectCompanyComponent,
  AAvatarAttachmentComponent,
  ASelectPrivateMessageComponent
];

const subModules: any = [
  UiFormsModule,
  ButtonsModule,
  LinksModule,
  PipesModule
];

@NgModule({
  declarations: [
      components,
      ResourceSecurePipe,
      SecurePipe,
      MFileUploadComponent,
      MFileUploadComponent,
      MAttachmentListImageComponent,
      MInputSearchComponent,
      MInputSearchUsersComponent,
      AAttachmentPreviewComponent,
      MTemplateListItemComponent,
      MMessageTextComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    subModules,
    RouterModule,
    NgSelectModule,
    TranslateModule,
    DirectivesModule,
    MatTooltipModule,
    QuillModule,
    NgxSpinnerModule,
    MatButtonToggleModule
  ],
  exports: [
    components,
    subModules,
    ResourceSecurePipe,
    SecurePipe,
    MFileUploadComponent,
    MInputSearchComponent,
    MInputSearchUsersComponent,
    MTemplateListItemComponent,
    MMessageTextComponent
  ],
  providers: []
})

export class BaseComponentsModule {
}
