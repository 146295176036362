import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {noop, of} from 'rxjs';
import {NotificationService} from '../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {RequestService} from '../../../app-root/services/request.service';
import {
  QualificationDataModification,
  UserQualification
} from '../../../shared/models/entity/feedbacks/QualificationData';
import {AlertService} from '../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {CompleteFeedback} from '../../../shared/models/entity/feedbacks/CompleteFeedback';
import {Company} from '../../../shared/models/entity/companies/Company';
import {Domain} from '../../../shared/models/entity/classifications/Domain';
import {CompanySimple} from '../../../shared/models/entity/companies/CompanySimple';
import {SessionService} from '../../../app-root/services/session.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

@Component({
  templateUrl: './feedback-details-page.component.html',
  styleUrls: ['./feedback-details-page.component.scss']
})
export class FeedbackDetailsPageComponent implements OnInit {

  public currentUser: CompleteCurrentUser;
  public qualificationDataModification: QualificationDataModification;
  public feedback: CompleteFeedback;
  public companies: Array<CompanySimple>;

  constructor(private sessionService: SessionService,
              private route: ActivatedRoute,
              private notificationService: NotificationService,
              private spinner: NgxSpinnerService,
              private requestService: RequestService,
              private router: Router,
              private alertService: AlertService,
              private translateService: TranslateService
  ) {
    this.route.data.subscribe((data: { feedback: CompleteFeedback, allCompanies: Array<Company> }) => {
      this.feedback = data.feedback;
      this.companies = data.allCompanies.map(company => company.toSimple());
      this.qualificationDataModification = new QualificationDataModification(this.feedback);
    });
  }

  ngOnInit() {
    this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  public qualifyFeedback(): void {
    this.spinner.show();
    this.requestService.createFromFeedback(this.feedback.id, this.qualificationDataModification).subscribe(
      request => {
        this.spinner.hide();
        this.notificationService.success('feedback.qualification.success', {code : request.code});
        this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'feedbacks']);
      },
      _ => {
        this.spinner.hide();
        this.notificationService.error('feedback.qualification.error');
        return of(null);
      }
    );
  }

  public onDeleteUnknownUser(userToDelete: UserQualification): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('feedback.unknownUser.delete.popup.title'),
      this.translateService.instant('feedback.unknownUser.delete.popup.message'),
      this.translateService.instant('feedback.unknownUser.delete.popup.validate'),
      this.translateService.instant('feedback.unknownUser.delete.popup.cancel')
    ).subscribe(
      () => {
        this.qualificationDataModification.unknownUsers = this.qualificationDataModification.unknownUsers
          .filter(el => el.email !== userToDelete.email);
      },
      () => noop()
    );
  }

  public onDomainChange(domain: Domain): void {
    this.qualificationDataModification.domain = domain;
    this.qualificationDataModification.category = null;
  }

  public onSelectedCompanyChange(selectedCompany: CompanySimple) {
    this.qualificationDataModification.commonCompany = selectedCompany;
    this.onDomainChange(null);
  }
}
