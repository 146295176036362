import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-title-section',
  templateUrl: './a-title-section.component.html',
  styleUrls: ['./a-title-section.component.scss']
})
export class ATitleSectionComponent  {

  @Input()
  public titleSectionKey: string;

  @Input()
  public titleSectionKeyI18nParams: any;

  @Input()
  public color: string;

  @Input()
  public inCardDivider: boolean = false;

  @Input()
  public largeText: boolean = false;
}
