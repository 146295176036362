import {Injectable} from '@angular/core';
import {ServiceDeskSession} from '../../shared/models/ServiceDeskSession';
import {classToPlain, plainToClass} from 'class-transformer';

const SESSION_STORAGE_KEY = '__SERVICE_DESK_USER__';

@Injectable()
export class BaererSessionService {

  private localStorage: Storage;

  constructor() {
    this.localStorage = window.localStorage;
  }

  public current(): ServiceDeskSession {
    const session = JSON.parse(this.localStorage.getItem(SESSION_STORAGE_KEY));
    if (session) {
      return plainToClass(ServiceDeskSession, session as Object);
    }
    return null;
  }

  public store(session: ServiceDeskSession): void {
    this.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(classToPlain(session)));
  }

  public clear() {
    this.localStorage.removeItem(SESSION_STORAGE_KEY);
  }
}
