import {Component, ElementRef, HostListener, Input} from '@angular/core';

@Component({
    selector: 'm-dropdown-list',
    templateUrl: './m-dropdown-list.component.html',
    styleUrls: ['./m-dropdown-list.component.scss'],
})
export class MDropdownListComponent {

  public actionsOpened = false;

  @Input()
  public closeOnClick: boolean = true;

  @Input()
  public positionDropdown: PositionDropdown = 'BOTTOMLEFT';
  public fromPositionDropdown = fromPositionDropdown;

  constructor(private elementRef: ElementRef) {}

  public toggleActions(): void {
    this.actionsOpened = !this.actionsOpened;
  }

  @HostListener('document:click', ['$event'])
  public onClickOut(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)
      && (event.target.classList == null || !event.target.classList.contains('ng-option'))) {
      this.actionsOpened = false;
    }
  }

  public onClickIn(): void {
    if (this.closeOnClick) {
      this.actionsOpened = false;
    }
  }
}

export type PositionDropdown = 'TOPRIGHT' | 'TOPLEFT' | 'TOPCENTER' | 'BOTTOMRIGHT' | 'BOTTOMLEFT' | 'BOTTOMCENTER' | 'LEFT';

export function fromPositionDropdown(positionDropdown: PositionDropdown): string {
  switch (positionDropdown) {
    case 'TOPRIGHT':
      return '_topRight';
    case 'TOPLEFT':
      return '_topLeft';
    case 'TOPCENTER':
      return '_topCenter';
    case 'BOTTOMRIGHT':
      return '_bottomRight';
    case 'BOTTOMLEFT':
      return '_bottomLeft';
    case 'BOTTOMCENTER':
      return '_bottomCenter';
    case 'LEFT':
      return '_left';
  }
}
