import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Domain} from '../../../shared/models/entity/classifications/Domain';
import {WorkspaceSimple} from "../../../shared/models/entity/workspaces/Workspace";

@Component({
  templateUrl: './domain-details-page.component.html',
  styleUrls: ['./domain-details-page.component.scss']
})
export class DomainDetailsPageComponent implements OnInit {

  public domain: Domain;

  private workspaceCode: string;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: { domain: Domain }) => {
      if (data.domain) { this.domain = data.domain; }
    });
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  public onDomainSaved(): void {
    this.goToSettingsPage();
  }

  public goToSettingsPage(): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'customization']);
  }
}
