import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkspaceUserWithCompanies} from '../../../shared/models/entity/users/WorkspaceUserWithCompanies';
import {Company} from '../../../shared/models/entity/companies/Company';
import {dashboardRoutes} from "../../../dashboard/dashboard-routing.module";

@Component({
  templateUrl: './agent-details-page.component.html',
  styleUrls: ['./agent-details-page.component.scss']
})
export class AgentDetailsPageComponent implements OnInit {

  public agent: WorkspaceUserWithCompanies;
  public allCompanies: Array<Company>;

  private workspaceCode: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  ngOnInit(): void {
    this.route.data.subscribe((data: { agent: WorkspaceUserWithCompanies, allCompanies: Array<Company> }) => {
      if (data.agent) { this.agent = data.agent; }
      if (data.allCompanies) { this.allCompanies = data.allCompanies; }
    });
  }

  public onAgentSaved(): void {
    this.goToSettingsPage();
  }

  public goToSettingsPage(): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'agents']);
  }
}
