import { Directive, ElementRef } from '@angular/core';
import { Reveal } from 'foundation-sites/js/foundation.reveal';
import $ from 'jquery';

@Directive({ selector: '[modal]' })
export class ModalDirective {

    private popup: any;

    constructor(private el: ElementRef) { }

    public open(): void {
        this.popup = new Reveal($(this.el.nativeElement));
        this.popup.open();
    }

    public close(): void {
        if (this.popup) {
            this.popup.destroy();
        }
    }
}
