import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {WorkspaceService} from '../../services/workspace.service';
import {WorkspaceModification} from '../../../shared/models/entity/workspaces/WorkspaceModification';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceModificationResolver extends AbstractResolver<WorkspaceModification> {

  constructor(spinnerService: SpinnerService, private workspaceService: WorkspaceService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkspaceModification> {
    const workspaceId = route.paramMap.get('workspaceId');
    return workspaceId ?
      this.workspaceService.findWorkspaceModification(workspaceId).pipe(take(1)) :
      of(WorkspaceModification.new()).pipe(take(1));
  }
}
