import {AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Directive, Injectable, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {AdministrationUserService} from '../../app-root/services/administration-user.service';

@Directive({
  selector: '[uniqueEmailAdministration]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueEmailAdministrationValidatorDirective, multi: true}]
})
@Injectable()
export class UniqueEmailAdministrationValidatorDirective implements AsyncValidator {

  @Input('uniqueEmailAdministration') initialEmail: string;

  constructor(private administrationUserService: AdministrationUserService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.existingUserEmail(this.administrationUserService)(control);
  }

  existingUserEmail(administrationUserService: AdministrationUserService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return administrationUserService.isExistingEmail(control.value)
        .pipe(first())
        .pipe(
          map((isExisting: boolean) => {
            if (isExisting && this.initialEmail !== control.value) {
              const error = {};
              error['alreadyExistingEmail'] = true;
              return error;
            }
            return null;
          })
        );
    };
  }
}
