import {Component, Input} from '@angular/core';
import {Event} from '../../../models/entity/requests/Event';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'm-event',
  templateUrl: './m-event.component.html',
  styleUrls: ['./m-event.component.scss']
})
export class MEventComponent<EVENT extends Event> {

  @Input()
  public event: EVENT;

  constructor(public translateService: TranslateService) {}
}
