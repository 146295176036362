import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {DashboardPageComponent} from './pages/dashboard-page.component';
import {SharedModule} from '../shared/shared.module';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {FormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DirectivesModule} from '../shared/directives/directives.module';

@NgModule({
  declarations: [DashboardPageComponent],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        BaseComponentsModule,
        FormsModule,
        MatTooltipModule,
        NgxSpinnerModule,
        DirectivesModule
    ]
})
export class DashboardModule { }
