import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {plainToClass} from 'class-transformer';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {Role} from '../../shared/models/entity/enums/Role';
import {WorkspaceUserSearchCriteria} from '../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {PaginatedWorkspaceUsers} from '../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUsers';
import {WorkspaceAgentSearchCriteria} from '../../shared/models/criterias/WorkspaceAgentSearchCriteria';
import {WorkspaceUserWithCompanies} from '../../shared/models/entity/users/WorkspaceUserWithCompanies';
import {WorkspaceUser} from '../../shared/models/entity/users/WorkspaceUser';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceUserService {

  constructor(private http: HttpClient) { }

  private baseUrl = '/api/workspaceUsers';

  public getByUserIdInCurrentWorkspace(userId: string): Observable<WorkspaceUserWithCompanies> {
    return this.http.get<WorkspaceUserWithCompanies>(`${this.baseUrl}/users/${userId}/workspaces/current`).pipe(
      map(workspaceUser => plainToClass(WorkspaceUserWithCompanies, workspaceUser))
    );
  }

  public isExistingEmailInWorkspace(email: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/email/${email}/exists`);
  }

  public getUserByUserIdInCurrentWorkspace(userId: string): Observable<WorkspaceUserWithCompanies> {
    return this.getByUserIdInCurrentWorkspace(userId)
      .pipe(filter((user: WorkspaceUserWithCompanies) => user.role === Role.USER ));
  }

  public getAgentByUserIdInCurrentWorkspace(userId: string): Observable<WorkspaceUserWithCompanies> {
    return this.getByUserIdInCurrentWorkspace(userId)
      .pipe(filter((user: WorkspaceUserWithCompanies) => user.role !== Role.USER ));
  }

  public update(user: WorkspaceUserWithCompanies): Observable<WorkspaceUserWithCompanies> {
    return this.http.put<WorkspaceUserWithCompanies>(`${this.baseUrl}`, user).pipe(
      map(workspaceUser => plainToClass(WorkspaceUserWithCompanies, workspaceUser))
    );
  }

  public create(user: WorkspaceUserWithCompanies): Observable<WorkspaceUserWithCompanies> {
    return this.http.post<WorkspaceUserWithCompanies>(`${this.baseUrl}`, user).pipe(
      map(workspaceUser => plainToClass(WorkspaceUserWithCompanies, workspaceUser))
    );
  }

  public findAutoAffectedAgentsForCompany(companyId: string): Observable<Array<WorkspaceUser>> {
    return this.http.get<Array<WorkspaceUser>>(`${this.baseUrl}/company/${companyId}/agents/autoAffected`)
      .pipe(map((workspaceUsers: any) => plainToClass(WorkspaceUser, workspaceUsers as [Object])));
  }

  public findAgentsForCompany(companyId: string): Observable<Array<WorkspaceUser>> {
    return this.http.get<Array<WorkspaceUser>>(`${this.baseUrl}/company/${companyId}/agents`)
      .pipe(map((workspaceUsers: any) => plainToClass(WorkspaceUser, workspaceUsers as [Object])));
  }

  public findUsersForCompany(companyId: string): Observable<Array<WorkspaceUser>> {
    return this.http.get<Array<WorkspaceUser>>(`${this.baseUrl}/company/${companyId}/users`)
      .pipe(map((workspaceUsers: any) => plainToClass(WorkspaceUser, workspaceUsers as [Object])));
  }

  public findAgents(workspaceAgentSearchCriteria: WorkspaceAgentSearchCriteria): Observable<PaginatedWorkspaceUsers> {
    return this.http.get<PaginatedWorkspaceUsers>(`${this.baseUrl}/agents`, {params: workspaceAgentSearchCriteria.toHttpParams()})
      .pipe(map(paginatedWorkspaceUsers => plainToClass(PaginatedWorkspaceUsers, paginatedWorkspaceUsers)));
  }

  public findUsers(workspaceUserSearchCriteria: WorkspaceUserSearchCriteria): Observable<PaginatedWorkspaceUsers> {
    return this.http.get<PaginatedWorkspaceUsers>(`${this.baseUrl}/users`, {params: workspaceUserSearchCriteria.toHttpParams()})
      .pipe(map(paginatedWorkspaceUsers => plainToClass(PaginatedWorkspaceUsers, paginatedWorkspaceUsers)));
  }

  public disable(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${id}`);
  }

  public findAutoAffectedAgentsForCurrentCompany(): Observable<Array<WorkspaceUser>> {
      return this.http.get<Array<WorkspaceUser>>(`${this.baseUrl}/company/current/agents/autoAffected`)
        .pipe(map((workspaceUsers: any) => plainToClass(WorkspaceUser, workspaceUsers as [Object])));
  }

  public getAdminAgentsByWorkspaceId(workspaceId: string): Observable<Array<WorkspaceUser>> {
    return this.http.get<Array<WorkspaceUser>>(`${this.baseUrl}/workspace/${workspaceId}/admins`)
      .pipe(map((workspaceUsers: any) => plainToClass(WorkspaceUser, workspaceUsers as [Object])));
  }
}
