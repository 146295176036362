import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {NewsStatus} from '../entity/enums/NewsStatus';
import {NewsPriority} from '../entity/enums/NewsPriority';

export class NewsSearchCriteria extends PaginatedCriteria {

  public tags?: Array<string>;
  public status?: NewsStatus;
  public priority?: NewsPriority;
  public companyDomainsId?: Array<string>;
  public q?: string;
  public workspaceCode?: string = null;

  public toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.priority) {
      params = params.set('priority', this.priority);
    }

    if (this.status) {
      params = params.set('status', this.status);
    }

    if (this.tags) {
      this.tags.forEach(tag => params = params.append('tags', tag));
    }

    if (this.companyDomainsId) {
      this.companyDomainsId.forEach(domain => {
        return params = params.append('companyIds', domain);
      });
    }

    if (this.q) {
      params = params.set('q', this.q);
    }

    if (this.workspaceCode) {
      params = params.set('workspaceCode', this.workspaceCode);
    }

    return params;
  }
}
