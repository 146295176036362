import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {catchError, map} from 'rxjs/operators';
import {DashboardCustomization} from '../../shared/models/entity/workspaces/DashboardCustomization';
import {of, throwError} from 'rxjs';
import {plainToClass} from 'class-transformer';
import {PaginatedWorkspaces} from '../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {WorkspacesSearchCriteria} from '../../shared/models/criterias/WorkspacesSearchCriteria';
import {LoginCustomization} from '../../shared/models/entity/workspaces/LoginCustomization';
import {WorkspaceModification} from '../../shared/models/entity/workspaces/WorkspaceModification';
import {WorkspaceSimple} from '../../shared/models/entity/workspaces/Workspace';

@Injectable()
export class WorkspaceService {

  constructor(private http: HttpClient) {}

  private baseUrl = '/api/workspaces';
  private basePublicUrl = '/api/public/workspaces';

  public getLoginCustomization(workspaceCode: string): Observable<LoginCustomization> {
    return this.http.get<LoginCustomization>(`${this.basePublicUrl}/login/${workspaceCode}`)
      .pipe(map((principal: any) => plainToClass(LoginCustomization, principal as Object)));
  }

  public getCurrentDashboardCustomization(): Observable<DashboardCustomization> {
    return this.http.get(`${this.baseUrl}/dashboard`).pipe(
      map(dashboardCustom => plainToClass(DashboardCustomization, dashboardCustom)),
      catchError( err => err.status === 404 ? of(null) : throwError(err))
    );
  }

  public search(workspacesSearchCriteria: WorkspacesSearchCriteria): Observable<PaginatedWorkspaces> {
    return this.http.get<PaginatedWorkspaces>(this.baseUrl, {params: workspacesSearchCriteria.toHttpParams()})
      .pipe(map((principal: any) => plainToClass(PaginatedWorkspaces, principal as Object)));
  }

  public findWorkspaceModification(workspaceId: string): Observable<WorkspaceModification> {
    return this.http.get<WorkspaceModification>(`${this.baseUrl}/modification/${workspaceId}`)
      .pipe(map((principal: any) => plainToClass(WorkspaceModification, principal as Object)));
  }

  public update(workspace: WorkspaceModification): Observable<WorkspaceModification> {
    return this.http.put<WorkspaceModification>(this.baseUrl + `/modification/${workspace.id}`, workspace)
      .pipe(map((principal: any) => plainToClass(WorkspaceModification, principal as Object)));
  }

  public create(workspace: WorkspaceModification): Observable<WorkspaceModification> {
    return this.http.post<WorkspaceModification>(this.baseUrl + '/modification', workspace)
      .pipe(map((principal: any) => plainToClass(WorkspaceModification, principal as Object)));
  }

  public isExistingCode(code: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + `/code/${code}/exists`);
  }

  public getWorkspacesForTransfer(): Observable<Array<WorkspaceSimple>> {
    return this.http.get<Array<WorkspaceSimple>>(`${this.baseUrl}/transfer`)
      .pipe(map((principal: any) => plainToClass(WorkspaceSimple, principal as [Object])));
  }
}
