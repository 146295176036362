import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {NewsService} from '../../services/news.service';
import {PaginatedNews} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedNews';
import {NewsSearchCriteria} from '../../../shared/models/criterias/NewsSearchCriteria';
import {SessionService} from '../../services/session.service';
import {switchMap, take} from 'rxjs/operators';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceNewsResolver extends AbstractResolver<PaginatedNews> {

  private initialPage: number = 0;
  private step: number = 10;

  constructor(spinnerService: SpinnerService,
              private newsService: NewsService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedNews> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap(currentUser => {
        if (currentUser.isAgentAdmin()) {
          return this.newsService.find(new NewsSearchCriteria(this.initialPage, this.step));
        } else {
          return of(PaginatedNews.emptyResult());
        }
      })
    ).pipe(
      take(1)
    );
  }
}
