import {Component, Input, ViewChild} from '@angular/core';
import {AAttachmentPreviewComponent} from '../../atoms/a-attachment-preview/a-attachment-preview.component';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'm-attachment',
  templateUrl: './m-attachment.component.html',
  styleUrls: ['./m-attachment.component.scss']
})
export class MAttachmentComponent {

  @Input()
  public forceButton: boolean = false;

  @Input()
  public localFileUrl: string;

  @Input()
  public attachment: Attachment;

  @ViewChild('contentPreview')
  public popup: AAttachmentPreviewComponent;

  public onAttachmentClick(): void {
    /**this.popup.open();**/
    this.popup.open();
  }
}
