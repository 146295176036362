import {RequestPriority} from '../entity/enums/RequestPriority';
import {RequestStatus} from '../entity/enums/RequestStatus';
import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria, SortOrder} from '../entity/paginated/PaginatedCriteria';
import {Params} from '@angular/router';
import {isArray} from 'rxjs/internal-compatibility';

export class RequestSearchCriteria extends PaginatedCriteria {

  public tags?: Array<string>;
  public q?: string;
  public priority?: RequestPriority;
  public statuses?: Array<RequestStatus>;
  public domainIds?: Array<string>;
  public categoryIds?: Array<string>;
  public companyId?: string; // Note : We have a list on server side because we enforce visible companies if no company set
  public code?: string;
  public workspaceCode?: string = null;
  public includeOnlyAssignedRequest?: Boolean;

  static isEmpty(searchCriteria: RequestSearchCriteria, excludeQ: boolean): boolean {
    if (!searchCriteria) {
      return true;
    }

    return (!searchCriteria.tags || searchCriteria.tags.length === 0) && !searchCriteria.priority &&
      (!searchCriteria.statuses || searchCriteria.statuses.length === 0) && !searchCriteria.companyId &&
      (!searchCriteria.domainIds || searchCriteria.domainIds.length === 0) &&
      (!searchCriteria.categoryIds || searchCriteria.categoryIds.length === 0) &&
      (!searchCriteria.code || searchCriteria.code === '') && !searchCriteria.includeOnlyAssignedRequest &&
      (excludeQ || (!searchCriteria.q || searchCriteria.q === ''));
  }

  static toHttpParams(searchCriteria: RequestSearchCriteria): HttpParams {
    let params = RequestSearchCriteria.toHttpParamsWithoutPagination(searchCriteria);

    if (searchCriteria.page !== null && searchCriteria.page !== undefined
      && searchCriteria.step !== null && searchCriteria.step !== undefined) {
      params = params.set('page', searchCriteria.page.toString()).set('step', searchCriteria.step.toString());
    }

    if (searchCriteria.sort != null) {
      params = params.set('sort', searchCriteria.sort);
    }

    if (searchCriteria.sortOrder != null) {
      params = params.set('sortOrder', searchCriteria.sortOrder.toString());
    }

    if (searchCriteria.workspaceCode) {
      params = params.set('workspaceCode', searchCriteria.workspaceCode);
    }

    return params;
  }

  static toHttpParamsWithoutPagination(searchCriteria: RequestSearchCriteria): HttpParams {
    let params = new HttpParams();

    if (searchCriteria.q) {
      params = params.set('q', searchCriteria.q);
    }
    if (searchCriteria.priority) {
      params = params.set('priority', searchCriteria.priority);
    }
    if (searchCriteria.statuses) {
      searchCriteria.statuses.forEach(status => params = params.append('statuses', status));
    }
    if (searchCriteria.domainIds) {
      searchCriteria.domainIds.forEach(domainId => params = params.append('domainIds', domainId));
    }
    if (searchCriteria.categoryIds) {
      searchCriteria.categoryIds.forEach(categoryId => params = params.append('categoryIds', categoryId));
    }
    if (searchCriteria.companyId) {
      params = params.set('companyIds', searchCriteria.companyId);
    }
    if (searchCriteria.tags) {
      searchCriteria.tags.forEach(tag => params = params.append('tags', tag));
    }
    if (searchCriteria.code) {
      params = params.set('code', searchCriteria.code);
    }
    if (searchCriteria.includeOnlyAssignedRequest) {
      params = params.set('includeOnlyAssignedRequest', searchCriteria.includeOnlyAssignedRequest.toString());
    }

    return params;
  }

  static fromParams(params: Params, defaultStep: number, defaultPage: number): RequestSearchCriteria {
    const criteria = new RequestSearchCriteria();
    criteria.statuses = !params.statuses ? [] : Array.isArray(params.statuses) ?
      params.statuses.map(statusStr => RequestStatus[statusStr]) : [(RequestStatus)[params.statuses]];
    criteria.priority = params.priority;
    criteria.q = params.q;
    criteria.code = params.code;
    criteria.includeOnlyAssignedRequest =
      params.includeOnlyAssignedRequest ? JSON.parse(params.includeOnlyAssignedRequest) : null;
    criteria.categoryIds = params.categoryIds && !isArray(params.categoryIds) ? [params.categoryIds] : params.categoryIds;
    criteria.companyId = params.companyId;
    criteria.domainIds = params.domainIds && !isArray(params.domainIds) ? [params.domainIds] : params.domainIds;
    criteria.tags = params.tags && !isArray(params.tags) ? [params.tags] : params.tags;
    criteria.step = params.step ? +params.step : defaultStep;
    criteria.page = params.page ? +params.page : defaultPage;
    criteria.sort = params.sort ? params.sort : 'BY_CREATION_DATE';
    criteria.sortOrder = params.sortOrder ? SortOrder[params.sortOrder] : SortOrder.DESC;
    return criteria;
  }

  static toCleanQueryParams(request: RequestSearchCriteria): Params {
    const queryParams = Object.assign({}, request);
    Object.keys(queryParams).forEach(k => {
      if (queryParams[k] === null || queryParams[k] === undefined
        || queryParams[k] === '' || queryParams[k].length === 0) {
        delete queryParams[k];
      }});
    return queryParams;
  }
}
