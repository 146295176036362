import {Component, Input, ViewChild} from '@angular/core';
import {ModalDirective} from '../a-popup/modal.directive';
import {AttachmentService} from '../../../../app-root/services/attachment.service';
import {buildEntityAttachmentUrls} from '../../../models/entity/enums/SharedCollection';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'a-attachment-preview',
  templateUrl: './a-attachment-preview.component.html',
  styleUrls: ['./a-attachment-preview.component.scss']
})
export class AAttachmentPreviewComponent {

  private _attachment: Attachment;
  public fileUrl: string = '';
  public file: File;
  public isImageOrPdf: boolean = false;
  public pngMimeType = 'image/png';
  public pdfMimeType = 'application/pdf';
  private authorizedFileTypes = [this.pdfMimeType, 'image/jpeg', this.pngMimeType];


  @Input()
  public set attachment(attachment: Attachment) {
    this._attachment = attachment;
    this.fileUrl = buildEntityAttachmentUrls(
      this._attachment.targetRef.collection,
      this._attachment.targetRef.id,
      this._attachment.id).getUrl();

    this.attachmentService.getAttachment(this._attachment).subscribe(file => {
      this.file = file;
      this.isImageOrPdf = this.authorizedFileTypes.includes(file.type);
    });
  }

  public get attachment() {
    return this._attachment;
  }

  @ViewChild(ModalDirective)
  private modal: ModalDirective;

  constructor(private attachmentService: AttachmentService) { }

  public open(): void {
    this.modal.open();
  }

  public close(): void {
    this.modal.close();
  }

  public download(): void {
    const anchor = document.createElement('a');
    anchor.download = this.file.name;
    anchor.href = URL.createObjectURL(this.file);
    anchor.click();
  }

}
