import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {WorkspaceSimple} from '../../../../models/entity/workspaces/Workspace';

@Component({
  selector: 'a-select-workspace',
  templateUrl: './a-select-workspace.component.html',
  styleUrls: ['./a-select-workspace.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectWorkspaceComponent),
      multi: true
    }
  ]
})
export class ASelectWorkspaceComponent implements ControlValueAccessor {
  @Input()
  public disabled: boolean = false;

  @Input()
  public placeholderKey: string = '';

  @Input()
  public placeholderKeyI18nParams: any;

  @Input()
  public workspaces: Array<WorkspaceSimple>;

  public selectedWorkspaces: WorkspaceSimple;
  private onNgChange: (value: WorkspaceSimple) => void;
  private onNgTouched: () => void;

  @Input()
  public clearable: boolean = true;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(workspace: WorkspaceSimple): void {
    this.selectedWorkspaces = workspace;
  }

  public onChange(workspace: WorkspaceSimple): void {
    this.selectedWorkspaces = workspace;
    this.onNgChange(workspace);
  }

  public compare(workspace1: WorkspaceSimple, workspace2: WorkspaceSimple): boolean {
    return workspace1 != null && workspace2 != null && workspace1.id === workspace2.id;
  }
}
