import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {
  PaginatedAdministrationUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {AdministrationUserService} from '../../services/administration-user.service';
import {AdministrationUserSearchCriteria} from '../../../shared/models/criterias/AdministrationUserSearchCriteria';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class AdministrationUsersPaginateResolver extends AbstractResolver<PaginatedAdministrationUsers> {

  private initialPage: number = 0;
  private step: number = 10;

  constructor(spinnerService: SpinnerService, private administrationUserService: AdministrationUserService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedAdministrationUsers> {
    return this.administrationUserService.search(new AdministrationUserSearchCriteria(this.initialPage, this.step)).pipe(take(1));
  }
}
