import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AInputMailValidatorComponent} from '../../../shared/lib-components/atoms/forms/a-input-mail-validator/a-input-mail-validator.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {AdministrationUserService} from '../../../app-root/services/administration-user.service';
import {NotificationService} from '../../../app-root/services/notification.service';
import {UserWithEmail} from '../../../shared/models/entity/users/User';

@Component({
  templateUrl: './administration-user-page.component.html',
  styleUrls: ['./administration-user-page.component.scss']
})
export class AdministrationUserPageComponent {

  @ViewChild('emailInputComponent')
  private emailInputComponent: AInputMailValidatorComponent;

  public user: UserWithEmail;
  public initialEmail: string;

  constructor(route: ActivatedRoute,
              private router: Router,
              private administrationUserService: AdministrationUserService,
              private notificationService: NotificationService,
              private spinner: NgxSpinnerService) {
    route.data.subscribe( (data: {user: UserWithEmail}) => {
      this.initialEmail = data.user.email;
      this.user = data.user;
    });
  }

  public save(): void {
    this.spinner.show();
    this.administrationUserService.save(this.user).subscribe(
      user => {
        this.spinner.hide();
        this.notificationService.success(this.user.id ? 'notification.user.updated' : 'notification.user.saved');
        this.user = user;
        this.goToAdministrationPage();
      },
      error => {
        this.spinner.hide();
        this.notificationService.error(this.user.id ?
          'notification.user.updated.error' : 'notification.user.saved.error', {error: error.error || error.statusText});
      }
    );
  }

  public goToAdministrationPage(): void {
    this.router.navigate(['administration'], {fragment: 'users'});
  }

  public emailIsValid(): boolean {
    return this.emailInputComponent && this.emailInputComponent.inputNgModel.errors == null;
  }
}
