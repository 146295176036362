import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {AInputCompanyCodeValidatorComponent} from '../../atoms/forms/a-input-company-code-validator/a-input-company-code-validator.component';
import {WorkspaceService} from '../../../../app-root/services/workspace.service';
import {AInputWorkspaceCodeValidatorComponent} from '../../atoms/forms/a-input-workspace-code-validator/a-input-workspace-code-validator.component';
import {forkJoin, Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {SharedCollection} from '../../../models/entity/enums/SharedCollection';
import {NgxSpinnerService} from 'ngx-spinner';
import {AttachmentService} from '../../../../app-root/services/attachment.service';
import {SessionService} from '../../../../app-root/services/session.service';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {User} from '../../../models/entity/users/User';
import {WorkspaceModification} from '../../../models/entity/workspaces/WorkspaceModification';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'm-workspace-form',
  templateUrl: './m-workspace-form.component.html',
  styleUrls: ['./m-workspace-form.component.scss']
})
export class MWorkspaceFormComponent {

  @ViewChild('workspaceCodeInputComponent')
  private workspaceCodeInputComponent: AInputWorkspaceCodeValidatorComponent;

  @ViewChild('companyCodeInputComponent')
  private companyCodeInputComponent: AInputCompanyCodeValidatorComponent;

  public workspaceSpinnerName: string = 'connexion';

  @Input()
  public title: string = '';

  @Input()
  public users: Array<User>;

  @Input()
  public currentUser: CompleteCurrentUser;

  @Output()
  public workspaceSaved = new EventEmitter<void>();

  private _workspace: WorkspaceModification;
  public initialWorkspaceCode: string;

  private initialDashboardLogo: Attachment;
  private dashboardLogoToUpload: File;
  private initialLoginLogos: Array<Attachment> = [];
  private loginLogosToUpload: Array<File> = [];

  @Input()
  get workspace(): WorkspaceModification {
    return this._workspace;
  }

  set workspace(workspace: WorkspaceModification) {
    this._workspace = workspace;
    this.initialWorkspaceCode = workspace ? workspace.code : null;
    this.initialDashboardLogo = workspace && workspace.dashboardCustomization ? workspace.dashboardCustomization.logo : null;
    this.initialLoginLogos = workspace && workspace.loginCustomization ?
      JSON.parse(JSON.stringify(workspace.loginCustomization.logos)) || [] : [] ;
  }

  constructor(private workspaceService: WorkspaceService,
              private notificationService: NotificationService,
              private spinner: NgxSpinnerService,
              private attachmentService: AttachmentService,
              private sessionService: SessionService
  ) {}

  public save(): void {
    this.spinner.show(this.workspaceSpinnerName);
    this.createWorkspaceIfNeeded().pipe(
      switchMap(() => this.uploadDashboardLogo(this.workspace.id)),
      switchMap(() => this.uploadLoginLogos(this.workspace.id)),
      switchMap(() => this.workspaceService.update(this.workspace)),
      switchMap(() => this.reloadCurrentUserIfNeeded())
    ).subscribe(
      _ => {
        this.spinner.hide(this.workspaceSpinnerName);
        this.notificationService.success(this.workspace.id ? 'notification.workspace.updated' : 'notification.workspace.saved');
        this.workspaceSaved.emit();
      },
      error => {
        this.spinner.hide(this.workspaceSpinnerName);
        if (error && error.status === 413) {
          this.notificationService.error('notification.attachment.error.REQUEST_ENTITY_TOO_LARGE',
            {error: error.error || error.statusText});
        } else {
          this.notificationService.error(this._workspace.id ?
            'notification.workspace.updated.error' : 'notification.workspace.saved.error', {error: error.error || error.statusText});
        }
      });
  }

  private reloadCurrentUserIfNeeded(): Observable<CompleteCurrentUser> {
    return this.workspace.initialAgentAdmin && this.workspace.initialAgentAdmin.id === this.currentUser.id ?
      this.sessionService.fetchCurrentUser() : of(null);
  }

  private createWorkspaceIfNeeded(): Observable<WorkspaceModification> {
    const workspaceCopy = Object.assign({}, this.workspace);
    workspaceCopy.loginCustomization.logos = [];
    workspaceCopy.dashboardCustomization.logo = null;

    return !this.workspace.id ?
      this.workspaceService.create(workspaceCopy).pipe(tap(workspace => this.workspace.id = workspace.id)) :
      of(this.workspace);
  }

  public codesAreValid(): boolean {
    return this.workspaceCodeInputComponent && this.workspaceCodeInputComponent.inputNgModel.errors == null
      && (!this.companyCodeInputComponent || this.companyCodeInputComponent.inputNgModel.errors == null );
  }

  public addDashboardLogo(value: any): void {
    this.dashboardLogoToUpload = value as File;
    this.workspace.dashboardCustomization.logo = new Attachment().attachmentFromFile(value as File);
  }

  public removeDashboardLogo(): void {
    this.dashboardLogoToUpload = undefined;
    this.workspace.dashboardCustomization.logo = undefined;
  }

  public addLogoToLoginLogosList(logo: File | Attachment): void {
    this.workspace.loginCustomization.logos.push(logo as Attachment);
    this.loginLogosToUpload.push(logo as File);
  }

  public removeLoginLogo(logo: File | Attachment): void {
    if (logo instanceof Attachment && logo.id) {
      const iLoginLogo = this.workspace.loginCustomization.logos.findIndex((element) => logo.id === element.id);
      this.workspace.loginCustomization.logos.splice(iLoginLogo, 1);

      const idInitialLogoToRemove = this.initialLoginLogos.findIndex((element) => logo.id === element.id);
      this.initialLoginLogos.splice(idInitialLogoToRemove, 1);
    } else {
      const iLoginLogoToUpload = this.loginLogosToUpload.findIndex((element) => logo.name === element.name);
      this.loginLogosToUpload.splice(iLoginLogoToUpload, 1);

      const iLoginLogo = this.workspace.loginCustomization.logos.findIndex((element) => logo.name === element.name);
      this.workspace.loginCustomization.logos.splice(iLoginLogo, 1);
    }
  }

  private uploadDashboardLogo(workspaceId: string): Observable<Attachment> {
    if (this.dashboardLogoToUpload) {
      return this.attachmentService.uploadAttachment(
        {
          collection: SharedCollection.WORKSPACES,
          id: workspaceId
        },
        this.dashboardLogoToUpload,
        this.currentUser
      ).pipe(tap(dashboardLogo => this.workspace.dashboardCustomization.logo = dashboardLogo));
    } else {
      return of(null);
    }
  }

  private uploadLoginLogos(workspaceId: string): Observable<Attachment[]> {
    if (this.loginLogosToUpload.length > 0) {
      const res = Array<Observable<Attachment>>();
      this.loginLogosToUpload.map((element) => {
        res.push(
          this.attachmentService.uploadAttachment(
            {
              collection: SharedCollection.WORKSPACES,
              id: workspaceId
            },
            element,
            this.currentUser
          )
        );
      });
      return forkJoin(res)
        .pipe(
          tap(loginLogos => this.workspace.loginCustomization.logos = this.initialLoginLogos.concat(loginLogos)),
          tap(() => this.loginLogosToUpload = [])
        );
    } else {
      return of( null);
    }
  }
}
