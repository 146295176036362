import {PaginatedResult} from '../PaginatedResult';
import {Type} from 'class-transformer';
import {PaginatedMetadata} from '../PaginatedMetadata';
import {WorkspaceUserWithCompanies} from '../../users/WorkspaceUserWithCompanies';

export class PaginatedWorkspaceUsers extends PaginatedResult {

  @Type(() => WorkspaceUserWithCompanies)
  public result: Array<WorkspaceUserWithCompanies>;

  static emptyResult() {
    const empty = new PaginatedWorkspaceUsers(new PaginatedMetadata(0, 0, 0));
    empty.result = [];
    return empty;
  }
}
