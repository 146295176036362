import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Company} from '../../../../models/entity/companies/Company';

@Component({
  selector: 'a-select-companies',
  templateUrl: './a-select-companies.component.html',
  styleUrls: ['./a-select-companies.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCompaniesComponent),
      multi: true
    }
  ]
})
export class ASelectCompaniesComponent implements ControlValueAccessor {

  @Input()
  public companies: Array<Company>;
  public selectedCompanies: Array<Company>;
  private onNgChange: (values: Array<Company>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'companies';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  constructor() {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(companies: Array<Company>): void {
    this.selectedCompanies = companies;
  }

  public onChange(companies: Array<Company>): void {
    this.selectedCompanies = companies;
    this.onNgChange(companies);
  }

  public compare(company1: Company, company2: Company): boolean {
    return company1 != null && company2 != null && company1.id === company2.id;
  }
}
