import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RequestsListPageComponent} from './pages/requests-list/requests-list-page.component';
import {RequestDetailsPageComponent} from './pages/request-details/request-details-page.component';
import {TranslateModule} from '@ngx-translate/core';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {FormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../shared/shared.module';
import {DirectivesModule} from '../shared/directives/directives.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [RequestsListPageComponent, RequestDetailsPageComponent],
    imports: [
        CommonModule,
        TranslateModule,
        BaseComponentsModule,
        FormsModule,
        NgxSpinnerModule,
        MatSortModule,
        SharedModule,
        DirectivesModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatTabsModule
    ],
    providers: [DatePipe]
})
export class RequestModule {
}
