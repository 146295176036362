import {Attachment} from './Attachment';
import {Type} from 'class-transformer';
import {Domain} from './classifications/Domain';
import {NewsStatus} from './enums/NewsStatus';
import {NewsPriority} from './enums/NewsPriority';

export class News {

  public id: string;
  public title: string;
  public text: string;
  public tags: Array<string> = [];
  @Type(() => Attachment)
  public attachments: Array<Attachment> = [];
  public status: NewsStatus = NewsStatus.PUBLISHED;
  public priority?: NewsPriority = NewsPriority.NORMAL;
  @Type(() => Domain)
  public domains: Array<Domain> = [];
}
