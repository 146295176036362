import {
  PaginatedResponseTemplates
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedResponseTemplates';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {ResponseTemplateSearchCriteria} from '../../../shared/models/criterias/ResponseTemplateSearchCriteria';
import {ResponseTemplateService} from '../../services/responseTemplate.service';
import {Injectable} from '@angular/core';
import {switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceResponseTemplatesPaginateResolver extends AbstractResolver<PaginatedResponseTemplates> {

  private page: number = 0;
  private step: number = 10;
  private searchCriteria = ResponseTemplateSearchCriteria.empty();

  constructor(spinnerService: SpinnerService,
              private responseTemplateService: ResponseTemplateService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
    this.searchCriteria.page = this.page;
    this.searchCriteria.step = this.step;
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedResponseTemplates> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap(currentUser => {
        if (currentUser.isAgentAdmin()) {
          return this.responseTemplateService.find(this.searchCriteria);
        } else {
          return of(PaginatedResponseTemplates.emptyResult());
        }
      }),
      take(1)
    );
  }
}
