import {Type} from 'class-transformer';
import {User} from './users/User';

const pictureExtensions: Array<string> = [
  'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'ico', 'cur', 'gif', 'bmp', 'apng', 'png', 'svg', 'tif', 'tiff', 'webp'
];

export interface Reference {

  collection: string;
  id: string;
}

export class Attachment {

  public id?: string;
  @Type(() => User)
  public author: User;
  public name: string;
  public contentType?: string;
  public targetRef?: Reference;
  public isPicture: boolean;
  public localFileUrl?: string;

  public attachmentFromFile(file: File): Attachment {
    return {name: file.name, localFileUrl: window.URL.createObjectURL(file)} as Attachment;
  }

  public attachmentIsAPicture(): boolean {
    const extension = this.name.split('.').pop();
    return extension && pictureExtensions.includes(extension);
  }
}
