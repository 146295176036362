import {Role} from '../enums/Role';
import {WorkspaceSimple} from '../workspaces/Workspace';
import {Company} from '../companies/Company';
import {UserWithEmail} from './User';

export class ContextualUser extends UserWithEmail {

  public role: Role;
  public activeWorkspace: WorkspaceSimple;
  public activeCompany?: Company;
}
