import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {AdministrationUserService} from '../../services/administration-user.service';
import {UserWithEmail} from '../../../shared/models/entity/users/User';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class AdministrationUserResolver extends AbstractResolver<UserWithEmail> {

  constructor(spinnerService: SpinnerService, private administrationUserService: AdministrationUserService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserWithEmail> {
    const userId = route.paramMap.get('userId');
    return userId ?
      this.administrationUserService.findById(userId).pipe(take(1)) :
      of(new UserWithEmail()).pipe(take(1));
  }
}
