import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app-page.component.html',
  styleUrls: ['./app-page.component.scss']
})
export class AppPageComponent {

  public title = 'Service desk';
  public menuOpened = false;
  public onLogin: boolean;
  public onNoWorkspace: boolean;
  public onWorkspacesChoice: boolean;
  public onAdministrationPage: boolean;

  constructor(translateService: TranslateService, private router: Router) {
    translateService.setDefaultLang('fr');
    translateService.use('fr');
    this.onLogin = this.router.url.indexOf('/login') > -1;
    this.onNoWorkspace = this.router.url.indexOf('/noWorkspace') > -1;
    this.onWorkspacesChoice = this.router.url.indexOf('/workspacesChoice') > -1;
    this.onAdministrationPage = this.router.url.startsWith('/administration');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe( _ => {
      this.onLogin = this.router.url.indexOf('/login') > -1;
      this.onNoWorkspace = this.router.url.indexOf('/noWorkspace') > -1;
      this.onWorkspacesChoice = this.router.url.indexOf('/workspacesChoice') > -1;
      this.onAdministrationPage = this.router.url.startsWith('/administration');
    });
  }

  public onSideMenuOpenedChange(menuOpened: boolean): void {
    this.menuOpened = menuOpened;
  }

  public onActivate(component: any): void {
    if (component.reloadMenuCounter) {
      component.reloadMenuCounter.subscribe( _ => {});
    }
  }
}
