import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'm-input-search',
  templateUrl: './m-input-search.component.html',
  styleUrls: ['./m-input-search.component.scss']
})
export class MInputSearchComponent {

  public searchText: string;

  @Output()
  public searchFired: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public placeholderKey: string;

  @Input()
  public placeholderKeyI18nParams: any;

  public filter(): void {
    this.searchFired.emit(this.searchText);
  }
}
