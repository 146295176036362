import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {SessionService} from '../../../app-root/services/session.service';
import {News} from '../../../shared/models/entity/News';
import {NewsService} from '../../../app-root/services/news.service';
import {NewsSearchCriteria} from '../../../shared/models/criterias/NewsSearchCriteria';
import {PaginatedNews} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedNews';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

@Component({
  selector: 'news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  public currentUser: CompleteCurrentUser;
  public news: Array<News> = [];
  public pageCount: number = 0;
  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;
  public newsSearchCriteria: NewsSearchCriteria = new NewsSearchCriteria();
  public currentPage: number = this.DEFAULT_STARTING_PAGE;

  @Input()
  public initialNews: PaginatedNews;

  constructor(private router: Router,
              private sessionService: SessionService,
              private newsService: NewsService
  ) {
    this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  public ngOnInit(): void {
    this.newsSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
    this.newsSearchCriteria.step = this.DEFAULT_STEP;

    if (this.initialNews) {
      this.news = this.initialNews.result;
      this.pageCount = Math.ceil(this.initialNews.metadata.count / this.DEFAULT_STEP);
    } else {
      this.loadNews(this.newsSearchCriteria, false);
    }
  }

  public sortNews(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.newsSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.newsSearchCriteria.sort = sort.active;

    this.loadNews(this.newsSearchCriteria, false);
  }

  public goToAddNewsPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'news', 'new']);
  }

  public filter(qSearchCriteria: string): void {
    this.newsSearchCriteria.q = qSearchCriteria;
    this.loadNews(this.newsSearchCriteria, true);
  }

  public loadNews(newsSearchCriteria: NewsSearchCriteria, resetPagination: Boolean): void {
    if (resetPagination) {
      this.currentPage = this.DEFAULT_STARTING_PAGE;
      newsSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
      newsSearchCriteria.step = this.DEFAULT_STEP;
    }
    this.newsService.find(newsSearchCriteria).subscribe(paginatedNews => {
      this.news = paginatedNews.result;
      this.pageCount = Math.ceil(paginatedNews.metadata.count / this.DEFAULT_STEP);
    });
  }

  public pageChanged(newsSearchCriteria: NewsSearchCriteria): void {
    this.currentPage = newsSearchCriteria.page;
    this.loadNews(this.newsSearchCriteria, false);
  }
}
