import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';

export class WorkspacesSearchCriteria extends PaginatedCriteria {

  public q?: string;
  public workspaceCode?: string;
  public companyId?: string;

  public toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.q) {
      params = params.set('q', this.q);
    }

    if (this.workspaceCode) {
      params = params.set('workspaceCode', this.workspaceCode);
    }

    if (this.companyId) {
      params = params.set('companyId', this.companyId);
    }

    return params;
  }
}
