import {Component, HostBinding, Input} from '@angular/core';
import {buildEntityAttachmentUrls} from '../../../models/entity/enums/SharedCollection';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'a-attachment-image',
  templateUrl: './a-attachment-image.component.html',
  styleUrls: ['./a-attachment-image.component.scss']
})
export class AAttachmentImageComponent {

  public url: string;

  private _attachment: Attachment;

  @Input()
  public sizeAvatar: Size = Size.DEFAULT;

  @Input()
  public usePublic: boolean = false;

  @Input()
  public displayMode: DisplayMode = DisplayMode.SQUARE;

  @HostBinding('class._circle') get circle() { return this.displayMode === DisplayMode.CIRCLE;}

  @HostBinding('class._large') get large() { return this.sizeAvatar === Size.LARGE; }
  @HostBinding('class._default') get default() { return this.sizeAvatar === Size.DEFAULT; }
  @HostBinding('class._small') get small() { return this.sizeAvatar === Size.SMALL; }
  @HostBinding('class._tiny') get tiny() { return this.sizeAvatar === Size.TINY; }

  get attachment(): Attachment {
    return this._attachment;
  }

  @Input()
  set attachment(value: Attachment) {
    this._attachment = value;
    if (value && !value.localFileUrl) {
      switch (this.usePublic) {
        case true:
          this.url = buildEntityAttachmentUrls(value.targetRef.collection, value.targetRef.id, value.id).getPublicUrl();
          break;
        case false:
          this.url = buildEntityAttachmentUrls(value.targetRef.collection, value.targetRef.id, value.id).getUrl();
          break;
      }
    }
  }
}

export enum Size {
  TINY = '_tiny',
  SMALL = '_small',
  DEFAULT = '_default',
  LARGE = '_large'
}

export enum DisplayMode {
  SQUARE = 'SQUARE',
  CIRCLE = 'CIRCLE'
}
