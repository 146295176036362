import {Component} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

@Component({
  templateUrl: './workspaces-choice-page.component.html',
  styleUrls: ['./workspaces-choice-page.component.scss']
})
export class WorkspacesChoicePageComponent {

  private currentUser: CompleteCurrentUser;

  constructor(private sessionService: SessionService) {
    this.sessionService.getCurrentUser().subscribe(currentUser => this.currentUser = currentUser);
  }

  public logout(): void {
    this.sessionService.logoutFrom(this.currentUser.activeWorkspace?.code);
  }
}
