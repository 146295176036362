import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'a-checkbox',
    templateUrl: './a-checkbox.component.html',
    styleUrls: ['./a-checkbox.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ACheckboxComponent),
            multi: true
        }
    ]
})
export class ACheckboxComponent implements ControlValueAccessor {

  private onNgChange: (value: boolean) => void;
  private onNgTouched: () => void;

  public disabled: boolean;
  public checked: boolean;

  @Input()
  public labelKey: string;

  @Input()
  public size: string = 'medium';

  @Input()
  public margin;

  @Output()
  public change = new EventEmitter<boolean>();

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: boolean): void {
    this.checked = obj;
  }

  public onCheckedChange(checked: boolean): void {
    this.onNgChange(checked);
    this.checked = checked;
    this.change.emit(checked);
  }
}
