import {AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Directive, Injectable, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {WorkspaceUserService} from '../../app-root/services/workspace-user.service';

@Directive({
  selector: '[uniqueEmail]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueEmailValidatorDirective, multi: true}]
})
@Injectable()
export class UniqueEmailValidatorDirective implements AsyncValidator {

  @Input('uniqueEmail') initialEmail: string;

  constructor(private workspaceUserService: WorkspaceUserService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.existingUserEmail(this.workspaceUserService)(control);
  }

  existingUserEmail(workspaceUserService: WorkspaceUserService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return workspaceUserService.isExistingEmailInWorkspace(control.value)
        .pipe(first())
        .pipe(
          map((isExisting: boolean) => {
            if (isExisting && this.initialEmail !== control.value) {
              const error = {};
              error['alreadyExistingEmail'] = true;
              return error;
            }
            return null;
          })
        );
    };
  }
}
