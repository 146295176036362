import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../app-root/services/session.service';
import {PaginatedCriteria, SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {Observable} from 'rxjs/internal/Observable';
import {AgentSearchCriteria} from '../../../shared/models/criterias/AgentSearchCriteria';
import {WorkspaceUserService} from '../../../app-root/services/workspace-user.service';
import {PaginatedWorkspaceUsers} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUsers';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {WorkspaceAgentSearchCriteria} from '../../../shared/models/criterias/WorkspaceAgentSearchCriteria';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {WorkspaceUserWithCompanies} from '../../../shared/models/entity/users/WorkspaceUserWithCompanies';
import {Company} from '../../../shared/models/entity/companies/Company';

@Component({
  selector: 'agents-list',
  templateUrl: './agents-list.component.html',
  styleUrls: ['./agents-list.component.scss']
})
export class AgentsListComponent implements OnInit {

  public currentUser: CompleteCurrentUser;
  public agents: Array<WorkspaceUserWithCompanies> = [];
  public pageCount: number = 0;
  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;
  public currentPage: number = this.DEFAULT_STARTING_PAGE;
  public agentSearchCriteria: WorkspaceAgentSearchCriteria;
  public agentsCount: number;
  private _allCompanies: Array<Company>;

  @Input()
  public set allCompanies(companies: Array<Company>) {
    if (companies) {
      this._allCompanies = companies;
    }
  }

  public get allCompanies() {
    return this._allCompanies;
  }

  @Input()
  public set initialAgentsSet(paginatedUsers: PaginatedWorkspaceUsers) {
    this._initialAgentsSet = paginatedUsers;
    this.agentsCount = paginatedUsers.metadata.count;
    this.reset(paginatedUsers);
  }

  private _initialAgentsSet: PaginatedWorkspaceUsers;

  @Output()
  public reloadAgents: EventEmitter<PaginatedCriteria> = new EventEmitter<PaginatedCriteria>();

  constructor(private router: Router,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private sessionService: SessionService,
              private workspaceUserService: WorkspaceUserService
  ) {
    this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  public ngOnInit(): void {
    this.agentSearchCriteria = new WorkspaceAgentSearchCriteria(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    if (this._initialAgentsSet) {
      this.agents = this._initialAgentsSet.result;
      this.pageCount = Math.ceil(this._initialAgentsSet.metadata.count / this.DEFAULT_STEP);
    } else {
      this.retrieveAgents(this.agentSearchCriteria)
        .subscribe(users => {
          this.agents = users.result;
          this.agentsCount = users.metadata.count;
          this.pageCount = Math.ceil(users.metadata.count / this.DEFAULT_STEP);
        });
    }
  }

  public sortAgents(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.agentSearchCriteria.sort = sort.active;
    this.agentSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.loadAgents(this.agentSearchCriteria, false);
  }

  public loadAgents(agentSearchCriteria: AgentSearchCriteria, resetPagination: Boolean) {
    if (resetPagination) {
      this.currentPage = this.DEFAULT_STARTING_PAGE;
      agentSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
      agentSearchCriteria.step = this.DEFAULT_STEP;
    }
    this.retrieveAgents(this.agentSearchCriteria)
      .subscribe( paginatedAgents => {
        this.agents = paginatedAgents.result;
        this.agentsCount = paginatedAgents.metadata.count;
        this.pageCount = Math.ceil(paginatedAgents.metadata.count / this.DEFAULT_STEP);
      });
  }

  public filter(workspaceAgentSearchCriteria: WorkspaceAgentSearchCriteria): void {
    this.agentSearchCriteria = workspaceAgentSearchCriteria;
    this.loadAgents(this.agentSearchCriteria, true);
  }

  public goToAddAgentPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'agents', 'new']);
  }

  public onAgentDisabled(): void {
    this.reloadAgents.emit(this.agentSearchCriteria);
  }

  public onPageChanged(agentSearchCriteria: WorkspaceAgentSearchCriteria): void {
    this.currentPage = agentSearchCriteria.page;
    this.retrieveAgents(agentSearchCriteria)
      .subscribe( paginatedAgents => {
        this.agents = paginatedAgents.result;
        this.pageCount = Math.ceil(paginatedAgents.metadata.count / this.DEFAULT_STEP);
      });
  }

  public retrieveAgents(agentSearchCriteria: WorkspaceUserSearchCriteria): Observable<PaginatedWorkspaceUsers> {
    return this.workspaceUserService.findAgents(agentSearchCriteria);
  }

  public reset(newSet: PaginatedWorkspaceUsers): void {
    this.agentSearchCriteria = new WorkspaceAgentSearchCriteria(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    this.currentPage = this.DEFAULT_STARTING_PAGE;
    this.agents = newSet.result;
    this.pageCount = Math.ceil(newSet.metadata.count / this.DEFAULT_STEP);
  }
}
