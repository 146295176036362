import {Role} from '../enums/Role';
import {WorkspaceSimple} from '../workspaces/Workspace';
import {Type} from 'class-transformer';
import {User} from './User';
import {CompanySimple} from '../companies/CompanySimple';
import {Attachment} from '../Attachment';

export class WorkspaceUser {
  public role: Role;
  public id: string;
  public userId: string;
  @Type(() => WorkspaceSimple)
  public activeWorkspace: WorkspaceSimple;
  @Type(() => CompanySimple)
  public activeCompany: CompanySimple;
  public firstName: string;
  public lastName: string;
  public superAdmin: boolean;
  public email: string;
  public disabled: boolean;
  public avatar?: Attachment;

  public getFullName(): string {
    if (this.disabled) {
      return `${this.firstName} ${this.lastName} (Désactivé)`;
    } else {
      return `${this.firstName} ${this.lastName}`;
    }
  }

  public toUser(): User {
    const user = new User();
    user.id = this.userId;
    user.firstName = this.firstName;
    user.lastName = this.lastName;
    user.disabled = this.disabled;
    user.avatar = this.avatar;
    user.superAdmin = this.superAdmin;

    return user;
  }
}

