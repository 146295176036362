import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-domain-icon',
  templateUrl: './a-domain-icon.component.html',
  styleUrls: ['./a-domain-icon.component.scss']
})
export class ADomainIconComponent {

  @Input()
  public sizeAvatar: SizeAvatar = 'DEFAULT';

  private _domainIconClass: string;

  @Input()
  public category: string;

  @Input()
  public title: string;

  @Input()
  get domainIconClass() {
    return this._domainIconClass;
  }

  set domainIconClass(domainAvatarClass: string) {
    this._domainIconClass = domainAvatarClass;
  }

  @Input()
  public label: string;

  public fromSizeAvatar = fromSizeAvatar;

}

export type SizeAvatar = 'DEFAULT' | 'LARGE' | 'SMALL' | 'TINY';

export function fromSizeAvatar(sizeAvatar: SizeAvatar): string {
  switch (sizeAvatar) {
    case 'DEFAULT':
      return '_default';
    case 'LARGE':
      return '_large';
    case 'SMALL':
      return '_small';
    case 'TINY':
      return '_tiny';
  }
}
