import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {RequestService} from '../../../app-root/services/request.service';
import {SimpleRequest} from '../../../shared/models/entity/requests/RequestSimple';
import {SessionService} from '../../../app-root/services/session.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CompleteMessage} from '../../../shared/models/entity/requests/CompleteMessage';
import {NotificationService} from '../../../app-root/services/notification.service';
import {RequestStatus} from '../../../shared/models/entity/enums/RequestStatus';
import {RequestPriority} from '../../../shared/models/entity/enums/RequestPriority';
import {combineLatest, forkJoin, fromEvent, Observable, of} from 'rxjs';
import {NgxSpinnerService} from 'ngx-spinner';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {AttachmentService} from '../../../app-root/services/attachment.service';
import {SharedCollection} from '../../../shared/models/entity/enums/SharedCollection';
import {MAddItemComponent} from '../../../shared/lib-components/molecules/m-add-item/m-add-item.component';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TagService} from '../../../app-root/services/tag.service';
import {RequestSearchCriteria} from '../../../shared/models/criterias/RequestSearchCriteria';
import {ResponseTemplateService} from '../../../app-root/services/responseTemplate.service';
import {ResponseTemplateSearchCriteria} from '../../../shared/models/criterias/ResponseTemplateSearchCriteria';
import {AInputComponent} from '../../../shared/lib-components/atoms/forms/a-input/a-input.component';
import {WorkspaceUserService} from '../../../app-root/services/workspace-user.service';
import {CompleteRequest} from '../../../shared/models/entity/requests/CompleteRequest';
import {User} from '../../../shared/models/entity/users/User';
import {ResponseTemplate} from '../../../shared/models/entity/ResponseTemplate';
import {Domain} from '../../../shared/models/entity/classifications/Domain';
import {Category} from '../../../shared/models/entity/classifications/Category';
import {Attachment} from '../../../shared/models/entity/Attachment';
import {Company} from '../../../shared/models/entity/companies/Company';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {CompanySimple} from '../../../shared/models/entity/companies/CompanySimple';
import {WorkspaceSimple} from '../../../shared/models/entity/workspaces/Workspace';
import {WorkspaceService} from '../../../app-root/services/workspace.service';
import {TransferRequest} from '../../../shared/models/entity/TransferRequest';
import {WorkspaceUser} from '../../../shared/models/entity/users/WorkspaceUser';
import {TransferredRequest} from '../../../shared/models/entity/requests/TransferredRequest';
import {RequestWrapper} from '../../../shared/models/entity/requests/RequestWrapper';

@Component({
  templateUrl: './request-details-page.component.html',
  styleUrls: ['./request-details-page.component.scss']
})
export class RequestDetailsPageComponent implements OnInit, AfterViewInit {

  public user: CompleteCurrentUser;
  public currentUserCompanies: Array<CompanySimple>;
  public isAffectedAgent: boolean = false;
  public isAssignedUser: boolean = false;

  get request(): CompleteRequest {
    return this._request;
  }

  set request(value: CompleteRequest) {
    this._request = value;
    this.requestAttachments = [].concat.apply([], value.messages.map(message => message.attachments))
      .sort((a, b) => this.utilsService.compareStrings(a.name, b.name, true));
  }
  private _request: CompleteRequest;
  public requestAttachments: Array<Attachment>;

  public requestNavigations: Array<SimpleRequest> = undefined;
  public requestNavigationsCriteria: RequestSearchCriteria = new RequestSearchCriteria();
  public requestCurrentUserPriority: RequestPriority;
  public messageText: string;

  public affectableAgents: Array<User> = [];
  public affectableUsers: Array<User> = [];
  public agentToAdd: User;
  public userToAdd: User;
  public relatedRequestToAdd: SimpleRequest;

  public filesToUpload: Array<File> = [];
  public maxFileSize: number = 30000000;

  public existingTags: string[] = [];
  public isWritingPrivateMessage: boolean = false;
  public privateMessageRecipients: User[] = [];

  public responseTemplates: Array<ResponseTemplate> = [];

  public openResponseTemplate: boolean = false;

  public canTransfer: boolean = false;
  public showTransferForm: boolean = false;

  public transferAvailableWorkspaces: Array<WorkspaceSimple>;
  public transferAvailableCompanies: Array<CompanySimple>;
  public transferAvailableAdmins: Array<WorkspaceUser>;

  public transferWorkspace: WorkspaceSimple;
  public transferCompany: CompanySimple;
  public transferAdmins: Array<WorkspaceUser>;

  public transferredTo: TransferredRequest;

  @ViewChild('messagesContainer')
  private messagesContainer: ElementRef;

  @ViewChildren('messages')
  private messages: QueryList<any>;

  @ViewChild('eventsContainer')
  private eventsContainer: ElementRef;

  @ViewChildren('events')
  private events: QueryList<any>;

  @ViewChild('relatedRequestsDropdown')
  private relatedRequestsDropdown: MAddItemComponent;

  @ViewChild('responseTemplateSearch')
  private searchInputComponent: AInputComponent;

  public qResTemplateSearch: string;

  CLOSED: RequestStatus = RequestStatus.CLOSED;

  constructor(private requestService: RequestService,
              private sessionService: SessionService,
              private route: ActivatedRoute,
              private attachmentService: AttachmentService,
              private notificationService: NotificationService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private workspaceUserService: WorkspaceUserService,
              private translateService: TranslateService,
              private utilsService: UtilsService,
              private tagService: TagService,
              private responseTemplateService: ResponseTemplateService,
              private activatedRoute: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              private workspaceService: WorkspaceService
  ) {}

  ngOnInit(): void {
    this.sessionService.getCurrentUser().pipe(
      tap(user => {
        this.user = user;
        this.currentUserCompanies = user.getCompaniesInActiveWorkspace();
      }),
      switchMap(() => combineLatest([this.route.data, this.activatedRoute.queryParams])),
      tap( ([data, params]: [{ request: RequestWrapper }, Params]) => {
        this.requestNavigationsCriteria = RequestSearchCriteria.fromParams(params, null, null);

        // Check if is not transferred
        if (data.request && data.request.type === 'complete') {
          this.request = data.request.request as CompleteRequest;
          this.requestCurrentUserPriority = this.request.getVisiblePriority(this.user);
          this.isAffectedAgent = this.user.isAgent()
            && this.request.agents.find(agent => agent.id === this.user.id) != null;
          this.isAssignedUser = this.user.isUser()
            && this.request.users.find(user => user.id === this.user.id) != null;
          if (!this.user.isUser()) {
            const rtSearchCriteria = ResponseTemplateSearchCriteria.from(this.request.company, this.request.domain);
            this.responseTemplateService
              .find(rtSearchCriteria)
              .subscribe(responseTemplates => this.responseTemplates = responseTemplates.result);
          }
        } else if (data.request && data.request.type === 'transferred') {
          this.transferredTo = data.request.request as TransferredRequest;
        }
      }),
      switchMap(() => forkJoin(this.getUsersWhichCanBeInvolvedAccordingToCurrentUser())),
      switchMap(() => this.sessionService.getActivatedWorkspace()),
      tap(workspace => {
        this.canTransfer = this.user.isAgentAdmin() && workspace.parameters && workspace.parameters.transfer;
        if (this.user.isAgentAdmin()) {
          this.transferWorkspace = workspace;
          this.onTransferWorkspaceChange(workspace);
        }
      })
    ).subscribe();
    this.workspaceService.getWorkspacesForTransfer()
      .subscribe(workspaces => this.transferAvailableWorkspaces = workspaces);
    this.tagService.getExistingTagsForRequest().subscribe(tags => this.existingTags = tags);
  }

  ngAfterViewInit(): void {
    this.route.queryParams.pipe(
      map(params => RequestSearchCriteria.fromParams(params, null, null)),
      switchMap(params => this.requestService.searchSimple(params))
    )
      .subscribe(navigations => {
        this.requestNavigations = navigations || [];
      });

    if (this.searchInputComponent) {
      fromEvent(this.searchInputComponent.inputField.nativeElement, 'keyup').pipe(
        map((event: any) => event.target.value),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((_: string) => {
          const criteria = ResponseTemplateSearchCriteria.empty();
          criteria.q = this.qResTemplateSearch;
          return this.responseTemplateService
            .find(criteria);
        })).subscribe(responseTemplates => this.responseTemplates = responseTemplates.result);
    }
    // Ugly tricks to wait images preview rendering before scrollToBottom
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      this.scrollToBottom();
    })();
    this.messages.changes.subscribe(() => this.scrollToBottom());
    this.events.changes.subscribe(() => this.scrollToBottom());
  }

  /*********************************
   ***  CREATION AND MSG SUBMIT  ***
   *********************************/

  public onSubmitMessage(): void {
    this.spinner.show();
    if (!this.request.id) {
      this.submitMessage(this.createRequest(this.messageText).pipe(tap(request => this.request = request)))
        .pipe(
          tap(() => this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests', this.request.code]))
        ).subscribe(
        () => this.onSubmitMessageSuccess('notification.request.created'),
        error => this.onSubmitMessageError(error, 'notification.request.created.error'));
    } else {
      this.submitMessage(of(this.request))
        .pipe(
          switchMap(() => this.requestService.findByCode(this.request.code)),
          tap(request => this.request = request)
        ).subscribe(
        () => this.onSubmitMessageSuccess('notification.message.created'),
        error => this.onSubmitMessageError(error, 'notification.message.created.error'));
    }
  }

  private onSubmitMessageSuccess(messageKey: string): void {
    this.notificationService.success(messageKey);
    this.spinner.hide();
  }

  private onSubmitMessageError(error: any, genericErrorMessageKey: string): void {
    if (error && error.status === 413) {
      this.notificationService.error('notification.attachment.error.REQUEST_ENTITY_TOO_LARGE', {error: error.error || error.statusText});
    } else {
      this.notificationService.error(genericErrorMessageKey);
    }
    this.spinner.hide();
  }

  private submitMessage(requestObservable: Observable<CompleteRequest>): Observable<CompleteMessage> {
    return requestObservable.pipe(
      switchMap(() => this.uploadFiles()),
      map(attachments => CompleteMessage.from(
        this.user,
        this.messageText,
        attachments,
        this.isWritingPrivateMessage,
        // We remove the 'admin only' user, it is carried by isWritingPrivateMessage
        this.privateMessageRecipients.filter(u => u.id !== '-1')
      )),
      switchMap(message => this.requestService.sendMessage(this.request.id, message)),
      tap(() => {
        this.messageText = undefined;
        this.filesToUpload = [];
      }),
    );
  }

  private createRequest(description: string): Observable<CompleteRequest> {
    this.request.description = description;
    this.request.createdDate = new Date();
    this.request.lastStatusChangeDate = new Date();
    return this.requestService.create(this.request);
  }


  /***********************
   ***  ATOMIC UPDATE  ***
   ***********************/

  public onStatusChange(newStatus: RequestStatus): void {
    // Note : Status can be changed only on update
    this.updateRequest(this.requestService.updateStatus(this.request.id, newStatus)).subscribe();
  }

  public onPriorityChange(newPriority: RequestPriority): void {
    this.request.id ?
      this.updateRequest(this.requestService.updatePriority(this.request.id, newPriority)).subscribe() :
      this.user.isUser() ? this.request.userPriority = newPriority : this.request.priority = newPriority;
  }

  public onTagsChange(newTags: Array<string>): void {
    if (this.request.tags.toString().toUpperCase() !== newTags.toString().toUpperCase()) {
      this.request.id ?
        this.updateRequest(this.requestService.updateTags(this.request.id, newTags)).subscribe() :
        this.request.tags = newTags;
    }
  }

  public onSelectedAgentToAdd(agentToAdd: User): void {
    this.agentToAdd = agentToAdd;
    if (agentToAdd && !this.request.agents.find(agent => agent.id === agentToAdd.id)) {
      this.request.agents.push(agentToAdd);
      if (this.request.id) {
        this.updateRequest(this.requestService.updateAgents(this.request.id, this.request.agents)).subscribe( _ => {
          this.refreshAffectedAgent();
          this.affectableAgents = this.affectableAgents.filter(el => el.id !== agentToAdd.id);
        });
      }
    } else {
      this.notificationService.warning('request.agent.already.affected', {fullName: agentToAdd.getFullName()});
    }
    setTimeout(() => this.agentToAdd = undefined);
  }

  public removeAgent(agentToRemove: User): void {
    const remainingAgents = this.request.agents.filter(agent => agent.id !== agentToRemove.id);
    this.request.id ?
      this.updateRequest(this.requestService.updateAgents(this.request.id, remainingAgents)).subscribe( _ => {
        this.refreshAffectedAgent();
        this.affectableAgents = this.affectableAgents.concat([agentToRemove]);
      }) :
      this.request.agents = remainingAgents;
  }

  public onSelectedUserToAdd(userToAdd: User): void {
    this.userToAdd = userToAdd;
    if (userToAdd && !this.request.users.find(user => user.id === userToAdd.id)) {
      this.request.users.push(userToAdd);
      if (this.request.id) {
        this.updateRequest(this.requestService.updateUsers(this.request.id, this.request.users)).subscribe();
        this.affectableUsers = this.affectableUsers.filter(el => el.id !== userToAdd.id);
      }
    } else {
      this.notificationService.warning('request.user.already.affected', {fullName: userToAdd.getFullName()});
    }
    setTimeout(() => this.userToAdd = undefined);
  }

  public removeUser(userToRemove: User): void {
    const remainingUsers = this.request.users.filter(user => user.id !== userToRemove.id);
    if ( this.request.id) {
      this.updateRequest(this.requestService.updateUsers(this.request.id, remainingUsers)).subscribe();
      this.affectableUsers = this.affectableUsers.concat([userToRemove]);
    } else {
      this.request.users = remainingUsers;
    }
  }

  public onSelectedRequestToAdd(requestToAdd: SimpleRequest): void {
    this.relatedRequestToAdd = requestToAdd;
    if (requestToAdd && !this.request.relatedRequests.find(request => request.id === requestToAdd.id)) {
      this.request.relatedRequests.push(requestToAdd);
      if (this.request.id) {
        this.updateRequest(this.requestService.updateRelatedRequests(this.request.id, this.request.relatedRequests)).subscribe();
      }
    } else {
      this.notificationService.warning('request.related.request.already.linked', {codeWithTopic: requestToAdd.getCodeWithTopic()});
    }
    setTimeout(() => this.relatedRequestToAdd = undefined);
  }

  public removeRelatedRequest(relatedRequestToRemove: SimpleRequest): void {
    const remainingRelatedRequests = this.request.relatedRequests.filter(request => request.id !== relatedRequestToRemove.id);
    this.request.id ?
      this.updateRequest(this.requestService.updateRelatedRequests(this.request.id, remainingRelatedRequests)).subscribe() :
      this.request.relatedRequests = remainingRelatedRequests;
  }

  public onRequestDomainChange(domain: Domain): void {
    if (this.request.id) {
      this.updateRequest(this.requestService.updateDomain(this.request.id, domain?.id)).subscribe();
    } else {
      this.request.domain = domain;
      this.request.category = null;
    }
  }

  public onRequestCategoryChange(category: Category): void {
    this.request.id ?
      this.updateRequest(this.requestService.updateCategory(this.request.id, category?.id)).subscribe() : this.request.category = category;
  }

  private updateRequest(completeRequestObservable: Observable<CompleteRequest>): Observable<CompleteRequest> {
    this.spinner.show();
    return completeRequestObservable.pipe(
      tap((request: CompleteRequest) => {
        this.request = request;
        this.notificationService.success('request.update.success');
        this.spinner.hide();
      }),
      catchError(() => {
        this.notificationService.error('request.update.error');
        this.spinner.hide();
        return of(null);
      })
    );
  }

  public refreshAffectedAgent(): void {
    this.isAffectedAgent = this.user.isAgent()
      && this.request.agents.find(agent => agent.id === this.user.id) != null;
  }

  /***********************
   ***   ATTACHMENTS   ***
   ***********************/

  public addFileToUpload(value: any): void {
    const file: File = value as File;
    this.filesToUpload.push(file);
    this.changeDetector.detectChanges();
  }

  public onAttachmentRemoved(removedAttachment: File): void {
    this.filesToUpload = this.filesToUpload.filter(file => file !== removedAttachment);
  }

  private uploadFiles(): Observable<Attachment[]> {
    let uploadObservables: Array<Observable<Attachment>>;

    if (this.filesToUpload.length > 0) {
      uploadObservables = this.filesToUpload.map(file =>
        this.attachmentService.uploadAttachment({
          collection: SharedCollection.REQUESTS,
          id: this.request.id
        }, file, this.user));
      return forkJoin(uploadObservables);
    } else {
      return of([]);
    }
  }


  /***********************
   ***   OTHERS   ***
   ***********************/

  public goToRequestPage(relatedRequest: SimpleRequest): void {
    this.relatedRequestsDropdown.actionsOpened = false;
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests', relatedRequest.code]);
  }

  public onRequestCompanyChange(company: Company): void {
    if (company) {
      this.request.company = company;
      this.request.agents = this.request.agents.filter(agent => agent.id === this.user.id);
      this.request.users = [];
      this.request.domain = null;
      this.request.category = null;

      forkJoin(this.getUsersWhichCanBeInvolvedAccordingToCurrentUser()).subscribe();
    }
  }

  private scrollToBottom(): void {
    if (this.messagesContainer) {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      if (this.eventsContainer) {
        this.eventsContainer.nativeElement.scrollTop = this.eventsContainer.nativeElement.scrollHeight;
      }
    }
  }

  private getUsersWhichCanBeInvolvedAccordingToCurrentUser(): Array<Observable<User[]>> {
    const obs: Array<Observable<User[]>> = [];

    if (this.request && this.request.company && this.request.company.id) {
      if (this.isAssignedUser || this.isAffectedAgent || this.user.isAgentAdmin()) {
      obs.push(this.workspaceUserService.findUsersForCompany(this.request.company.id)
        .pipe(
          map(users => users.map(it => it.toUser())),
          map(users => users.filter(user => {
            return this.request.users.find(it => it.id === user.id) === undefined;
          })),
          tap(users => this.affectableUsers = users))
      );
      }
      if (this.isAffectedAgent || this.user.isAgentAdmin()) {
        obs.push(this.workspaceUserService.findAgentsForCompany(this.request.company.id)
          .pipe(
            map(agents => agents.map(it => it.toUser())),
            map(agents => agents.filter(agent => {
              return this.request.agents.find(it => it.id === agent.id) === undefined;
            })),
            tap((agents: Array<User>) => this.affectableAgents = agents)
          ));
      }
    }

    return obs;
  }

  public getMissingFields(form: NgForm): string {
    const invalidFields = [];
    for (const name in form.controls) {
      if (form.controls[name].invalid) {
        invalidFields.push(this.translateService.instant('request.invalid.field',
          {fieldName: this.translateService.instant('request.' + name)}));
      }
    }
    return invalidFields.join('\n');
  }

  public onAttachmentClick(attachment: Attachment): void {
    this.attachmentService.getAttachment(attachment).subscribe(file => {
      const anchor = document.createElement('a');
      anchor.download = file.name;
      anchor.href = URL.createObjectURL(file);
      anchor.click();
    });
  }

  public onTabChange(): void {
    this.scrollToBottom();
  }

  public canAccessDomain(): boolean {
    return this.user.isUser() ? !this.request.id : this.isAffectedAgent;
  }

  public canAccessCategory(): boolean {
    return this.canAccessDomain() &&  !!this.request.domain;
  }

  public navigateToRequestLists(): void {
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests'],
      {queryParams: RequestSearchCriteria.toCleanQueryParams(this.requestNavigationsCriteria)});
  }

  public navigateToPrevious(currentRequestId: string): void {
    const index = this.requestNavigations.findIndex(simpleRequest => simpleRequest.id === currentRequestId);
    const previousRequest: SimpleRequest =
      index === 0 ? this.requestNavigations[this.requestNavigations.length - 1] : this.requestNavigations[index - 1];
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests', previousRequest.code],
      {queryParams: RequestSearchCriteria.toCleanQueryParams(this.requestNavigationsCriteria)});
  }

  public navigateToNext(currentRequestId: string): void {
    const index = this.requestNavigations.findIndex(simpleRequest => simpleRequest.id === currentRequestId);
    const nextRequest: SimpleRequest =
      index < this.requestNavigations.length - 1 ? this.requestNavigations[index + 1] : this.requestNavigations[0];
    this.router.navigate(['workspaces', this.user.activeWorkspace.code, 'requests', nextRequest.code],
      {queryParams: RequestSearchCriteria.toCleanQueryParams(this.requestNavigationsCriteria)});
  }

  public fillResponse(message: string, attachments: Array<Attachment>): void {
    this.messageText = this.messageText ? this.messageText + message : '' + message;
    this.closeResponseTemplates();
    if (attachments === null || attachments === undefined || attachments.length === 0) {
      return;
    }
    const attachmentsFilePromise = attachments
      .map(attachment => this.attachmentService.getAttachment(attachment));
    forkJoin(attachmentsFilePromise)
      .subscribe(fileList => this.filesToUpload = this.filesToUpload.concat(fileList));
  }

  public openResponseTemplates(): void {
    this.openResponseTemplate = true;
  }

  public closeResponseTemplates(): void {
    this.openResponseTemplate = false;
  }

  public updatePrivateMessageRecipients(recipients: User[]): void {
    this.privateMessageRecipients = recipients;
    this.isWritingPrivateMessage = !!recipients.length;
  }

  public openTransferForm(): void {
    this.showTransferForm = true;
  }

  public closeTransferForm(): void {
    this.showTransferForm = false;
  }

  public onTransferWorkspaceChange(workspace: WorkspaceSimple): void {
    this.transferWorkspace = workspace;
    this.transferCompany = null;
    this.transferAdmins = null;
    this.transferAvailableCompanies = this.user.getCompaniesInWorkspaces(workspace.id);
    this.workspaceUserService.getAdminAgentsByWorkspaceId(workspace.id).subscribe(admins =>
      this.transferAvailableAdmins = admins);
  }

  public transferRequest(): void {
    const transfer: TransferRequest = {
      requestId: this.request.id,
      workspaceId: this.transferWorkspace.id,
      companyId: this.transferCompany && this.transferCompany.id,
      adminIds: this.transferAdmins && this.transferAdmins.map(admin => admin.id)
    };
    this.requestService.transferRequest(transfer).subscribe(() => {
        this.notificationService.success('request.actions.transfer.status.success');
        this.navigateToRequestLists();
      },
      () => this.notificationService.error('request.actions.transfer.status.error')
    );
  }
}
