import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';
import {ResponseTemplateSearchCriteria} from '../../shared/models/criterias/ResponseTemplateSearchCriteria';
import {PaginatedResponseTemplates} from '../../shared/models/entity/paginated/paginated-entities/PaginatedResponseTemplates';
import {ResponseTemplate} from '../../shared/models/entity/ResponseTemplate';

@Injectable()
export class ResponseTemplateService {

  private baseUrl = '/api/responseTemplate';

  constructor(private httpClient: HttpClient) {}

  public getById(id: string): Observable<ResponseTemplate> {
    return this.httpClient.get<ResponseTemplate>(`${this.baseUrl}/${id}`)
      .pipe(
        map(responseTemplate => plainToClass(ResponseTemplate, responseTemplate))
      );
  }

  public update(responseTemplate: ResponseTemplate): Observable<ResponseTemplate> {
    return this.httpClient.put<ResponseTemplate>(this.baseUrl, responseTemplate)
      .pipe(
        map(responseTemplateUpdated => plainToClass(ResponseTemplate, responseTemplateUpdated))
      );
  }

  public create(responseTemplate: ResponseTemplate): Observable<ResponseTemplate> {
    return this.httpClient.post<ResponseTemplate>(this.baseUrl, responseTemplate)
      .pipe(
        map(responseTemplateSaved => plainToClass(ResponseTemplate, responseTemplateSaved))
      );
  }

  public find(responseTemplateSearchCriteria: ResponseTemplateSearchCriteria): Observable<PaginatedResponseTemplates> {
    return this.httpClient.get(this.baseUrl, {params: responseTemplateSearchCriteria.toHttpParams()})
      .pipe(
        map(paginatedResult => plainToClass(PaginatedResponseTemplates, paginatedResult as Object))
      );
  }

  public delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  public isExistingName(name: string): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('name', name);
    return this.httpClient.get<boolean>(`${this.baseUrl}/nameExists`, {params: params}).pipe(
      map(response => plainToClass(Boolean, response) as boolean)
    );
  }
}
