import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginPageComponent} from './pages/login/login-page.component';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {PasswordPageComponent} from './pages/password/password-page.component';
import {DirectivesModule} from '../shared/directives/directives.module';
import {EmailSentPageComponent} from './pages/email-sent/email-sent-page.component';
import {NgxSpinnerModule} from 'ngx-spinner';

const components = [LoginPageComponent, PasswordPageComponent, EmailSentPageComponent];

@NgModule({
  imports: [
    CommonModule,
    BaseComponentsModule,
    TranslateModule,
    FormsModule,
    DirectivesModule,
    NgxSpinnerModule
  ],
  declarations: [components],
  exports: [components]
})
export class LoginModule {
}
