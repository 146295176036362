import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';

export class WorkspaceUserSearchCriteria extends PaginatedCriteria {

  constructor(public page?: number,
              public step?: number,
              public companyId?: string,
              public q?: string) {
    super(page, step);
  }

  static newEmptyWithPagination(page: number, step: number) {
    return new WorkspaceUserSearchCriteria(page, step);
  }

  public toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.q) {
      params = params.set('q', this.q);
    }

    if (this.companyId) {
      params = params.set('companyId', this.companyId);
    }

    return params;
  }
}
