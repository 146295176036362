import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'a-button-search',
  templateUrl: './a-button-search.component.html',
  styleUrls: ['./a-button-search.component.scss']
})
export class AButtonSearchComponent implements OnInit {

  // Expanded host binding
  @HostBinding('class._expanded') isExpanded: boolean = false;

  @Input()
  public inputType: string = 'button';

  @Output()
  public btnClick = new EventEmitter<void>();

  @Input()
  public labelKey: string;

  @Input()
  public loading: false;

  @Input()
  public expanded: boolean = false;

  @Input()
  public disabled: boolean = false;

  ngOnInit(): void {
    if (this.expanded) {
      this.isExpanded = true;
    }
  }
}
