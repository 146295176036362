import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {requestPriorities, RequestPriority} from '../../../../models/entity/enums/RequestPriority';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'a-select-priority',
  templateUrl: './a-select-priority.component.html',
  styleUrls: ['./a-select-priority.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectPriorityComponent),
      multi: true
    }
  ]
})
export class ASelectPriorityComponent implements ControlValueAccessor {

  public prioritiesWithLabel: { label: string; priority: RequestPriority }[];
  public selectedPriority: { label: string; priority: RequestPriority };
  private onNgChange: (value: RequestPriority) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'priority';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean;

  constructor(private translateService: TranslateService) {
    this.prioritiesWithLabel = requestPriorities().map(priority =>  {
      return {label: translateService.instant('request.priority.' + priority), priority: priority} ;
    });
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(priority: RequestPriority): void {
    this.selectedPriority = priority ?
      {label: this.translateService.instant('request.priority.' + priority), priority: priority} : undefined;
  }

  public onChange(priorityWithLabel: { label: string; priority: RequestPriority }): void {
    this.selectedPriority = priorityWithLabel;
    priorityWithLabel ? this.onNgChange(priorityWithLabel.priority) : this.onNgChange(undefined);
  }

  public compare(priority1: { label: string; priority: RequestPriority },
                 priority2: { label: string; priority: RequestPriority }): boolean {
    return priority1 != null && priority2 != null && priority1.priority === priority2.priority;
  }
}
