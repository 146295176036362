import {Component, EventEmitter, Input, Output} from '@angular/core';
import { fromSize, Size } from '../buttons-utils';

@Component({
  selector: 'a-button-delete',
  templateUrl: './a-button-delete.component.html',
  styleUrls: ['./a-button-delete.component.scss']
})
export class AButtonDeleteComponent {

  @Output()
  public btnClick = new EventEmitter<void>();

  @Input()
  public disabled = false;

  @Input()
  public hollow = false;

  @Input()
  public size: Size = 'DEFAULT';

  public fromSize = fromSize;

}
