import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from '../../services/session.service';

@Injectable()
export class CurrentWorkspaceResolver implements Resolve<void> {

  constructor(private sessionService: SessionService,
              private router: Router
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    return this.sessionService.getCurrentUser().pipe(
      map(currentUser => currentUser.activeWorkspace ?
        this.router.navigate(['workspaces', currentUser.activeWorkspace.code]) :
        this.router.navigate(['noWorkspace'])),
      map(() => null)
    );
  }
}
