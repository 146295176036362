import {Component, Input, OnInit} from '@angular/core';
import {PaginatedAdministrationUsers} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {AdministrationUserSearchCriteria} from '../../../shared/models/criterias/AdministrationUserSearchCriteria';
import {Router} from '@angular/router';
import {AdministrationUserService} from '../../../app-root/services/administration-user.service';
import {Sort} from '@angular/material/sort';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {WorkspaceSimple} from '../../../shared/models/entity/workspaces/Workspace';
import {SessionService} from '../../../app-root/services/session.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {noop} from 'rxjs';
import {AlertService} from '../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../app-root/services/notification.service';
import {PasswordService} from '../../../app-root/services/password.service';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {UserAdministrationSearchResult} from '../../../shared/models/entity/users/AdministrationUser';
import {UserWithEmail} from '../../../shared/models/entity/users/User';

@Component({
  selector: 'administration-users-list',
  templateUrl: './administration-users-list.component.html',
  styleUrls: ['./administration-users-list.component.scss']
})
export class AdministrationUsersListComponent implements OnInit {

  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;

  @Input()
  public workspaces: Array<WorkspaceSimple>;

  public currentUser: CompleteCurrentUser;

  @Input()
  private initialUsers: PaginatedAdministrationUsers;
  public users: Array<UserAdministrationSearchResult> = [];
  public usersCount: number;

  public administrationUserSearchCriteria: AdministrationUserSearchCriteria = new AdministrationUserSearchCriteria();
  public pageCount: number = 0;
  public currentPage: number = this.DEFAULT_STARTING_PAGE;

  constructor(private router: Router,
              private administrationUserService: AdministrationUserService,
              private sessionService: SessionService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService,
              private notificationService: NotificationService,
              private translateService: TranslateService,
              private passwordService: PasswordService
  ) {
    sessionService.getCurrentUser().subscribe(currentUser => this.currentUser = currentUser);
  }

  public ngOnInit(): void {
    this.administrationUserSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
    this.administrationUserSearchCriteria.step = this.DEFAULT_STEP;

    if (this.initialUsers) {
      this.users = this.initialUsers.result;
      this.usersCount = this.initialUsers.metadata.count;
      this.pageCount = Math.ceil(this.initialUsers.metadata.count / this.DEFAULT_STEP);
    } else {
      this.loadUsers(this.administrationUserSearchCriteria, false);
    }
  }

  public sortUsers(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.administrationUserSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.administrationUserSearchCriteria.sort = sort.active;

    this.loadUsers(this.administrationUserSearchCriteria, false);
  }

  public goToCreateUserPage(): void {
    this.router.navigate(['/administration/users/new']);
  }

  public goToUserEditionPage(userId: string): void {
    this.router.navigate(['/administration/users/' + userId]);
  }

  public filter(administrationUserSearchCriteria: AdministrationUserSearchCriteria): void {
    this.administrationUserSearchCriteria = administrationUserSearchCriteria;
    this.loadUsers(this.administrationUserSearchCriteria, true);
  }

  private loadUsers(administrationUserSearchCriteria: AdministrationUserSearchCriteria, resetPagination: Boolean): void {
    if (resetPagination) {
      this.currentPage = this.DEFAULT_STARTING_PAGE;
      administrationUserSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
      administrationUserSearchCriteria.step = this.DEFAULT_STEP;
    }
    this.spinner.show();
    this.administrationUserService.search(administrationUserSearchCriteria).subscribe(
      paginatedUsers => {
        this.users = paginatedUsers.result;
        this.usersCount = paginatedUsers.metadata.count;
        this.pageCount = Math.ceil(paginatedUsers.metadata.count / this.DEFAULT_STEP);
        this.spinner.hide();
      }, _ => this.spinner.hide());
  }

  public onPageChanged(administrationUserSearchCriteria: AdministrationUserSearchCriteria): void {
    this.currentPage = administrationUserSearchCriteria.page;
    this.loadUsers(this.administrationUserSearchCriteria, false);
  }

  public onDisableUser(user: UserAdministrationSearchResult): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('administration.disabled.alert.title.user'),
      this.translateService.instant('administration.disabled.alert.message.user', {user: user.getFullName()}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')).subscribe(
      () => this.disable(user),
      () => noop()
    );
  }

  private disable(user: UserAdministrationSearchResult): void {
    this.spinner.show();
    this.administrationUserService.disable(user.id).subscribe(
      () => {
        this.notificationService.success('administration.disabled.user.success');
        this.spinner.hide();
        this.loadUsers(this.administrationUserSearchCriteria, false);
      },
      () => {
        this.notificationService.error('administration.disabled.user.error');
        this.spinner.hide();
      }
    );
  }

  public onResetPassword(user: UserWithEmail): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('forgotten.password.title'),
      this.translateService.instant('forgotten.password.message.user', {user: user.getFullName()}),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')
    ).subscribe(
      () => this.resetPassword(user),
      () => noop()
    );
  }

  private resetPassword(user: UserWithEmail): void {
    this.spinner.show();
    this.passwordService.renewUserPassword(user.email).subscribe(
      () => {
        this.notificationService.success('forgotten.password.success');
        this.spinner.hide();
      },
      () => {
        this.notificationService.error('forgotten.password.error');
        this.spinner.hide();
      }
    );
  }
}
