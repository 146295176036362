import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {take} from 'rxjs/operators';
import {CompanyService} from '../../services/company.service';
import {CompanyWithAgents} from '../../../shared/models/entity/companies/CompanyWithAgents';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class CompanyWithAgentsResolver extends AbstractResolver<CompanyWithAgents> {

  constructor(spinnerService: SpinnerService, private companyService: CompanyService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompanyWithAgents> {
    const companyId = route.paramMap.get('companyId');
    return companyId ?
      this.companyService.findWithAgentsById(companyId).pipe(take(1)) :
      of(CompanyWithAgents.newCompany()).pipe(take(1));
  }
}
