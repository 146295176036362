import {Type} from 'class-transformer';

export class Metrics {

  public allRequestCount: number;
  public waitingForCustomerRequestCount: number;
  public createdRequestCount: number;
  public inProgressRequestCount: number;
  public expediteRequestCount?: number;
  @Type(() => Map)
  public averageRequestsHandlingDelayInMinutesByCompany?: Map<string, number>;
  @Type(() => Map)
  public averageRequestsTreatmentDelayInMinutesByCompany?: Map<string, number>;
  public globalAverageRequestsHandlingDelayInMinutes: number;
  public globalAverageRequestsTreatmentDelayInMinutes: number;
  public feedbackCount?: number;
  public closedCount?: number;
}
