import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ClassificationService} from '../../../../app-root/services/classification.service';
import {Domain} from '../../../models/entity/classifications/Domain';
import {Category} from '../../../models/entity/classifications/Category';

@Component({
  selector: 'm-domain-form',
  templateUrl: './m-domain-form.component.html',
  styleUrls: ['./m-domain-form.component.scss']
})
export class MDomainFormComponent {

  @Input()
  public domain: Domain;

  @Input()
  public title: string = '';

  public spinnerName: string = 'domainFormSpinner';

  @Output()
  public domainSaved = new EventEmitter<Domain>();

  constructor(private classificationService: ClassificationService, private notificationService: NotificationService,
              private spinner: NgxSpinnerService) {}

  public save(): void {
    this.spinner.show(this.spinnerName);
    this.classificationService.save(this.domain).subscribe(
      domain => {
        this.spinner.hide(this.spinnerName);
        this.notificationService.success(this.domain.id ? 'notification.domain.updated' : 'notification.domain.saved');
        this.domainSaved.emit(domain);
      },
      () => {
        this.spinner.hide(this.spinnerName);
        this.notificationService.error(this.domain.id ? 'notification.domain.updated.error' : 'notification.domain.saved.error');
      });
  }

  public addCategory(): void {
    if (this.domain.categories) {
      this.domain.categories.push(new Category());
    } else {
      this.domain.categories = [new Category()];
    }
  }

  public removeCategory(index: number) {
    this.domain.categories.splice(index, 1);
  }
}
