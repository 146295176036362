import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {RequestSearchCriteria} from '../../../shared/models/criterias/RequestSearchCriteria';
import {combineLatest, fromEvent, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {RequestService} from '../../../app-root/services/request.service';
import {ActivatedRoute, DefaultUrlSerializer, Params, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {NotificationService} from '../../../app-root/services/notification.service';
import {AInputComponent} from '../../../shared/lib-components/atoms/forms/a-input/a-input.component';
import {TagService} from '../../../app-root/services/tag.service';
import {BlobService} from '../../../app-root/services/blop.service';
import {PaginatedRequests} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedRequests';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {buildExportFormat, ExportFormat} from '../../../shared/models/entity/enums/ExportFormat';
import {SearchResultRequest} from '../../../shared/models/entity/requests/RequestSearchResult';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {CompanySimple} from '../../../shared/models/entity/companies/CompanySimple';

@Component({
  templateUrl: './requests-list-page.component.html',
  styleUrls: ['./requests-list-page.component.scss']
})
export class RequestsListPageComponent implements OnInit, AfterViewInit {

  public EXCEL_EXPORT_FORMAT = ExportFormat.EXCEL;
  public DEFAULT_STEP: number = 10;
  public DEFAULT_STARTING_PAGE: number = 0;

  public searchCriteria: RequestSearchCriteria = new RequestSearchCriteria();
  public effectiveSearchCriteria: RequestSearchCriteria = new RequestSearchCriteria();
  public requestPageCount: number = 0;
  public currentPage: number = this.DEFAULT_STARTING_PAGE;

  public requests: Array<SearchResultRequest>;
  public resultsCount: number = 0;

  public showFilters: boolean = false;
  public existingTags: String[] = [];

  public currentUser: CompleteCurrentUser;
  public currentCompany: CompanySimple;
  public currentUserCompanies: Array<CompanySimple>;
  public isUserRole: boolean = true;
  private effectiveQueryParams: Params;

  @ViewChild('searchInputComponent', {static: true})
  private searchInputComponent: AInputComponent;

  constructor(private sessionService: SessionService,
              private requestService: RequestService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private notificationService: NotificationService,
              private activatedRoute: ActivatedRoute,
              private tagService: TagService
  ) {

    combineLatest([
      activatedRoute.queryParams,
      this.tagService.getExistingTagsForRequest()
    ]).subscribe(([params, existingTags]: [Params, Array<string>]) => {
      this.existingTags = existingTags;
      this.currentPage = params.page ? +params.page : this.DEFAULT_STARTING_PAGE;

      this.searchCriteria = RequestSearchCriteria.fromParams(params, this.DEFAULT_STEP, this.DEFAULT_STARTING_PAGE);
      this.effectiveSearchCriteria = Object.assign({}, this.searchCriteria);
      this.showFilters = !RequestSearchCriteria.isEmpty(this.searchCriteria, true);

      this.loadRequests(this.effectiveSearchCriteria).subscribe();

      this.effectiveQueryParams = this.getQueryParams();
    });
  }

  ngOnInit(): void {
    this.sessionService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      this.isUserRole = this.currentUser.isUser ();
      this.currentUserCompanies = this.currentUser.getCompaniesInActiveWorkspace();
      this.currentCompany = this.currentUser.activeCompany;
    });
  }

  ngAfterViewInit(): void {
    fromEvent(this.searchInputComponent.inputField.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      filter(text => text != null && !this.showFilters),
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((_: string) => {
      const criteria = new RequestSearchCriteria();
      criteria.q = this.searchCriteria.q;
      this.searchNavigate(criteria, true);
    });
  }

  public toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  private loadRequests(searchCriteria: RequestSearchCriteria): Observable<PaginatedRequests> {
    this.spinner.show();
    return this.requestService.search(searchCriteria)
      .pipe(
        tap(paginatedRequests => {
          this.requests = paginatedRequests.result;
          this.resultsCount = paginatedRequests.metadata.count;
          this.requestPageCount = Math.ceil(paginatedRequests.metadata.count / this.DEFAULT_STEP);
          this.spinner.hide();
        }),
        catchError(() => {
          this.requests = null;
          this.notificationService.error('request.list.search.error');
          this.spinner.hide();
          return of(null);
        })
      );
  }

  public sortRequests(sort: Sort): void {
    if (!sort.active || sort.direction === '' && sort.active === 'code') {
      return;
    }

    this.effectiveSearchCriteria.sort = sort.active;
    this.effectiveSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.searchNavigate(this.effectiveSearchCriteria, false);
  }

  public getQueryParams(): Params {
    const criteria: RequestSearchCriteria = Object.assign({}, this.effectiveSearchCriteria);
    criteria.step = null;
    criteria.page = null;
    return RequestSearchCriteria.toCleanQueryParams(criteria);
  }

  public getRequestUrl(code: string): string {
    return new DefaultUrlSerializer()
      .serialize(this.router.createUrlTree([`/workspaces/${this.currentUser.activeWorkspace.code}/requests/${code}`], {queryParams: this.effectiveQueryParams}));
  }

  public cleanFilters(): void {
    this.searchCriteria = new RequestSearchCriteria();
  }

  public extractRequestList(exportFormat: ExportFormat): void {
    this.spinner.show();
    this.requestService.extractRequestList(this.searchCriteria, exportFormat).subscribe(
      blob => {
        this.spinner.hide();
        BlobService.downloadBlob(blob, buildExportFormat(exportFormat).requestFileName);
      },
      () => {
        this.notificationService.error('request.list.extract.error');
        this.spinner.hide();
      }
    );
  }

  public searchNavigate(criteria: RequestSearchCriteria, resetPagination: boolean): void {
    if (resetPagination) {
      this.searchCriteria.page = this.DEFAULT_STARTING_PAGE;
      this.searchCriteria.step =  this.DEFAULT_STEP;
      this.searchCriteria.sort = null;
      this.searchCriteria.sortOrder = null;
    }

    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'requests'],
      {queryParams: RequestSearchCriteria.toCleanQueryParams(criteria)});
  }
}
