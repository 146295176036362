import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {SimpleRequest} from '../../shared/models/entity/requests/RequestSimple';
import {CompleteMessage} from '../../shared/models/entity/requests/CompleteMessage';
import {RequestSearchCriteria} from '../../shared/models/criterias/RequestSearchCriteria';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {RequestStatus} from '../../shared/models/entity/enums/RequestStatus';
import {RequestPriority} from '../../shared/models/entity/enums/RequestPriority';
import {QualificationDataModification} from '../../shared/models/entity/feedbacks/QualificationData';
import {PaginatedRequests} from '../../shared/models/entity/paginated/paginated-entities/PaginatedRequests';
import {SearchResultRequest} from '../../shared/models/entity/requests/RequestSearchResult';
import {CompleteRequest} from '../../shared/models/entity/requests/CompleteRequest';
import {buildExportFormat, ExportFormat} from '../../shared/models/entity/enums/ExportFormat';
import {User} from '../../shared/models/entity/users/User';
import {TransferRequest} from '../../shared/models/entity/TransferRequest';

@Injectable()
export class RequestService {

  private baseUrl = '/api/requests';

  constructor(private http: HttpClient) {}

  public findRelatedRequestsForCompanyAgent(companyId: string, agentId: string): Observable<Array<SearchResultRequest>>  {
    return this.http.get<SearchResultRequest>(`${this.baseUrl}/company/${companyId}/agent/${agentId}`)
      .pipe(map((principal: any) => plainToClass(SearchResultRequest, principal as [Object])));
  }

  public findByCode(code: string): Observable<CompleteRequest> {
    return this.http.get<CompleteRequest>(this.baseUrl + '/' + code)
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public substituteAgentOnCompany(companyId: string, removedAgentId: string, substituteAgentId: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/substitute/${companyId}`, {removedAgentId, substituteAgentId});
  }

  public sendMessage(requestId: string, message: CompleteMessage): Observable<CompleteMessage> {
    return this.http.post<CompleteMessage>(this.baseUrl + `/${requestId}/message`, message)
      .pipe(map((principal: any) => plainToClass(CompleteMessage, principal as Object)));
  }

  public search(searchCriteria: RequestSearchCriteria): Observable<PaginatedRequests> {
    return this.http.get<PaginatedRequests>(this.baseUrl, {params: RequestSearchCriteria.toHttpParams(searchCriteria)})
      .pipe(map((principal: any) => plainToClass(PaginatedRequests, principal as Object)));
  }

  public searchSimple(searchCriteria: RequestSearchCriteria): Observable<Array<SimpleRequest>> {
    return this.http.get<Array<SimpleRequest>>(this.baseUrl + '/simple',
      {params: RequestSearchCriteria.toHttpParamsWithoutPagination(searchCriteria)})
      .pipe(map((principal: any) => plainToClass(SimpleRequest, principal as [Object])));
  }

  public updateStatus(requestId: string, newStatus: RequestStatus): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/status` , {status: newStatus})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updatePriority(requestId: string, newPriority: RequestPriority): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/priority`, {priority: newPriority})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateTags(requestId: string, newTags: Array<String>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/tags`, {tags: newTags})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public create(request: CompleteRequest): Observable<CompleteRequest> {
    return this.http.post<CompleteRequest>(this.baseUrl, request)
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public createFromFeedback(feedbackId: string, qualificationData: QualificationDataModification): Observable<CompleteRequest> {
    return this.http.post<CompleteRequest>(`${this.baseUrl}/feedback/${feedbackId}`, qualificationData)
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateUsers(requestId: string, users: Array<User>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/users` , {users: users.map(user => user.id)})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateAgents(requestId: string, agents: Array<User>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/agents` , {agents: agents.map(agent => agent.id)})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateRelatedRequests(requestId: string, relatedRequests: Array<SimpleRequest>): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/relatedRequests` ,
      {relatedRequests: relatedRequests.map(request => request.id)})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public sendReminder(requestId: string) {
    return this.http.put<void>(this.baseUrl + `/${requestId}/reminder`, {});
  }

  public extractRequestList(searchCriteria: RequestSearchCriteria, exportFormat: ExportFormat): Observable<Blob> {
    return this.http.get(buildExportFormat(exportFormat).requestUrl, {
      params: RequestSearchCriteria.toHttpParamsWithoutPagination(searchCriteria),
      responseType: 'blob'
    });
  }

  public updateDomain(requestId: string, domainId: string): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/domain`, {domainId: domainId})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public updateCategory(requestId: string, categoryId: string): Observable<CompleteRequest> {
    return this.http.put<CompleteRequest>(this.baseUrl + `/${requestId}/category`, {categoryId: categoryId})
      .pipe(map((principal: any) => plainToClass(CompleteRequest, principal as Object)));
  }

  public transferRequest(transfer: TransferRequest): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${transfer.requestId}/transfer`, transfer);
  }
}
