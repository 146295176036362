import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionService} from '../../../app-root/services/session.service';
import {PaginatedCriteria, SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {Observable} from 'rxjs/internal/Observable';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {PaginatedWorkspaceUsers} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUsers';
import {WorkspaceUserService} from '../../../app-root/services/workspace-user.service';
import {Company} from '../../../shared/models/entity/companies/Company';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {WorkspaceUserWithCompanies} from '../../../shared/models/entity/users/WorkspaceUserWithCompanies';

@Component({
  selector: 'users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {

  public currentUser: CompleteCurrentUser;
  public DEFAULT_STARTING_PAGE: number = 0;
  public DEFAULT_STEP: number = 10;
  public currentPage: number = this.DEFAULT_STARTING_PAGE;
  public pageCount: number = 0;
  public userSearchCriteria: WorkspaceUserSearchCriteria;
  public paginatedUsers: Array<WorkspaceUserWithCompanies>;
  private _initialUsersSet: PaginatedWorkspaceUsers;
  public usersCount: number;

  @Input()
  public set initialUsersSet(paginatedUsers: PaginatedWorkspaceUsers) {
    this._initialUsersSet = paginatedUsers;
    this.reset(paginatedUsers);
  }

  @Input()
  public allCompanies: Array<Company>;

  @Input()
  public userCount: number;

  @Output()
  public reloadUsers: EventEmitter<PaginatedCriteria> = new EventEmitter<PaginatedCriteria>();

  constructor(private router: Router,
              private utilsService: UtilsService,
              private translateService: TranslateService,
              private sessionService: SessionService,
              private workspaceUserService: WorkspaceUserService
  ) {
    this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  public ngOnInit(): void {
    this.userSearchCriteria = WorkspaceUserSearchCriteria.newEmptyWithPagination(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);

    if (this._initialUsersSet) {
      this.paginatedUsers = this._initialUsersSet.result;
      this.usersCount = this._initialUsersSet.metadata.count;
      this.pageCount = Math.ceil(this._initialUsersSet.metadata.count / this.DEFAULT_STEP);
    } else {
      this.retrieveUsers(this.userSearchCriteria)
        .subscribe(users => {
          this.paginatedUsers = users.result;
          this.usersCount = users.metadata.count;
          this.pageCount = Math.ceil(users.metadata.count / this.DEFAULT_STEP);
       });
    }
  }

  public sortUsers(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }

    this.userSearchCriteria.sort = sort.active;
    this.userSearchCriteria.sortOrder = SortOrder[sort.direction.toUpperCase()];
    this.loadUsers(this.userSearchCriteria, false);
  }

  public loadUsers(userSearchCriteria: WorkspaceUserSearchCriteria, resetPagination: Boolean): void {
    if (resetPagination) {
      this.currentPage = this.DEFAULT_STARTING_PAGE;
      userSearchCriteria.page = this.DEFAULT_STARTING_PAGE;
      userSearchCriteria.step = this.DEFAULT_STEP;
    }
    this.retrieveUsers(this.userSearchCriteria).subscribe(users => {
      this.paginatedUsers = users.result;
      this.usersCount = users.metadata.count;
      this.pageCount = Math.ceil(users.metadata.count / this.DEFAULT_STEP);
    });
  }

  public filter(userSearchCriteria: WorkspaceUserSearchCriteria): void {
    this.userSearchCriteria = userSearchCriteria;
    this.loadUsers(this.userSearchCriteria, true);
  }

  public goToAddUserPage(): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'settings', 'users', 'new']);
  }

  public onUserDisabled(): void {
    this.reloadUsers.emit(this.userSearchCriteria);
  }

  public reset(newSet: PaginatedWorkspaceUsers): void {
    this.currentPage = this.DEFAULT_STARTING_PAGE;
    this.userSearchCriteria = new WorkspaceUserSearchCriteria(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    this.paginatedUsers = newSet.result;
    this.pageCount = Math.ceil(newSet.metadata.count / this.DEFAULT_STEP);
  }

  // equivalent to reset(emptySet)
  public resetToEmpty(): void {
    this.currentPage = this.DEFAULT_STARTING_PAGE;
    this.userSearchCriteria = new WorkspaceUserSearchCriteria(this.DEFAULT_STARTING_PAGE, this.DEFAULT_STEP);
    this.paginatedUsers = [];
    this.pageCount = 0;
  }

  public retrieveUsers(userSearchCriteria: WorkspaceUserSearchCriteria): Observable<PaginatedWorkspaceUsers> {
    return this.workspaceUserService.findUsers(userSearchCriteria).pipe(
      catchError(  () => {
        this.resetToEmpty();
        return of(null);
      })
    );
  }

  public onPageChanged(userSearchCriteria: WorkspaceUserSearchCriteria): void {
    this.currentPage = userSearchCriteria.page;
    this.retrieveUsers(userSearchCriteria)
      .subscribe(users => {
        this.paginatedUsers = users.result;
        this.pageCount = Math.ceil(users.metadata.count / this.DEFAULT_STEP);
      });
  }
}
