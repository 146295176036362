import {User} from '../users/User';
import {Type} from 'class-transformer';
import {Attachment} from '../Attachment';
import {CompleteCurrentUser} from '../users/CompleteCurrentUser';

export class CompleteMessage {

  @Type(() => Date)
  public creationDate: Date;
  public text: string;
  public restrictedAccess: boolean = false;
  @Type(() => User)
  public restrictedUsers: User[] = [];
  @Type(() => User)
  public author: User;
  @Type(() => Attachment)
  public attachments: Array<Attachment>;

  static from(user: CompleteCurrentUser, messageText: string, attachments: Array<Attachment>, isPrivate: boolean, restrictedUsers: User[]): CompleteMessage {
    const message = new CompleteMessage();
    message.creationDate = new Date();
    message.text = messageText;
    message.attachments = attachments;
    message.author = User.fromCompleteCurrentUser(user);
    message.restrictedAccess = isPrivate;
    message.restrictedUsers = restrictedUsers;
    return message;
  }
}
