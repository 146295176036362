export enum ExportFormat {
  EXCEL = 'EXCEL',
  CSV = 'CSV'
}

export interface ExportFormatData {
  requestUrl: string;
  requestFileName: string;
}

export function buildExportFormat(exportFormat: ExportFormat): ExportFormatData {

  switch (exportFormat) {
    case ExportFormat.EXCEL:
      return {
        requestUrl: `/api/requests/extract/${ExportFormat.EXCEL}`,
        requestFileName: 'reportRequest.xlsx'
      };
    case ExportFormat.CSV:
      return {
        requestUrl: `/api/requests/extract/${ExportFormat.CSV}`,
        requestFileName: 'reportRequest.csv'
      };
  }
}
