import {RequestStatus} from '../enums/RequestStatus';

export class SimpleRequest {

  public id: string;
  public code: string;
  public topic: string;
  public status: RequestStatus;
  public companyId: string;

  public getCodeWithTopic(): string {
    return `[${this.code}] - ${this.topic}`;
  }
}
