import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CompanySimple} from '../../../../models/entity/companies/CompanySimple';

@Component({
  selector: 'a-select-simple-company',
  templateUrl: './a-select-simple-company.component.html',
  styleUrls: ['./a-select-simple-company.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectSimpleCompanyComponent),
      multi: true
    }
  ]
})
export class ASelectSimpleCompanyComponent implements ControlValueAccessor {
  @Input()
  public disabled: boolean = false;

  @Input()
  public placeholder: string = '';

  @Input()
  public companies: Array<CompanySimple>;

  public selectedCompany: CompanySimple;
  private onNgChange: (value: CompanySimple) => void;
  private onNgTouched: () => void;

  @Input()
  public clearable: boolean = true;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(company: CompanySimple): void {
    this.selectedCompany = company;
  }

  public onChange(company: CompanySimple): void {
    this.selectedCompany = company;
    this.onNgChange(company);
  }

  public compare(company1: CompanySimple, company2: CompanySimple): boolean {
    return company1 != null && company2 != null && company1.id === company2.id;
  }
}
