import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {CompanyService} from '../../../app-root/services/company.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {MetricsService} from '../../../app-root/services/metrics.service';
import {Metrics} from '../../models/Metrics';
import {filter, switchMap, tap} from 'rxjs/operators';
import {requestOpenStatuses, RequestStatus} from '../../models/entity/enums/RequestStatus';
import {RequestPriority} from '../../models/entity/enums/RequestPriority';
import {AppService} from '../../../app-root/services/app.service';
import {App} from '../../models/App';
import {Location} from '@angular/common';
import {CompleteCurrentUser} from '../../models/entity/users/CompleteCurrentUser';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  host: {
    '[class._retracted]': 'menuOpened',
  },
})
export class AppMenuComponent implements OnInit {

  public requestOpenStatuses: Array<RequestStatus> = requestOpenStatuses();
  public IN_PROGRESS: RequestStatus = RequestStatus.IN_PROGRESS;
  public WAITING_FOR_CUSTOMER: RequestStatus = RequestStatus.WAITING_FOR_CUSTOMER;
  public CREATED: RequestStatus = RequestStatus.CREATED;
  public CLOSED: RequestStatus = RequestStatus.CLOSED;
  public EXPEDITE: RequestPriority = RequestPriority.EXPEDITE;

  public currentUser: CompleteCurrentUser;
  public currentLocationPath: string;
  public metrics: Metrics;
  public appData: App;
  public workspaceCode: string;

  @Output()
  public sideMenuOpenedEvent = new EventEmitter<boolean>();

  @Input()
  public menuOpened = false;

  constructor(private sessionService: SessionService,
              private companyService: CompanyService,
              private router: Router,
              private metricsService: MetricsService,
              private activatedRoute: ActivatedRoute,
              appService: AppService,
              public location: Location
  ) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.currentLocationPath = location.path();
    });
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => {
      this.getCurrentMetrics();
    });

    this.sessionService.getCurrentUser().pipe(
      filter(user => !!user),
      switchMap(() => appService.getAppData())
    ).subscribe(appData => this.appData = appData);
  }

  ngOnInit(): void {
    this.getCurrentMetrics();
  }

  public toggleRetractMenu(): void {
    this.sideMenuOpenedEvent.next(!this.menuOpened);
  }

  public logout(): void {
    this.sessionService.logoutFrom(this.currentUser.activeWorkspace?.code);
  }

  public getCurrentMetrics(): void {
    this.sessionService.getCurrentUser().pipe(
      filter(currentUser => currentUser != null),
      tap(currentUser => this.currentUser = currentUser),
      tap(currentUser => this.workspaceCode = currentUser.activeWorkspace.code),
      filter(user => !!user && !!user.activeWorkspace),
      switchMap(() => this.metricsService.getCurrent())
    ).subscribe(metrics => this.metrics = metrics);
  }

  public goToNewRequestPage(): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'requests', 'new']);
  }

  public isOnNewRequestPage(): boolean {
    return this.router.url === `/workspaces/${this.workspaceCode}/requests/new`;
  }

  public goToRequestListPage(): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'requests']);
  }

  public isOnDashboardPage(): boolean {
    return this.router.url === `/workspaces/${this.workspaceCode}/dashboard`;
  }

  public isOnRequestListPage(): boolean {
    return this.router.url === `/workspaces/${this.workspaceCode}/requests`;
  }

  public isOnSettingsPage(): boolean {
    return this.router.url.startsWith(`/workspaces/${this.workspaceCode}/settings`);
  }

  public goToSettingsPage(): void {
    if (this.currentUser.isUser()) {
      this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'me']);
    } else if (this.isOnDashboardPage() && this.currentUser.isAgentAdmin()) {
      this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'news']);
    } else {
      this.router.navigate(['workspaces', this.workspaceCode, 'settings']);
    }
  }

  public goToAdministration(): void {
    this.router.navigate(['administration'], {fragment: 'users'});
  }
}
