import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {PaginatedCriteria} from '../../../models/entity/paginated/PaginatedCriteria';
import {MatButtonToggleChange} from '@angular/material/button-toggle/button-toggle';

@Component({
  selector: 'm-paginator',
  templateUrl: './m-paginator.component.html',
  styleUrls: ['./m-paginator.component.scss']
})
export class MPaginatorComponent<CRITERIA extends PaginatedCriteria> implements OnInit, OnChanges {

  private static MAX_DISPLAYABLE_PAGE: number = 5;

  @Input()
  public currentPage: number;

  // Ref to a paginated criteria, used to emit this criteria with populated page and step field
  @Input()
  public paginatedCriteria: CRITERIA;

  @Input()
  public step: number = 10;

  @Input()
  public pageCount: number;

  @Input()
  public startingPage: number = 0;

  @Output()
  public pageChanged: EventEmitter<CRITERIA> = new EventEmitter();

  public pages: Array<DisplayablePage> = [];

  public getCriterias(): PaginatedCriteria {
    if (this.step < 0 || this.currentPage < 0) {
      throw new DOMException('App-Paginator: Step or CurrentPage is undefined');
    } else {
      return new PaginatedCriteria(this.currentPage, this.step);
    }
  }

  public btnHandler(event: MatButtonToggleChange): void {
    this.currentPage = event.value;
    this.pages = this.computePages();
    const paginatedCriteria = this.getCriterias();
    this.paginatedCriteria.page = paginatedCriteria.page;
    this.paginatedCriteria.step = paginatedCriteria.step;
    this.pageChanged.emit(this.paginatedCriteria);
  }

  public ngOnInit() {
    if (this.pageCount === null || this.pageCount === undefined || isNaN(this.pageCount)) {
      throw new DOMException(`app-paginator: wrong number of pages (${this.pageCount})`);
    }
    this.currentPage = this.startingPage;
    this.pages = this.computePages();
  }

  public ngOnChanges() {
    this.pages = this.computePages();
  }

  private computePages(): Array<DisplayablePage> {
    if (!this.pageCount || this.pageCount <= 1) {
      return [];
    }

    let overlapsBeforeExist = false;
    let overlapsAfterExist = false;
    return Array.from(Array(this.pageCount).keys())
      .map(valIdx => new DisplayablePage(valIdx, (valIdx + 1).toString(), this.isPageDisplayable(valIdx)))
      .map(page => {
        if (!page.isDisplay) {
          if (!overlapsAfterExist && page.index > this.currentPage) {
            overlapsAfterExist = true;
            page.toDisplayableOverlaps();
          } else if (!overlapsBeforeExist && page.index < this.currentPage) {
            overlapsBeforeExist = true;
            page.toDisplayableOverlaps();
          }
        }
        return page;
      })
      .filter(page => page.isDisplay);
  }

  private isPageDisplayable(pageIndex: number): boolean {
    return this.pageCount <= MPaginatorComponent.MAX_DISPLAYABLE_PAGE ||
      pageIndex === 0 || pageIndex === this.pageCount - 1 || (pageIndex >= this.currentPage - 1 && pageIndex <= this.currentPage + 1);
  }
}

export class DisplayablePage {
  index: number;
  text: string;
  isDisplay: boolean;
  clickable: boolean;

  constructor(index: number, text: string, isDisplay: boolean) {
    this.index = index;
    this.text = text;
    this.isDisplay = isDisplay;
    this.clickable = isDisplay;
  }

  public toDisplayableOverlaps(): void {
    this.text = '...';
    this.isDisplay = true;
    this.clickable = false;
  }
}
