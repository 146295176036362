import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import Swal from 'sweetalert2';

@Injectable()
export class AlertService {

  public confirmWithSelectInput(title: string,
                               message: string,
                               confirmText: string,
                               cancelText: string,
                               selectOptions: Map<string, string>
  ): Observable<string> {

    return new Observable<string>((observer: Observer<string>) => {
      Swal.fire({
        title,
        text: message,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: confirmText,
        showCancelButton: true,
        cancelButtonText: cancelText,
        input: 'select',
        inputOptions: selectOptions
      }).then(result => {
        if (result.value) {
          observer.next(result.value);
        } else if (result.dismiss) {
          observer.error(null);
        }
        observer.complete();
      });
    });
  }

  public confirmWithCustomBtn(title: string,
                              message: string,
                              confirmText: string,
                              cancelText: string
  ): Observable<void> {

    return new Observable<void>((observer: Observer<void>) => {
      Swal.fire({
        title,
        text: message,
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonText: confirmText,
        showCancelButton: true,
        cancelButtonText: cancelText
      }).then(result => {
        if (result.value) {
          observer.next(null);
        } else if (result.dismiss) {
          observer.error(null);
        }
        observer.complete();
      });
    });
  }
}
