import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {SpinnerService} from '../services/spinner.service';

const ROOT_SPINNER_NAME: string = 'root-spinner';
export abstract class AbstractResolver<T> implements Resolve<T> {

  protected constructor(private spinnerService: SpinnerService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T> {
    this.spinnerService.show(ROOT_SPINNER_NAME);
    return this.doResolve(route, state)
      .pipe(finalize(() => this.spinnerService.hide(ROOT_SPINNER_NAME)));
  }

  protected abstract doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T>;
}
