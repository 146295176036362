export enum RequestStatus {
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_CUSTOMER = 'WAITING_FOR_CUSTOMER',
  CLOSED = 'CLOSED',
}

export function requestStatuses(): Array<RequestStatus> {
  return [RequestStatus.CREATED, RequestStatus.IN_PROGRESS, RequestStatus.WAITING_FOR_CUSTOMER, RequestStatus.CLOSED];
}

export function requestOpenStatuses(): Array<RequestStatus> {
  return [RequestStatus.CREATED, RequestStatus.IN_PROGRESS, RequestStatus.WAITING_FOR_CUSTOMER];
}
