import {Routes} from '@angular/router';
import {DashboardPageComponent} from './pages/dashboard-page.component';
import {LoggedInGuard} from '../app-root/guards/logged-in.guard';
import {CurrentDashboardCustomizationResolver} from '../app-root/resolvers/workspace/current-dashboard-customization.resolver';

export const dashboardRoutes: Routes = [
  {
    path: '', component: DashboardPageComponent, canActivate: [LoggedInGuard], resolve: {
      dashboardHeader: CurrentDashboardCustomizationResolver
    }
  }
];
