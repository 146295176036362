import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {PaginatedResponseTemplates} from "../../../../shared/models/entity/paginated/paginated-entities/PaginatedResponseTemplates";

@Component({
  selector: 'app-templates-tab',
  templateUrl: './templates-tab.component.html',
  styleUrls: ['./templates-tab.component.scss']
})
export class TemplatesTabComponent {

  public initialResponseTemplates: PaginatedResponseTemplates;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.initialResponseTemplates = data['initialResponseTemplates'];
    });
  }

}
