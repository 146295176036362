import {Type} from 'class-transformer';
import {Entity} from '../common/Entity';
import {Category} from './Category';

export class Domain extends Entity {

  public title: string;
  public iconClass: string;
  @Type(() => Category)
  public categories?: Array<Category> = [];
}
