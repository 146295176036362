import {Injectable} from '@angular/core';

@Injectable()
export class UtilsService {

  public compareStrings(a: string, b: string, isAsc: boolean): number {
    return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public isUrl(str: string): boolean {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  }

  public compareDate(a: Date, b: Date, isAsc: boolean): number {
    return (a > b ? 1 : a < b ? -1 : 0) * (isAsc ? 1 : -1);
  }
}
