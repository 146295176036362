import {Type} from 'class-transformer';
import {Domain} from '../classifications/Domain';
import {CompanySimple} from './CompanySimple';
import {WorkspaceSimple} from '../workspaces/Workspace';

export class Company extends CompanySimple {

  @Type(() => Domain)
  subscribedDomains?: Array<Domain>;
  @Type(() => WorkspaceSimple)
  workspace: WorkspaceSimple;

  static from(companySimple: CompanySimple, workspaceSimple: WorkspaceSimple): Company {
    const company = new Company();
    company.id = companySimple.id;
    company.code = companySimple.code;
    company.name = companySimple.name;
    company.workspace = workspaceSimple;
    company.subscribedDomains = [];
    return company;
  }

  public toSimple(): CompanySimple {
    const simple = new CompanySimple();
    simple.id = this.id;
    simple.code = this.code;
    simple.name = this.name;
    return simple;
  }
}

