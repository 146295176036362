import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  templateUrl: './email-sent-page.component.html',
  styleUrls: ['./email-sent-page.component.scss']
})
export class EmailSentPageComponent {

  constructor(private router: Router){}

  public goToLoginPage(): void {
    this.router.navigate(['login']);
  }
}
