import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClassificationService} from '../../../../../app-root/services/classification.service';
import {Domain} from '../../../../models/entity/classifications/Domain';

@Component({
  selector: 'a-select-domains',
  templateUrl: './a-select-domains.component.html',
  styleUrls: ['./a-select-domains.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectDomainsComponent),
      multi: true
    }
  ]
})
export class ASelectDomainsComponent implements ControlValueAccessor {

  public domains: Array<Domain>;
  public selectedDomains: Array<Domain>;
  private onNgChange: (values: Array<Domain>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'domains';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  constructor(classificationService: ClassificationService) {
    classificationService.findAllDomainsByCurrentWorkspace().subscribe(domains => this.domains = domains);
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(domains: Array<Domain>): void {
    this.selectedDomains = domains;
  }

  public onChange(domains: Array<Domain>): void {
    this.selectedDomains = domains;
    this.onNgChange(domains);
  }

  public compare(domain1: Domain, domain2: Domain): boolean {
    return domain1 != null && domain2 != null && domain1.id === domain2.id;
  }
}
