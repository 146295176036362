import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Domain} from '../../shared/models/entity/classifications/Domain';

@Injectable()
export class ClassificationService {

  private baseUrl = '/api/classifications';

  constructor(private http: HttpClient) {}

  public findDomainByIds(domainIds: Array<string>) {
    let params = new HttpParams();
    domainIds.forEach(domainId => params = params.append('domainIds', domainId));
    params = params.set('includeAllDomainsOnEmptyIds', 'false');
    return this.http.get<Array<Domain>>(`${this.baseUrl}/domains`, {params})
      .pipe(map((principal: any) => plainToClass(Domain, principal as [Object])));
  }

  public toHttpParams(companiesId: Array<string>): HttpParams {
    let httpParams = new HttpParams();
    companiesId.forEach(companyId => httpParams = httpParams.append('companiesId', companyId));
    return httpParams;
  }

  public findMutualDomainsByCompanies(companiesId: Array<string>): Observable<Array<Domain>> {
    return this.http.get<Array<Domain>>(`${this.baseUrl}/company/mutual`, {params: this.toHttpParams(companiesId)})
      .pipe(map((principal: any) =>
        plainToClass(Domain, principal as [Object])));
  }

  public findDomainsByCompany(companyId: String): Observable<Array<Domain>> {
    return this.http.get<Array<Domain>>(`${this.baseUrl}/company/${companyId}`)
      .pipe(map((principal: any) =>
        plainToClass(Domain, principal as [Object])));
  }

  public findAllDomainsByCurrentWorkspace(): Observable<Array<Domain>> {
    return this.http.get<Array<Domain>>(this.baseUrl + '/domains')
      .pipe(map((principal: any) => plainToClass(Domain, principal as [Object])));
  }

  public findDomainId(domainId: string): Observable<Domain> {
    return this.http.get<Domain>(`${this.baseUrl}/domains/${domainId}`)
      .pipe(map((principal: any) => plainToClass(Domain, principal as Object)));
  }

  public save(domain: Domain): Observable<Domain> {
    return domain.id ? this.update(domain) : this.create(domain);
  }

  private update(domain: Domain): Observable<Domain> {
    return this.http.put<Domain>(`${this.baseUrl}/domains/${domain.id}`, domain)
      .pipe(map((principal: any) => plainToClass(Domain, principal as Object)));
  }

  private create(domain: Domain): Observable<Domain> {
    return this.http.post<Domain>(`${this.baseUrl}/domains`, domain)
      .pipe(map((principal: any) => plainToClass(Domain, principal as Object)));
  }
}
