import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-select',
  templateUrl: './a-select.component.html',
  styleUrls: ['./a-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectComponent),
      multi: true
    }
  ]
})
export class ASelectComponent implements ControlValueAccessor, AfterViewInit {

  @Input()
  public label: string;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  public hasIcon: boolean;

  public data: string;

  private onNgChange: (value: string) => void;
  private onNgTouched: () => void;

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('select') selectField: ElementRef;
  @Input() public focus: boolean = false;

  ngAfterViewInit() {
    if (this.focus) {
      this.selectField.nativeElement.focus();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(obj: string): void {
    this.data = obj;
  }

  public onChange(data: string): void {
    this.data = data;
    this.onNgChange(data);
  }

  public onDelete(): void {
    this.deleted.emit();
  }

}
