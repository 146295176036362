import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {Directive, Injectable} from '@angular/core';
import {ResponseTemplateService} from '../../app-root/services/responseTemplate.service';
import {first, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Directive({
  selector: '[uniqueTemplateName]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueTemplateNameValidatorDirective, multi: true}]
})
@Injectable()
export class UniqueTemplateNameValidatorDirective implements AsyncValidator {

  constructor(private responseTemplateService: ResponseTemplateService) {}

  public validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.responseTemplateService
      .isExistingName(control.value)
      .pipe(
        first(),
        switchMap(isExisting => {
          if (isExisting) {
            return of({alreadyExistingName: true});
          } else {
            return of(null);
          }
        })
      );
  }

}
