import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Company} from '../../../../models/entity/companies/Company';

@Component({
  selector: 'a-select-company',
  templateUrl: './a-select-company.component.html',
  styleUrls: ['./a-select-company.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCompanyComponent),
      multi: true
    }
  ]
})
export class ASelectCompanyComponent implements ControlValueAccessor {

  @Input()
  public placeholder: string = '';

  @Input()
  public companies: Array<Company>;
  public selectedCompany: Company;
  private onNgChange: (values: Company) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'companies';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  constructor() {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(company: Company): void {
    this.selectedCompany = company;
  }

  public onChange(company: Company): void {
    this.selectedCompany = company;
    this.onNgChange(company);
  }

  public compare(company1: Company, company2: Company): boolean {
    return company1 != null && company2 != null && company1.id === company2.id;
  }
}
