import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-toggle',
  templateUrl: './a-toggle.component.html',
  styleUrls: ['./a-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AToggleComponent),
      multi: true
    }
  ]
})
export class AToggleComponent implements ControlValueAccessor {

  @Input()
  public name: string;

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  public data: boolean;

  private onNgChange: (value: boolean) => void;
  private onNgTouched: () => void;

  constructor() { }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  public writeValue(obj: boolean): void {
    this.data = obj;
  }

  public onChange(data: boolean): void {
    this.data = data;
    this.onNgChange(data);
  }
}
