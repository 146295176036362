import {Component, Input} from '@angular/core';
import {FormStep} from '../m-multi-step-form/m-multi-step-form.component';

@Component({
    selector: 'm-form-step-thread',
    templateUrl: './m-form-step-thread.component.html',
    styleUrls: ['./m-form-step-thread.component.scss']
})
export class MFormStepThreadComponent {

  @Input()
  public stepsConfig: FormStep[];

  @Input()
  public activeFormStepIndex = 0;

}

