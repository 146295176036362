import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {requestRoutes} from '../request/request-routing.module';
import {loginRoutes} from '../login/login-routing.module';
import {settingsRoutes} from '../settings/settings-routing.module';
import {dashboardRoutes} from '../dashboard/dashboard-routing.module';
import {feedbackRoutes} from '../feedback/feedback-routing.module';
import {NoActiveWorkspaceGuard} from './guards/no-active-workspace.guard';
import {administrationRoutes} from '../administration/administration-routing.module';
import {LoggedInGuard} from './guards/logged-in.guard';
import {SuperAdminGuard} from './guards/super-admin.guard';
import {WorkspacesChoicePageComponent} from '../workspaces-choice/pages/workspaces-choice/workspaces-choice-page.component';
import {noWorkspaceRoutes} from '../no-workspace/no-workspace-routing.module';
import {HasActiveWorkspaceGuard} from './guards/has-active-workspace.guard';
import {WorkspaceByCodeResolver} from "./resolvers/workspace/workspace-by-code.resolver";
import {CurrentWorkspaceGuard} from "./guards/current-workspace.guard";

const routes: Routes = [
  { path: 'login', children: loginRoutes },
  { path: '', pathMatch: 'full', canActivate: [LoggedInGuard, CurrentWorkspaceGuard], children: [] },
  {
    path: 'workspaces/:workspaceCode', canActivate: [LoggedInGuard],
    resolve: {
      workspace: WorkspaceByCodeResolver
    },
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', canActivate: [LoggedInGuard, HasActiveWorkspaceGuard], children: dashboardRoutes },
      { path: 'requests', canActivate: [LoggedInGuard, HasActiveWorkspaceGuard], children: requestRoutes },
      { path: 'settings', canActivate: [LoggedInGuard, HasActiveWorkspaceGuard], children: settingsRoutes },
      { path: 'feedbacks', canActivate: [LoggedInGuard, HasActiveWorkspaceGuard], children: feedbackRoutes }
    ]
  },
  { path: 'administration', canActivate: [LoggedInGuard, SuperAdminGuard], children: administrationRoutes },
  { path: 'noWorkspace', canActivate: [LoggedInGuard, NoActiveWorkspaceGuard], children: noWorkspaceRoutes },
  { path: 'workspacesChoice', canActivate: [LoggedInGuard, HasActiveWorkspaceGuard], component: WorkspacesChoicePageComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
