import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-read-only',
  templateUrl: './a-read-only.component.html',
  styleUrls: ['./a-read-only.component.scss']
})
export class AReadOnlyComponent {

  @Input()
  public labelKey: string;

  @Input()
  public valueKey: string;

}
