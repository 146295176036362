import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AGENTS_OR_USER_SEARCH_CRITERIA, WorkspaceAgentSearchCriteria,
} from '../../../models/criterias/WorkspaceAgentSearchCriteria';
import {WorkspaceUserSearchCriteria} from '../../../models/criterias/WorkspaceUserSearchCriteria';
import {SessionService} from '../../../../app-root/services/session.service';
import {Role} from '../../../models/entity/enums/Role';
import {Company} from '../../../models/entity/companies/Company';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';

@Component({
  selector: 'm-input-search-users',
  templateUrl: './m-input-search-users.component.html',
  styleUrls: ['./m-input-search-users.component.scss']
})
export class MInputSearchUsersComponent implements OnInit {
  @Input()
  public agentDisplay: boolean = true;

  @Input()
  public qPlaceholderKey: string;

  @Input()
  public qPlaceholderKeyI18nParams: any;

  @Input()
  public companySelectPlaceholderKey: string = '';

  @Input()
  public companySelectPlaceholderKeyI18nParams: any;

  @Output()
  public searchFired: EventEmitter<AGENTS_OR_USER_SEARCH_CRITERIA> = new EventEmitter<AGENTS_OR_USER_SEARCH_CRITERIA>();

  @Input()
  public set companies(companies: Array<Company>) {
    if (companies && companies.length > 0) {
      this._companies = companies;
    }
  }

  public get companies() {
    return this._companies;
  }

  private _companies: Array<Company>;

  public genericSearchCriteria: AGENTS_OR_USER_SEARCH_CRITERIA;
  public selectedCompany: Company;
  public currentUser: CompleteCurrentUser;

  public rolesSelected: string = 'AGENT,AGENT_ADMIN';

  constructor(private sessionService: SessionService) {
    sessionService.getCurrentUser().subscribe(curUser => this.currentUser = curUser);
  }

  ngOnInit(): void {
    if (this.agentDisplay) {
      this.genericSearchCriteria = new WorkspaceAgentSearchCriteria();
    } else {
      this.genericSearchCriteria = new WorkspaceUserSearchCriteria();
    }
  }

  public filter(): void {
    this.genericSearchCriteria.companyId = this.selectedCompany ? this.selectedCompany.id : null;
      if (this.genericSearchCriteria instanceof WorkspaceAgentSearchCriteria) {
        const roles = this.rolesSelected.split(',');
        this.genericSearchCriteria.roles = roles.map(el => Role[el]);
      }
      this.searchFired.emit(this.genericSearchCriteria);
    }
}
