import {Component, Input} from '@angular/core';
import {SizeAvatar} from '../../atoms/a-avatar/a-avatar.component';
import {WorkspaceUser} from '../../../models/entity/users/WorkspaceUser';
import {User} from '../../../models/entity/users/User';
import {Role} from '../../../models/entity/enums/Role';

@Component({
    selector: 'm-avatar-list',
    templateUrl: './m-avatar-list.component.html',
    styleUrls: ['./m-avatar-list.component.scss']
})
export class MAvatarListComponent {

  @Input()
  public sizeAvatar: SizeAvatar = 'DEFAULT';

  @Input()
  public white: boolean;

  @Input()
  public users: Array<WorkspaceUser|User> = [];

  @Input()
  public defaultRole: Role;

}
