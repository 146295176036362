import {Entity} from '../common/Entity';
import {Attachment} from '../Attachment';
import {WorkspaceSimpleWithUserState} from '../workspaces/WorkspaceWithUserState';
import {Type} from 'class-transformer';

export class UserAdministrationSearchResult extends Entity {

  public firstName: string;
  public lastName: string;
  public superAdmin: boolean;
  public email: string;
  public disabled: boolean;
  public avatar?: Attachment;
  @Type(() => WorkspaceSimpleWithUserState)
  public workspaces: Array<WorkspaceSimpleWithUserState>;

  public getWorkspacesStr(): string {
    return this.workspaces ? this.workspaces.map(workspace => workspace.getNameWithUserState()).join(', ') : '';
  }

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
