import {Component, Input} from '@angular/core';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'm-attachment-button',
  templateUrl: './m-attachment-button.component.html',
  styleUrls: ['./m-attachment-button.component.scss']
})
export class MAttachmentButtonComponent {

  @Input()
  public attachment: Attachment;

}
