import {CompanySimple} from '../companies/CompanySimple';
import {Role} from '../enums/Role';
import {Type} from 'class-transformer';
import {WorkspaceSimple} from '../workspaces/Workspace';
import {ContextualWorkspace} from '../workspaces/ContextualWorkspace';
import {Attachment} from '../Attachment';

export class CompleteCurrentUser {

  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public activeRole: Role;
  public disabled: boolean;
  public superAdmin: boolean;
  public avatar?: Attachment;
  @Type(() => CompanySimple)
  public activeCompany: CompanySimple;
  @Type(() => WorkspaceSimple)
  public activeWorkspace: WorkspaceSimple;
  @Type(() => ContextualWorkspace)
  public contextualWorkspaces: Array<ContextualWorkspace> = [];

  public getFullName(): string {
    if (this.disabled) {
      return `${this.firstName} ${this.lastName} (Désactivé)`;
    } else {
      return `${this.firstName} ${this.lastName}`;
    }
  }

  public isAgent(): boolean {
    return Role.AGENT === this.activeRole || this.isAgentAdmin();
  }

  public isUser(): boolean {
    return Role.USER === this.activeRole;
  }

  public isAgentAdmin(): boolean {
    return Role.AGENT_ADMIN === this.activeRole;
  }

  public isAgentInWorkspace(workspaceId: String): boolean {
    return Role.AGENT === this.getRoleInWorkspace(workspaceId) || this.isAgentAdminInWorkspace(workspaceId);
  }

  public isUserInWorkspace(workspaceId: String): boolean {
    return Role.USER === this.getRoleInWorkspace(workspaceId);
  }

  public isAgentAdminInWorkspace(workspaceId: String): boolean {
    return Role.AGENT_ADMIN === this.getRoleInWorkspace(workspaceId);
  }

  public getWorkspaces(): Array<WorkspaceSimple> {
    return this.contextualWorkspaces ?
      this.contextualWorkspaces.map(workspace => WorkspaceSimple.fromContextualWorkspace(workspace)) : [];
  }

  public getCompaniesInActiveWorkspace(): Array<CompanySimple> {
    if (this.contextualWorkspaces && this.activeWorkspace) {
      const workspace = this.contextualWorkspaces.find(wks => wks.id === this.activeWorkspace.id);
      return workspace && workspace.companies ? workspace.companies.map(company => company.company) : [];
    } else {
      return [];
    }
  }

  public getWorkspaceById(workspaceId: String): ContextualWorkspace {
    return this.contextualWorkspaces ? this.contextualWorkspaces.find(workspace => workspace.id === workspaceId) : null;
  }

  public getCompaniesInWorkspaces(workspaceId: String): Array<CompanySimple> {
    const workspace = this.getWorkspaceById(workspaceId);
    return workspace && workspace.companies ? workspace.companies.map(company => company.company) : [];
  }

  public getCompaniesNameInWorkspaces(workspaceId: String): Array<string> {
    const workspace = this.getWorkspaceById(workspaceId);
    return workspace && workspace.companies ? workspace.companies.map(company => company.company.name) : [];
  }

  public getRoleInWorkspace(workspaceId: String): Role {
    const workspace = this.getWorkspaceById(workspaceId);
    return workspace ? workspace.role : null;
  }

  public visibleCompanyIds(): Array<string> {
    return this.isAgent() ? this.getCompaniesInActiveWorkspace().map(it => it.id) : [this.activeCompany.id];
  }
}
