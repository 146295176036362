import {Entity} from '../common/Entity';
import {CompleteCurrentUser} from './CompleteCurrentUser';
import {CompleteUser} from './CompleteUser';
import {Attachment} from '../Attachment';

export class User extends Entity {

  public firstName: string;
  public lastName: string;
  public disabled: boolean;
  public avatar?: Attachment;
  public superAdmin: boolean;

  static from(completeUser: CompleteUser) {
    const user = new User();
    user.id = completeUser.id;
    user.firstName = completeUser.firstName;
    user.lastName = completeUser.lastName;
    user.disabled = completeUser.disabled;
    user.avatar = completeUser.avatar;
    return user;
  }

  static fromCompleteCurrentUser(completeUser: CompleteCurrentUser) {
    const user = new User();
    user.id = completeUser.id;
    user.firstName = completeUser.firstName;
    user.lastName = completeUser.lastName;
    user.disabled = completeUser.disabled;
    user.avatar = completeUser.avatar;
    return user;
  }

  public getFullName(): string {
    if (this.disabled) {
      return `${this.firstName} ${this.lastName} (Désactivé)`;
    } else {
      return `${this.firstName} ${this.lastName}`;
    }
  }
}

export class UserWithEmail extends User {
  public email: string;
}
