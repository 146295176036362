import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {RequestService} from '../../services/request.service';
import {SessionService} from '../../services/session.service';
import {CompleteRequest} from '../../../shared/models/entity/requests/CompleteRequest';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';
import {RequestWrapper} from '../../../shared/models/entity/requests/RequestWrapper';

@Injectable()
export class RequestByCodeResolver extends AbstractResolver<RequestWrapper | void> {

  constructor(spinnerService: SpinnerService,
              private requestService: RequestService,
              private sessionService: SessionService,
              private router: Router
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RequestWrapper | void> {
    const requestCode = route.paramMap.get('requestCode');
    return requestCode ?
      this.findRequest(requestCode) :
      this.sessionService.getCurrentUser()
        .pipe(switchMap((user: CompleteCurrentUser) => of(
          new RequestWrapper('complete', CompleteRequest.new(user))
        )))
        .pipe(take(1));
  }

  private findRequest(requestCode: string): Observable<RequestWrapper | void> {
    return this.requestService.findByCode(requestCode).pipe(
      take(1),
      map(req => new RequestWrapper('complete', req)),
      catchError(err => {
        if (err.status === 303) {
          if (err.error.canRedirect) {
            return this.sessionService.activeWorkspace(err.error.workspaceCode, err.error.companyCode).pipe(
              tap(() =>
                  this.router.navigate(['workspaces', err.error.workspaceCode, 'requests', err.error.requestCode])
              ),
              map(() => null)
            );
          } else {
            return of(new RequestWrapper('transferred', err.error));
          }
        }
        return throwError(err);
      })
    );
  }
}
