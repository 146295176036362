import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

const I18NAttributeWhitelist = ['placeholder', 'title', 'label', 'value', 'notFoundText'];
type I18nParam = { target: string, key: string, params?: Object };

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[i18n]'
})
export class I18nTranslateDirective implements OnInit, OnChanges {

  @Input()
  i18n: I18nParam[] | string = [];

  constructor(
    private el: ElementRef,
    private translateService: TranslateService
  ) {}

  private usage(item: I18nParam) {
    const attr = item.target.split(':');
    if ((attr[0] !== 'innerHTML' && attr[0] !== 'attribute') ||
      (attr[1] && !(I18NAttributeWhitelist.includes(attr[1])))) {
      throw Error(
        'Bad signature for the i18n directive on {' + item.target + ', ' + item.key + ', ' + JSON.stringify(item.params) + '}, ' +
        'target should be innerHTML or attribute:<your attribute> ' +
        'with attribute in [' + I18NAttributeWhitelist + ']'
      );
    }
  }

  ngOnInit() {
    if (typeof this.i18n === 'string') {
      this.updateInnerHTML(this.i18n);
    } else {
      this.i18n.forEach(item => {
        if (item.key) {
          this.usage(item);
          if (item.target !== 'innerHTML') {
            this.updateAttribute(item);
          } else {
            this.updateInnerHTML(item.key, item.params);
          }
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  private updateInnerHTML(key: string, params?: Object) {
    this.el.nativeElement.setAttribute('data-i18n-key', key);
    this.el.nativeElement.setAttribute('data-i18n-args', btoa(unescape(encodeURIComponent(JSON.stringify(params ? params : {})))));
    this.translateService.stream(key, params ? params : {})
      .subscribe(res => this.el.nativeElement.innerHTML = res);
  }

  private updateAttribute(params: I18nParam) {
    const target = params.target.split(':')[1];
    this.el.nativeElement.setAttribute('data-i18n-' + target + '-key', params.key);
    this.el.nativeElement.setAttribute('data-i18n-' + target + '-args', btoa(unescape(encodeURIComponent(JSON.stringify(params.params ? params.params : {})))));
    this.translateService.stream(params.key, params.params ? params.params : {})
      .subscribe(res => this.el.nativeElement.setAttribute(target, res));
  }
}
