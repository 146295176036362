import {Type} from 'class-transformer';
import {Domain} from '../classifications/Domain';
import {Category} from '../classifications/Category';
import {CompanySimple} from '../companies/CompanySimple';
import {User} from '../users/User';
import {SimpleRequest} from './RequestSimple';
import {
  AgentsChangedEvent,
  CategoryChangedEvent,
  DomainChangedEvent,
  EventType,
  NewMessageEvent,
  PriorityChangedEvent,
  RelatedRequestChangedEvent,
  ReminderSendedEvent,
  StatusChangedEvent,
  TagsChangedEvent,
  TransferEvent,
  UserPriorityChangedEvent,
  UsersChangedEvent
} from './Event';
import {WorkspaceSimple} from '../workspaces/Workspace';
import {CompleteCurrentUser} from '../users/CompleteCurrentUser';
import {RequestPriority} from '../enums/RequestPriority';
import {Company} from '../companies/Company';
import {RequestStatus} from '../enums/RequestStatus';
import {RequestCommon} from './RequestCommon';
import {CompleteMessage} from './CompleteMessage';

export class CompleteRequest extends RequestCommon {

  @Type(() => Date)
  public handlingDate?: Date;
  @Type(() => Date)
  public closedDate?: Date;
  @Type(() => Domain)
  public domain: Domain;
  @Type(() => Category)
  public category: Category;
  @Type(() => CompanySimple)
  public company: CompanySimple;
  @Type(() => User)
  public author: User;
  @Type(() => SimpleRequest)
  public relatedRequests: Array<SimpleRequest> = [];
  @Type(() => CompleteMessage)
  public messages: Array<CompleteMessage>;
  @Type(() => Event, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: AgentsChangedEvent, name: EventType.AGENTCHANGE },
        { value: UsersChangedEvent, name: EventType.USERCHANGE },
        { value: ReminderSendedEvent, name: EventType.REMIND },
        { value: TagsChangedEvent, name: EventType.TAGCHANGE },
        { value: RelatedRequestChangedEvent, name: EventType.RELATEDREQUESTCHANGE },
        { value: DomainChangedEvent, name: EventType.DOMAINCHANGE },
        { value: CategoryChangedEvent, name: EventType.CATEGORYCHANGE },
        { value: NewMessageEvent, name: EventType.MESSAGE },
        { value: PriorityChangedEvent, name: EventType.PRIORITYCHANGE },
        { value: UserPriorityChangedEvent, name: EventType.USERPRIORITYCHANGE },
        { value: StatusChangedEvent, name: EventType.STATUSCHANGE },
        { value: TransferEvent, name: EventType.TRANSFER },
      ],
    },
  })
  public events: Array<Event>;
  public workspace: WorkspaceSimple;


  static new(user: CompleteCurrentUser): CompleteRequest {
    const currentUser = User.fromCompleteCurrentUser(user);

    const completeRequest = new CompleteRequest();
    completeRequest.author = currentUser;
    completeRequest.tags = [];
    completeRequest.relatedRequests = [];
    completeRequest.userPriority = RequestPriority.NORMAL;
    completeRequest.messages = [];

    if (user.isUser()) {
      completeRequest.company = Company.from(user.activeCompany, user.activeWorkspace);
      completeRequest.status = RequestStatus.CREATED;
      completeRequest.users = [currentUser];
      completeRequest.agents = [];
    } else if (user.isAgent()) {
      completeRequest.status = RequestStatus.WAITING_FOR_CUSTOMER;
      completeRequest.users = [];
      completeRequest.agents = [currentUser];
      completeRequest.priority = RequestPriority.NOT_DEFINE;
    }

    return completeRequest;
  }
}
