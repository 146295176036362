import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CompanyService} from '../../services/company.service';
import {PaginatedCompanies} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedCompanies';
import {CompanySearchCriteria} from '../../../shared/models/criterias/CompanySearchCriteria';
import {SessionService} from '../../services/session.service';
import {switchMap, take} from 'rxjs/operators';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceCompaniesPaginateResolver extends AbstractResolver<PaginatedCompanies> {

  private initialPage: number = 0;
  private step: number = 10;

  constructor(spinnerService: SpinnerService,
              private companyService: CompanyService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedCompanies> {
    return this.sessionService.getCurrentUser().pipe(
      switchMap(currentUser => {
        return currentUser.isAgentAdmin()
          ? this.companyService.findWithAgentsAttachments(new CompanySearchCriteria(this.initialPage, this.step))
          : of(PaginatedCompanies.emptyResult());
        }
        )
    ).pipe(
      take(1)
    );
  }
}
