import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'm-editable-text',
  templateUrl: './m-editable-text.component.html',
  styleUrls: ['./m-editable-text.component.scss']
})
export class MEditableTextComponent {

  @Input()
  public text: string;

  @Output()
  public textChanged = new EventEmitter<string>();

  public editLabel = false;

  public editScore(): void {
    this.editLabel = true;
  }

  public closeEditionPopup(): void {
    this.editLabel = false;
    this.textChanged.emit(this.text);
  }
}
