export enum RequestPriority {
  NOT_DEFINE = 'NOT_DEFINE',
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
  EXPEDITE = 'EXPEDITE'
}

interface RequestPriorityMetaData {
  criticality: number;
}

export function requestPriorities(): Array<RequestPriority> {
  return [
    RequestPriority.NOT_DEFINE,
    RequestPriority.LOW,
    RequestPriority.NORMAL,
    RequestPriority.HIGH,
    RequestPriority.EXPEDITE
  ];
}

export function getRequestPriorityMetaData(requestPriority: RequestPriority | string): RequestPriorityMetaData {
  switch (requestPriority) {
    case RequestPriority.NOT_DEFINE:
      return {criticality: 0};
    case RequestPriority.LOW:
      return {criticality: 1};
    case RequestPriority.NORMAL:
      return {criticality: 2};
    case RequestPriority.HIGH:
      return {criticality: 3};
    case RequestPriority.EXPEDITE:
      return {criticality: 4};
  }
}
