import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {App} from '../../shared/models/App';

@Injectable()
export class AppService {

  private baseUrl = '/api/app';

  constructor(private http: HttpClient) {}

  public getAppData(): Observable<App> {
    return this.http.get<App>(this.baseUrl).pipe(map((principal: any) => plainToClass(App, principal as Object)));
  }
}
