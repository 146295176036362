import {Component} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordService} from '../../../app-root/services/password.service';
import {NotificationService} from '../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {WorkspaceService} from '../../../app-root/services/workspace.service';
import {Attachment} from '../../../shared/models/entity/Attachment';

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {

  public credentials: Credentials = {
    login: '',
    password: ''
  };

  public newPasswordRequestEmail: string;
  public authenticationError = false;
  public forgotPasswordMode = false;
  public connexionSpinnerName: string = 'connexion';
  public lostPasswordSpinnerName: string = 'passwordLost';
  public loginText: string;
  public loginLogos: Array<Attachment>;

  constructor(private sessionService: SessionService,
              private passwordService: PasswordService,
              private spinner: NgxSpinnerService,
              private notificationService: NotificationService,
              private workspaceService: WorkspaceService,
              private router: Router, route: ActivatedRoute
  ) {
    route.queryParams.subscribe(params => {
      if (params['workspaceCode']) {
        workspaceService.getLoginCustomization(params['workspaceCode']).subscribe(loginCustom => {
          this.loginText = loginCustom.text;
          this.loginLogos = loginCustom.logos;
        });
      }
    });

    route.queryParams.subscribe(params => {
      if (params && params.token) {
        this.spinner.show(this.connexionSpinnerName);
        // Ugly fix to retrieve encoded value (+ are transform into space on params.token)
        this.sessionService.authenticateFromToken(this.router.url.split('token=').pop()).subscribe(
          () => {
            this.router.navigate(['/dashboard']);
            this.spinner.hide(this.connexionSpinnerName);
          },
          () => {
            this.authenticationError = true;
            this.spinner.hide(this.connexionSpinnerName);
          });
      }
    });

    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      if (navigation.extras.state.logout) {
        this.notificationService.success('login.logout.success');
      } else if (navigation.extras.state.unauthorized) {
        this.sessionService.clearSession();
        this.notificationService.warning('login.logout.unauthorized');
      }
    }
  }

  public toggleForgotPassword(): void {
    this.forgotPasswordMode = !this.forgotPasswordMode;
  }

  public authenticate(credentials: Credentials): void {
    this.sessionService.authenticate(credentials.login, credentials.password).subscribe(
      () => this.router.navigate(['/']),
      () => this.authenticationError = true
    );
  }

  public renewUserPassword(): void {
    this.spinner.show(this.lostPasswordSpinnerName);
    this.passwordService.renewUserPassword(this.newPasswordRequestEmail).subscribe(
      () => {
        this.spinner.hide(this.lostPasswordSpinnerName);
        this.router.navigate(['/login/emailSent']);
      },
      () => {
        this.spinner.hide(this.lostPasswordSpinnerName);
        this.router.navigate(['/login/emailSent']);
      });
  }
}

export interface Credentials {
  login: string;
  password: string;
}
