import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClassificationService} from '../../../../../app-root/services/classification.service';
import {Category} from '../../../../models/entity/classifications/Category';

@Component({
  selector: 'a-select-categories',
  templateUrl: './a-select-categories.component.html',
  styleUrls: ['./a-select-categories.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCategoriesComponent),
      multi: true
    }
  ]
})
export class ASelectCategoriesComponent implements ControlValueAccessor {

  private _domainIds: Array<string>;
  public categoriesWithDomain: Array<CategoryWithDomain>;
  public selectedCategoriesWithDomain: Array<CategoryWithDomain>;
  private writedValue: Array<string>;

  @Input()
  get domainsIds(): Array<string> {
    return this._domainIds;
  }

  set domainsIds(domainIds: Array<string>) {
    this._domainIds = domainIds;
    this.categoriesWithDomain = [];
    if (domainIds) {
      this.classificationService.findDomainByIds(domainIds).subscribe(domains => {
        const categoriesWithDomain = [];
        domains.forEach(domain => {
          if (domain.categories) {
            categoriesWithDomain.push(...domain.categories.map(category => {
              return {
                domain: {
                  id: domain.id,
                  title: domain.title,
                  iconClass: domain.iconClass
                },
                category: category
              } as CategoryWithDomain;
            }));
          }
        });
        // Use to force NG detect changes
        this.categoriesWithDomain = categoriesWithDomain;
        if (this.selectedCategoriesWithDomain) {
          this.onChange(this.selectedCategoriesWithDomain.filter(catWithDomain => domainIds.includes(catWithDomain.domain.id)));
        } else if (this.writedValue) {
          this.writeValue(this.writedValue);
        }
      });
    }
  }

  constructor(private classificationService: ClassificationService) { }

  private onNgChange: (values: Array<string>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'categories';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public required: boolean;

  groupByDomainIdFn = (item: CategoryWithDomain) => item.domain.id;
  groupByDomainValueFn = (_: string, children: any[]) => ({
    id: children[0].domain.id,
    title: children[0].domain.title,
    iconClass: children[0].domain.iconClass
  })

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(categoryIds: Array<string>): void {
    this.writedValue = categoryIds;
    this.selectedCategoriesWithDomain = categoryIds && this.categoriesWithDomain && this.categoriesWithDomain.length > 0 ?
      categoryIds.map(id => this.categoriesWithDomain.find(c => c.category.id === id)) : undefined;
  }

  public onChange(categoriesWithDomain: Array<CategoryWithDomain>): void {
    this.selectedCategoriesWithDomain = categoriesWithDomain;
    this.onNgChange(categoriesWithDomain ? categoriesWithDomain.map(catWithDomain => catWithDomain.category.id) : undefined);
  }
}

export interface CategoryWithDomain {
  domain: SimpleDomain;
  category: Category;
}

export interface SimpleDomain {
  id: string;
  title: string;
  iconClass: string;
}
