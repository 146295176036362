import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {SessionService} from '../services/session.service';

@Injectable()
export class AgentGuard implements CanActivate {

  constructor(private sessionService: SessionService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.sessionService.getCurrentUser().pipe(map(user => user !== null && user.isAgent()));
  }
}
