import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {
  PaginatedWorkspaceUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUsers';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {take} from 'rxjs/operators';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceAgentsPaginateResolver extends AbstractResolver<PaginatedWorkspaceUsers> {

  private initialPage: number = 0;
  private step: number = 10;

  constructor(spinnerService: SpinnerService, private workspaceUserService: WorkspaceUserService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedWorkspaceUsers> {
    return this.workspaceUserService
      .findAgents(new WorkspaceUserSearchCriteria(this.initialPage, this.step))
      .pipe(
        take(1)
      );
  }
}
