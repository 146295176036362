import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AButtonCancelComponent} from './a-button-cancel/a-button-cancel.component';
import {AButtonDefaultComponent} from './a-button-default/a-button-default.component';
import {AButtonSubmitComponent} from './a-button-submit/a-button-submit.component';
import {AButtonSearchComponent} from './a-button-search/a-button-search.component';
import {AButtonRoundComponent} from './a-button-round/a-button-round.component';
import {AButtonTextComponent} from './a-button-text/a-button-text.component';
import {AButtonAddComponent} from './a-button-add/a-button-add.component';
import {AButtonDeleteComponent} from './a-button-delete/a-button-delete.component';
import {AButtonNextComponent} from './a-button-next/a-button-next.component';
import {AButtonBackComponent} from './a-button-back/a-button-back.component';
import {AButtonBoltComponent} from './a-button-bolt/a-button-bolt.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {DirectivesModule} from '../../../directives/directives.module';

const components = [
  AButtonCancelComponent,
  AButtonDefaultComponent,
  AButtonSubmitComponent,
  AButtonSearchComponent,
  AButtonRoundComponent,
  AButtonTextComponent,
  AButtonAddComponent,
  AButtonDeleteComponent,
  AButtonNextComponent,
  AButtonBoltComponent,
  AButtonBackComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatTooltipModule,
        TranslateModule,
        DirectivesModule
    ],
  declarations: [
    components,
  ],
  exports: components
})
export class ButtonsModule {
}
