import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {FeedbackService} from '../../services/feedback.service';
import {CompleteFeedback} from '../../../shared/models/entity/feedbacks/CompleteFeedback';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class FeedbackResolver extends AbstractResolver<CompleteFeedback> {

  constructor(spinnerService: SpinnerService, private feedbackService: FeedbackService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompleteFeedback> {
    const feedbackId = route.paramMap.get('feedbackId');
    return this.feedbackService.findById(feedbackId).pipe(take(1));
  }
}
