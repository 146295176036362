import {Type} from 'class-transformer';
import {Company} from './Company';
import {WorkspaceUser} from '../users/WorkspaceUser';

export class CompanyWithAgents extends Company {

  @Type(() => WorkspaceUser)
  public autoAffectedAgents: Array<WorkspaceUser>;
  @Type(() => WorkspaceUser)
  public agents: Array<WorkspaceUser>;

  static newCompany(): CompanyWithAgents {
    const companyWithAgents = new CompanyWithAgents();
    companyWithAgents.autoAffectedAgents = [];
    companyWithAgents.agents = [];
    companyWithAgents.subscribedDomains = [];
    return companyWithAgents;
  }
}
