import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgForm} from '@angular/forms';
import {CompanyService} from '../../../../app-root/services/company.service';
import {AInputCompanyCodeValidatorComponent} from '../../atoms/forms/a-input-company-code-validator/a-input-company-code-validator.component';
import {UtilsService} from '../../../../app-root/services/utils.service';
import {SessionService} from '../../../../app-root/services/session.service';
import {CompanyWithAgents} from '../../../models/entity/companies/CompanyWithAgents';
import {WorkspaceUser} from '../../../models/entity/users/WorkspaceUser';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';

@Component({
  selector: 'm-company-agents-form',
  templateUrl: './m-company-agents-form.component.html',
  styleUrls: ['./m-company-agents-form.component.scss']
})
export class MCompanyAgentsFormComponent {

  @ViewChild('codeInputComponent')
  private codeInputComponent: AInputCompanyCodeValidatorComponent;

  @Input()
  public title: string = '';

  @Input()
  public agents: Array<WorkspaceUser>;

  @Output()
  public companyWithAgentsSaved = new EventEmitter<CompanyWithAgents>();

  @ViewChild('form')
  public form: NgForm;

  private _companyWithAgents: CompanyWithAgents;
  public initialCode: string;

  public agentsAutoAffectedSorted: Array<WorkspaceUser>;

  public currentUser: CompleteCurrentUser;

  @Input()
  get companyWithAgents(): CompanyWithAgents {
    return this._companyWithAgents;
  }

  set companyWithAgents(companyWithAgents: CompanyWithAgents) {
    this._companyWithAgents = companyWithAgents;
    if (companyWithAgents) {
      this.agentsAutoAffectedSorted = this.companyWithAgents.agents
        .sort((a, b) => this.utilsService.compareStrings(a.firstName, b.firstName, true));
      this.initialCode = companyWithAgents.code;
    }
  }

  constructor(private companyService: CompanyService,
              private sessionService: SessionService,
              private notificationService: NotificationService,
              private utilsService: UtilsService) {
    sessionService.getCurrentUser().subscribe(currentUser => this.currentUser = currentUser);
  }

  public update(): void {
    this.companyService.save(this.companyWithAgents).subscribe(companyWithAgents => {
      this.notificationService.success(this.companyWithAgents.id ? 'notification.company.updated' : 'notification.company.saved');
      this.form.form.markAsPristine();
      this.companyWithAgentsSaved.emit(companyWithAgents);
    }, error => this.notificationService.error(this.companyWithAgents.id ?
      'notification.company.updated.error' : 'notification.company.saved.error', {error: error.error || error.statusText}));
  }

  public onAgentsChange(agents: Array<WorkspaceUser>): void {
    this.companyWithAgents.agents = agents;
    this.companyWithAgents.autoAffectedAgents =
      this.companyWithAgents.autoAffectedAgents.filter(agent => agents.map(it => it.userId).includes(agent.userId));
    this.agentsAutoAffectedSorted = agents
      .sort((a, b) => this.utilsService.compareStrings(a.firstName, b.firstName, true));
  }

  public codeIsValid(): boolean {
    return this.codeInputComponent && this.codeInputComponent.inputNgModel.errors == null;
  }
}
