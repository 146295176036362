import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {map, tap} from 'rxjs/operators';
import {SessionService} from '../services/session.service';

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.sessionService.getCurrentUser().pipe(
      tap(user => {
        if (user === null) {
          this.router.navigate(['login']);
        }
      }),
      map(user => user !== null)
    );
  }
}
