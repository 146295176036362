import {Routes} from '@angular/router';
import {SettingsPageComponent} from './pages/settings/settings-page.component';
import {LoggedInGuard} from '../app-root/guards/logged-in.guard';
import {AgentGuard} from '../app-root/guards/agent.guard';
import {AgentAdminGuard} from '../app-root/guards/agent-admin.guard';
import {AgentDetailsPageComponent} from './pages/agent-details/agent-details-page.component';
import {WorkspaceAgentResolver} from '../app-root/resolvers/agents/workspace-agent.resolver';
import {CurrentUserProfilePageComponent} from './pages/current-user-profile/current-user-profile-page.component';
import {WorkspaceUserResolver} from '../app-root/resolvers/user/workspace-user.resolver';
import {CompanyDetailsPageComponent} from './pages/company-details/company-details-page.component';
import {CompanyWithAgentsResolver} from '../app-root/resolvers/companies/company-with-agents.resolver';
import {NewsDetailsPageComponent} from './pages/news-details/news-details-page.component';
import {NewsResolver} from '../app-root/resolvers/news/news.resolver';
import {DomainDetailsPageComponent} from './pages/domain-details/domain-details-page.component';
import {DomainResolver} from '../app-root/resolvers/domain/domain.resolver';
import {WorkspaceNewsResolver} from '../app-root/resolvers/news/workspace-news.resolver';
import {WorkspaceCompaniesPaginateResolver} from '../app-root/resolvers/companies/workspace-companies-paginate.resolver';
import {ResponseTemplateDetailsPageComponent} from './pages/response-template-details/response-template-details-page.component';
import {ResponseTemplateResolver} from '../app-root/resolvers/responseTemplates/response-template.resolver';
import {WorkspaceAgentsPaginateResolver} from '../app-root/resolvers/agents/workspace-agents-paginate.resolver';
import {WorkspaceUsersPaginateResolver} from '../app-root/resolvers/user/workspace-users-paginate.resolver';
import {UserDetailsPageComponent} from './pages/user-details/user-details-page.component';
import {WorkspaceResponseTemplatesPaginateResolver} from '../app-root/resolvers/responseTemplates/workspace-response-templates-paginate.resolver';
import {WorkspaceCompaniesResolver} from '../app-root/resolvers/companies/workspace-companies.resolver';
import {WorkspaceDomainsResolver} from '../app-root/resolvers/domain/workspace-domains.resolver';
import {WorkspaceCurrentUserResolver} from '../app-root/resolvers/user/workspace-current-user.resolver';
import {WorkspaceAgentsResolver} from '../app-root/resolvers/agents/workspace-agents.resolver';
import {UserAccountTabComponent} from "./pages/tabs/user-account/user-account-tab.component";
import {AgentsTabComponent} from "./pages/tabs/agents/agents-tab.component";
import {UsersTabComponent} from "./pages/tabs/users/users-tab.component";
import {CompaniesTabComponent} from "./pages/tabs/companies/companies-tab.component";
import {NewsTabComponent} from "./pages/tabs/news/news-tab.component";
import {TemplatesTabComponent} from "./pages/tabs/templates/templates-tab.component";
import {CustomizationTabComponent} from "./pages/tabs/customization/customization-tab.component";

export const settingsRoutes: Routes = [
  {
    path: '', component: SettingsPageComponent, canActivate: [LoggedInGuard, AgentGuard],
    children: [
      {path: '', redirectTo: 'information', pathMatch: 'full'},
      {
        path: 'information', component: UserAccountTabComponent, canActivate: [LoggedInGuard],
        resolve: {
          currentWorkspaceUser: WorkspaceCurrentUserResolver,
          allCompanies: WorkspaceCompaniesResolver
        }
      },
      {
        path: 'agents', component: AgentsTabComponent, canActivate: [LoggedInGuard],
        resolve: {
          initialAgents: WorkspaceAgentsPaginateResolver,
          allCompanies: WorkspaceCompaniesResolver
        }
      },
      {
        path: 'users', component: UsersTabComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
        resolve: {
          initialUsers: WorkspaceUsersPaginateResolver,
          allCompanies: WorkspaceCompaniesResolver
        }
      },
      {
        path: 'companies', component: CompaniesTabComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
        resolve: {
          initialCompanies: WorkspaceCompaniesPaginateResolver
        }
      },
      {
        path: 'news', component: NewsTabComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
        resolve: {
          initialNews: WorkspaceNewsResolver
        }
      },
      {
        path: 'templates', component: TemplatesTabComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
        resolve: {
          initialResponseTemplates: WorkspaceResponseTemplatesPaginateResolver
        }
      },
      {
        path: 'customization', component: CustomizationTabComponent, canActivate: [LoggedInGuard],
        resolve: {
          domains: WorkspaceDomainsResolver
        }
      }
    ]
  },
  {
    path: 'agents/new', component: AgentDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      agent: WorkspaceAgentResolver,
      allCompanies: WorkspaceCompaniesResolver
    },
  },
  {
    path: 'agents/:agentId', component: AgentDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      agent: WorkspaceAgentResolver,
      allCompanies: WorkspaceCompaniesResolver
    }
  },
  {
    path: 'me', component: CurrentUserProfilePageComponent, canActivate: [LoggedInGuard]
  },
  {
    path: 'users/new', component: UserDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      user: WorkspaceUserResolver,
      allCompanies: WorkspaceCompaniesResolver
    },
  },
  {
    path: 'users/:userId', component: UserDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      user: WorkspaceUserResolver,
      allCompanies: WorkspaceCompaniesResolver
    }
  },
  {
    path: 'companies/new', component: CompanyDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      companyWithAgents: CompanyWithAgentsResolver,
      allAgents: WorkspaceAgentsResolver
    }
  },
  {
    path: 'companies/:companyId', component: CompanyDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      companyWithAgents: CompanyWithAgentsResolver,
      allAgents: WorkspaceAgentsResolver
    }
  },
  {
    path: 'news/new', component: NewsDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      news: NewsResolver
    }
  },
  {
    path: 'news/:newsId', component: NewsDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      news: NewsResolver
    }
  },
  {
    path: 'domains/new', component: DomainDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      domain: DomainResolver
    },
  },
  {
    path: 'domains/:domainId', component: DomainDetailsPageComponent, canActivate: [LoggedInGuard, AgentAdminGuard],
    resolve: {
      domain: DomainResolver
    }
  },
  {
    path: 'responseTemplate/new', canActivate: [LoggedInGuard, AgentAdminGuard], component: ResponseTemplateDetailsPageComponent,
    resolve: {
      responseTemplate: ResponseTemplateResolver
    }
  },
  { path: 'responseTemplate/:responseTemplateId', canActivate: [LoggedInGuard, AgentAdminGuard], component: ResponseTemplateDetailsPageComponent,
    resolve: {
      responseTemplate: ResponseTemplateResolver
    }
  },
];
