export class FeedbackSearchResult {

  public id: string;
  public topic: string;
  public senderEmail: string;
  public mainRecipientEmail: string;
  public recipientsEmails: Array<string>;
  public createdDate: Date;
  public canDelete: boolean;
}
