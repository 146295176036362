import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {
  PaginatedWorkspaceUsers
} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUsers';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {WorkspaceUserService} from '../../services/workspace-user.service';
import {WorkspaceUserSearchCriteria} from '../../../shared/models/criterias/WorkspaceUserSearchCriteria';
import {take} from 'rxjs/operators';
import {AbstractResolver} from '../abstract-resolver';
import {SortOrder} from '../../../shared/models/entity/paginated/PaginatedCriteria';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceAgentsResolver extends AbstractResolver<PaginatedWorkspaceUsers> {

  constructor(spinnerService: SpinnerService, private workspaceUserService: WorkspaceUserService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaginatedWorkspaceUsers> {
    const criteria = new WorkspaceUserSearchCriteria(null, null);
    criteria.sort = 'BY_LASTNAME';
    criteria.sortOrder = SortOrder.ASC;
    return this.workspaceUserService
      .findAgents(criteria)
      .pipe(
        take(1)
      );
  }
}
