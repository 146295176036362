import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {Directive, Injectable, Input} from '@angular/core';

@Directive({
  selector: '[emailValid]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailValidValidatorDirective, multi: true}]
})
@Injectable()
export class EmailValidValidatorDirective implements Validator {

  @Input('emailValid') email = '';

  validate(control: AbstractControl) {
    if (!control.value || !control.value.match(new RegExp(/^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/))) {
      const error = {};
      error['emailIsNotValid'] = true;
      return error;
    }
    return true;
  }
}
