import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkspaceUserWithCompanies} from '../../../shared/models/entity/users/WorkspaceUserWithCompanies';
import {Company} from '../../../shared/models/entity/companies/Company';
import {WorkspaceSimple} from "../../../shared/models/entity/workspaces/Workspace";

@Component({
  templateUrl: './user-details-page.component.html',
  styleUrls: ['./user-details-page.component.scss']
})
export class UserDetailsPageComponent {

  public user: WorkspaceUserWithCompanies;
  public allCompanies: Array<Company>;

  private workspaceCode: string;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.data.subscribe((data: { user: WorkspaceUserWithCompanies, allCompanies: Array<Company> }) => {
      if (data.user) { this.user = data.user; }
      if (data.allCompanies) { this.allCompanies = data.allCompanies; }
    });
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  onUserSaved() {
    this.goToSettingsPage();
  }

  goToSettingsPage() {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'users']);
  }
}
