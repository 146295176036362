import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-title-view',
  templateUrl: './a-title-view.component.html',
  styleUrls: ['./a-title-view.component.scss']
})
export class ATitleViewComponent {

  @Input()
  public titleKey: string;

  @Input()
  public titleKeyI18nParams: any;

  @Input()
  public color: string;

  @Input()
  public noWrapTxt: boolean;

}
