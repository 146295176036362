import {Component} from '@angular/core';
import {WorkspaceUserWithCompanies} from "../../../../shared/models/entity/users/WorkspaceUserWithCompanies";
import {Company} from "../../../../shared/models/entity/companies/Company";
import {ActivatedRoute} from "@angular/router";
import {SessionService} from "../../../../app-root/services/session.service";

@Component({
  templateUrl: './user-account-tab.component.html',
  styleUrls: ['./user-account-tab.component.scss']
})
export class UserAccountTabComponent {

  public currentWorkspaceUser: WorkspaceUserWithCompanies;
  public allCompanies: Array<Company>;

  constructor(
    private sessionService: SessionService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.currentWorkspaceUser = data['currentWorkspaceUser'];
      this.allCompanies = data['allCompanies'];
    });
  }

  public onCurrentUserChange(): void {
    this.sessionService.fetchCurrentUser().subscribe();
  }

}
