import {Routes} from '@angular/router';
import {RequestByCodeResolver} from '../app-root/resolvers/requests/request-by-code.resolver';
import {LoggedInGuard} from '../app-root/guards/logged-in.guard';
import {RequestsListPageComponent} from './pages/requests-list/requests-list-page.component';
import {RequestDetailsPageComponent} from './pages/request-details/request-details-page.component';

export const requestRoutes: Routes = [
  {
    path: '', component: RequestsListPageComponent, canActivate: [LoggedInGuard]
  },
  {
    path: 'new', component: RequestDetailsPageComponent, canActivate: [LoggedInGuard],
    resolve: {
      request: RequestByCodeResolver
    },
  },
  {
    path: ':requestCode', component: RequestDetailsPageComponent, canActivate: [LoggedInGuard],
    resolve: {
      request: RequestByCodeResolver
    },
  }
];
