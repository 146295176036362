import {Component, Input, OnInit} from '@angular/core';
import {PaginatedResponseTemplates} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedResponseTemplates';
import {ResponseTemplateService} from '../../../app-root/services/responseTemplate.service';
import {ResponseTemplateSearchCriteria} from '../../../shared/models/criterias/ResponseTemplateSearchCriteria';
import {ResponseTemplate} from '../../../shared/models/entity/ResponseTemplate';

@Component({
  selector: 'response-templates-list',
  templateUrl: './response-templates-list.component.html',
  styleUrls: ['./response-templates-list.component.scss']
})
export class ResponseTemplatesListComponent implements OnInit {

  @Input()
  public paginatedResponseTemplates: PaginatedResponseTemplates;

  public responseTemplates: Array<ResponseTemplate>;
  public step = 10;
  private defaultStartingPage: 0;
  public currentPage;

  public effectiveSearchCriteria: ResponseTemplateSearchCriteria;
  public pagesCount;

  constructor(private responseTemplateService: ResponseTemplateService) {
    this.effectiveSearchCriteria = ResponseTemplateSearchCriteria.empty();
    this.effectiveSearchCriteria.step = this.step;
    this.currentPage = this.defaultStartingPage;
  }

  public ngOnInit(): void {
    if (this.paginatedResponseTemplates) {
      this.responseTemplates = this.paginatedResponseTemplates.result;
      this.pagesCount = Math.ceil(this.paginatedResponseTemplates.metadata.count / this.step);
    }
  }

  public reloadTemplateResponses(): void {
    const resTempSearchCriteria = ResponseTemplateSearchCriteria.empty();
    resTempSearchCriteria.step = this.step;
    resTempSearchCriteria.page = this.defaultStartingPage;
    this.responseTemplateService.find(resTempSearchCriteria).subscribe( res => {
      this.paginatedResponseTemplates = res;
      this.responseTemplates = res.result;
      this.pagesCount = Math.ceil(res.metadata.count / res.metadata.step);
      this.currentPage = this.defaultStartingPage;
    });
  }

  private loadTemplateResponse(searchCriteria, resetPagination: boolean = false): void {
    if (resetPagination) {
      searchCriteria.page = this.defaultStartingPage;
      searchCriteria.step = this.step;
    }
    this.responseTemplateService.find(searchCriteria).subscribe( res => {
      this.paginatedResponseTemplates = res;
      this.responseTemplates = res.result;
      this.pagesCount = Math.ceil(res.metadata.count / res.metadata.step);
      this.currentPage = searchCriteria.page;
    });
  }

  public onPageChanged(searchCriteria): void {
    this.loadTemplateResponse(searchCriteria);
  }

  public filter(searchCriteria: ResponseTemplateSearchCriteria): void {
    this.loadTemplateResponse(searchCriteria);
  }
}
