import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MTabsComponent } from '../../molecules/m-tabs/m-tabs.component';

@Component({
    selector: 'a-tab',
    templateUrl: './a-tab.component.html',
    styleUrls: ['./a-tab.component.scss']
})
export class ATabComponent implements OnInit, OnDestroy {

  @Input()
  public tabText: string;

  @Input()
  public tabId: string;

  @Input()
  public disabled: boolean;

  public selected: boolean;

  constructor(private tabsComponent: MTabsComponent) { }

  ngOnInit() {
    this.tabsComponent.addTab(this);
  }

  ngOnDestroy(): void {
    this.tabsComponent.removeTab(this);
  }
}
