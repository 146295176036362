import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PasswordService} from '../../../app-root/services/password.service';
import {NotificationService} from '../../../app-root/services/notification.service';

@Component({
  templateUrl: './password-page.component.html',
  styleUrls: ['./password-page.component.scss']
})
export class PasswordPageComponent {

  public passwordConfirmation: string;
  public passwordUpdate: PasswordUpdate = {
    login: undefined,
    token: undefined,
    password: undefined
  };

  constructor(private passwordService: PasswordService,
              private route: ActivatedRoute,
              private router: Router,
              private notificationService: NotificationService
  ) {
    route.queryParams.subscribe(params => {
      this.passwordUpdate = {
        login: params.login,
        token: params.token,
        password: undefined
      };
    });
  }

  public save(passwordUpdate: PasswordUpdate): void {
    this.passwordService.registerPassword(passwordUpdate).subscribe(() => {
      this.notificationService.success('password.save.success');
      this.router.navigate(['../login']);
    }, () => this.notificationService.error('password.save.error'));
  }
}

export interface PasswordUpdate {
  login: string;
  token: string;
  password: string;
}
