import {Component, Input} from '@angular/core';
import {NewsPriority} from '../../../models/entity/enums/NewsPriority';
import {News} from '../../../models/entity/News';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'm-news',
  templateUrl: './m-news.component.html',
  styleUrls: ['./m-news.component.scss']
})
export class MNewsComponent {

  public HIGH_PRIORITY: NewsPriority = NewsPriority.HIGH;

  private _news: News;
  public visibleAttachments: Array<Attachment> = [];
  public hiddenAttachments: Array<Attachment> = [];

  @Input()
  get news(): News {
    return this._news;
  }

  set news(news: News) {
    this._news = news;
    if (news) {
      if (news.attachments && news.attachments.length > 3) {
        this.visibleAttachments = news.attachments.slice(0, 3);
        this.hiddenAttachments = news.attachments.slice(3, news.attachments.length);
      } else {
        this.visibleAttachments = news.attachments;
        this.hiddenAttachments = [];
      }
    }
  }

}
