import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';
import {Domain} from '../entity/classifications/Domain';
import {HttpParams} from '@angular/common/http';
import {Category} from '../entity/classifications/Category';
import {CompanySimple} from '../entity/companies/CompanySimple';

export class ResponseTemplateSearchCriteria extends PaginatedCriteria {

  constructor(public page: number,
              public step: number,
              public q: string,
              public company: CompanySimple,
              public category: Category,
              public domain: Domain,
              public name: string) {
    super(page, step);
  }

  static empty(): ResponseTemplateSearchCriteria {
    return new ResponseTemplateSearchCriteria(null, null, null, null, null, null, null);
  }

  static fromCompany(company: CompanySimple) {
    return new ResponseTemplateSearchCriteria(null, null, null, company, null, null, null);
  }

  static from(company: CompanySimple, domain: Domain) {
      return new ResponseTemplateSearchCriteria(null, null, null, company, null, domain, null);
    }

  public toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.company) {
      params = params.set('companyId', this.company.id);
    }
    if (this.q) {
      params = params.set('q', this.q);
    }
    if (this.domain) {
      params = params.set('domainId', this.domain.id);
    }
    if (this.name) {
      params = params.set('name', this.name);
    }
    if (this.category) {
      params = params.set('categoryId', this.category.id);
    }

    return params;
  }
}
