import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {PasswordUpdate} from '../../login/pages/password/password-page.component';

export interface LostPasswordTrigger {
  email: string;
}

@Injectable()
export class PasswordService {

  private baseUrl = 'api/public/users/passwords';

  constructor(private http: HttpClient) {}

  public registerPassword(passwordUpdate: PasswordUpdate): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${passwordUpdate.token}`, {
      login: passwordUpdate.login,
      password: passwordUpdate.password // TODO : Md5.hashStr(passwordUpdate.password) as string but doesn't work on server side
    });
  }

  public renewUserPassword(email: string): Observable<string> {
    return this.http.post<string>(`${this.baseUrl}/`, {email} as LostPasswordTrigger);
  }
}
