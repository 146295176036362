import {Component, Input} from '@angular/core';
import {DisplayMode, Size} from '../../atoms/a-attachment-image/a-attachment-image.component';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'm-attachment-list-image',
  templateUrl: './m-attachment-list-image.component.html',
  styleUrls: ['./m-attachment-list-image.component.scss']
})
export class MAttachmentListImageComponent {

  @Input()
  public attachments: Array<Attachment>;

  @Input()
  public sizeAvatar: Size;

  @Input()
  public usePublic: boolean = false;

  @Input()
  public displayMode: DisplayMode;
}
