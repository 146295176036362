import {NgModule} from '@angular/core';
import {NoWorkspacePageComponent} from './pages/no-workspace-page.component';
import {SharedModule} from '../shared/shared.module';
import {LinksModule} from '../shared/lib-components/atoms/links/links.module';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonsModule} from '../shared/lib-components/atoms/buttons/buttons.module';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {RouterModule} from '@angular/router';
import {DirectivesModule} from '../shared/directives/directives.module';

@NgModule({
  declarations: [NoWorkspacePageComponent],
    imports: [
        SharedModule,
        LinksModule,
        TranslateModule,
        ButtonsModule,
        BaseComponentsModule,
        RouterModule,
        DirectivesModule
    ],
  providers: []
})
export class NoWorkspaceModule {
}
