import {Component, Input} from '@angular/core';
import {SizeAvatar} from '../../atoms/a-avatar/a-avatar.component';
import {Domain} from '../../../models/entity/classifications/Domain';

@Component({
    selector: 'm-domain-icon-list',
    templateUrl: './m-domain-icon-list.component.html',
    styleUrls: ['./m-domain-icon-list.component.scss']
})
export class MDomainIconListComponent {

  @Input()
  public sizeAvatar: SizeAvatar = 'DEFAULT';

  @Input()
  public white: boolean;

  @Input()
  public domains: Array<Domain> = [];

}
