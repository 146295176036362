import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {RequestService} from '../../services/request.service';
import {SessionService} from '../../services/session.service';
import {AbstractResolver} from '../abstract-resolver';
import {WorkspaceSimple} from '../../../shared/models/entity/workspaces/Workspace';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceByCodeResolver extends AbstractResolver<WorkspaceSimple> {

  constructor(spinnerService: SpinnerService,
              private requestService: RequestService,
              private sessionService: SessionService
  ) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkspaceSimple> {
    const workspaceCode = route.paramMap.get('workspaceCode');
    return workspaceCode ?
      this.sessionService.activeWorkspace(workspaceCode).pipe(
        map(user => user.activeWorkspace)
      ) :
      of(null);
  }
}
