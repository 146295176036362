import {Component, Input} from '@angular/core';
import {ATabComponent} from '../../atoms/a-tab/a-tab.component';

@Component({
    selector: 'm-tabs',
    templateUrl: './m-tabs.component.html',
    styleUrls: ['./m-tabs.component.scss']
})
export class MTabsComponent {

  @Input()
  public verticalMode: boolean;

  public tabs: ATabComponent[] = [];

  public addTab(tab: ATabComponent): void {
    if (this.tabs.length === 0) {
      this.selectTab(tab);
    }
    this.tabs.push(tab);
  }

  public selectTab(tab: ATabComponent): void {
    if (!tab.disabled) {
      this.tabs.map(t => t.selected = false);
      tab.selected = true;
    }
  }

  public removeTab(tab: ATabComponent): void {
    const index = this.tabs.indexOf(tab);
    if (index > -1) {
      this.tabs.splice(index, 1);
    }
  }
}
