import {Component, Input} from '@angular/core';

@Component({
    selector: 'a-button-text',
    templateUrl: './a-button-text.component.html',
    styleUrls: ['./a-button-text.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
    host: {class: 'grid-x align-middle'}
})
export class AButtonTextComponent {

  @Input()
  labelKeyI18nParams: any;

  @Input()
  labelKey: string;

  @Input()
  loading: boolean = false;
}
