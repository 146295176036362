import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'a-button-next',
  templateUrl: './a-button-next.component.html',
  styleUrls: ['./a-button-next.component.scss']
})
export class AButtonNextComponent {

  @Output()
  public btnClick = new EventEmitter<void>();

  @Input()
  public loading: false;

  @Input()
  public labelKey: string;

  @Input()
  public showBackIcon: boolean = true;

  @Input()
  public expanded: boolean = false;

}
