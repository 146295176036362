import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';

export class CompanySearchCriteria extends PaginatedCriteria {

  constructor(public page?: number, public step?: number, public q?: string) {
    super(page, step);
  }

  public toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.q) {
      params = params.set('q', this.q);
    }

    return params;
  }
}
