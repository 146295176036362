import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {agentRoles, Role} from '../../../../models/entity/enums/Role';

@Component({
  selector: 'a-select-agent-role',
  templateUrl: './a-select-agent-role.component.html',
  styleUrls: ['./a-select-agent-role.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectAgentRoleComponent),
      multi: true
    }
  ]
})
export class ASelectAgentRoleComponent implements ControlValueAccessor {

  public rolesWithLabel: { label: string; role: Role }[];
  public selectedRole: { label: string; role: Role };
  private onNgChange: (value: Role) => void;
  private onNgTouched: () => void;

  @Input()
  public required: boolean;

  @Input()
  public name: string = 'role';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean;

  constructor(private translateService: TranslateService) {
    this.rolesWithLabel = agentRoles().map(role =>  {
      return {label: translateService.instant('user.role.' + role), role: role} ;
    });
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(role: Role): void {
    this.selectedRole = role ?
      {label: this.translateService.instant('user.role.' + role), role: role} : undefined;
  }

  public onChange(roleWithLabel: { label: string; role: Role }): void {
    this.selectedRole = roleWithLabel;
    roleWithLabel ? this.onNgChange(roleWithLabel.role) : this.onNgChange(undefined);
  }

  compare(role1: { label: string; role: Role },
          role2: { label: string; role: Role }): boolean {
    return role1 != null && role2 != null && role1.role === role2.role;
  }
}
