import {Entity} from '../common/Entity';
import {Type} from 'class-transformer';
import {LoginCustomization} from './LoginCustomization';
import {DashboardCustomization} from './DashboardCustomization';
import {CompanySimple} from '../companies/CompanySimple';
import {User} from '../users/User';
import {WorkspaceParameters} from './WorkspaceParameters';

export class WorkspaceModification extends Entity {

  public code?: string;
  public name?: string;
  public description?: string;
  @Type(() => LoginCustomization)
  public loginCustomization?: LoginCustomization  = new LoginCustomization();
  @Type(() => DashboardCustomization)
  public dashboardCustomization?: DashboardCustomization = new DashboardCustomization();
  @Type(() => CompanySimple)
  public initialCompany: CompanySimple;
  @Type(() => User)
  public initialAgentAdmin: User;
  @Type(() => WorkspaceParameters)
  public parameters?: WorkspaceParameters = new WorkspaceParameters();

  public static new(): WorkspaceModification {
    const workspaceModification = new WorkspaceModification();
    workspaceModification.loginCustomization = new LoginCustomization();
    workspaceModification.dashboardCustomization = new DashboardCustomization();
    workspaceModification.initialCompany = new CompanySimple();
    workspaceModification.initialAgentAdmin = null;
    return workspaceModification;
  }
}
