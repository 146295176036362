import {Entity} from '../common/Entity';
import {ContextualWorkspace} from './ContextualWorkspace';

export class WorkspaceSimple extends Entity {

  public code: string;
  public name: string;

  public static fromContextualWorkspace(contextualWorkspace: ContextualWorkspace): WorkspaceSimple {
    const workspaceSimple = new WorkspaceSimple();
    workspaceSimple.id = contextualWorkspace.id;
    workspaceSimple.code = contextualWorkspace.code;
    workspaceSimple.name = contextualWorkspace.name;
    return workspaceSimple;
  }
}
