import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {FeedbackSearchResult} from '../../../shared/models/entity/feedbacks/FeedbackSearchResult';
import {FeedbackService} from '../../services/feedback.service';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class WorkspaceFeedbacksResolver extends AbstractResolver<Array<FeedbackSearchResult>> {

  constructor(spinnerService: SpinnerService, private feedbackService: FeedbackService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<FeedbackSearchResult>> {
      return this.feedbackService.findCurrent().pipe(take(1));
  }
}
