import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CompanySimple} from '../../../../models/entity/companies/CompanySimple';

@Component({
  selector: 'a-select-company-id',
  templateUrl: './a-select-company-id.component.html',
  styleUrls: ['./a-select-company-id.component.scss'],
  styles: [':host {display: block}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectCompanyIdComponent),
      multi: true
    }
  ]
})
export class ASelectCompanyIdComponent implements ControlValueAccessor {

  @Input()
  public placeholder: string = '';

  private _companies: Array<CompanySimple>;
  public displayedCompanies: Array<CompanySimple>;

  @Input()
  get companies(): Array<CompanySimple> {
    return this._companies;
  }

  set companies(companies: Array<CompanySimple>) {
    this._companies = companies;
    this.displayedCompanies = companies || [];
    this.displayedCompanies.sort((c1, c2) => c1.name < c2.name ? -1 : 1);
  }

  public selectedCompany: CompanySimple;

  private onNgChange: (value: string) => void;

  private onNgTouched: () => void;

  @Input()
  public clearable: boolean = true;

  constructor() {}

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(companyId: string): void {
    this.selectedCompany = companyId ? this.companies.find(company => company.id === companyId) : undefined;
  }

  public onChange(company: CompanySimple): void {
    this.selectedCompany = company;
    this.onNgChange(company ? company.id : undefined);
  }

  compare(company1: CompanySimple, company2: CompanySimple) {
    return company1 != null && company2 != null && company1.id === company2.id;
  }
}
