import {Component, Input} from '@angular/core';
import {buildEntityAttachmentUrls} from '../../../models/entity/enums/SharedCollection';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'a-attachment-image-overlay',
  templateUrl: './a-attachment-image-overlay.component.html',
  styleUrls: ['./a-attachment-image-overlay.component.scss']
})
export class AAttachmentImageOverlayComponent {

  public url: string;

  private _attachment: Attachment;

  @Input()
  public overlay: boolean = false;

  get attachment(): Attachment {
    return this._attachment;
  }

  @Input()
  set attachment(value: Attachment) {
    this._attachment = value;
    if (value && !value.localFileUrl) {
        this.url = buildEntityAttachmentUrls(value.targetRef.collection, value.targetRef.id, value.id).getPublicUrl();
      }
    }
}
