import {Type} from 'class-transformer';
import {CompleteMessage} from '../requests/CompleteMessage';
import {CompanySimple} from "../companies/CompanySimple";

export class TransferData {
  public transferredRequestRef: string;
  public originalCompanyName: string;
  public originalWorkspaceName: string;
  @Type(() => CompanySimple)
  public desiredCompany: CompanySimple;
  @Type(() => CompleteMessage)
  public messages: Array<CompleteMessage>;
}
