import {Component, Input} from '@angular/core';
import {fromPositionIcon, fromSize, fromVisibility, PositionIcon, Size, Visibility} from '../links-utils';

@Component({
    selector: 'a-link-icon',
    templateUrl: './a-link-icon.component.html',
    styleUrls: [
        '../links.scss',
        './a-link-icon.component.scss'
    ]
})
export class ALinkIconComponent {

  @Input()
  public disabled: boolean = false;

  public fromVisibility = fromVisibility;
  public fromPositionIcon = fromPositionIcon;
  public fromSize = fromSize;

  @Input()
  public visibility: Visibility = 'MEDIUM';

  @Input()
  public positionIcon: PositionIcon = 'LEFT';

  @Input()
  public size: Size = 'DEFAULT';
}
