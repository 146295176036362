import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {StepFormComponent} from '../step-form-component';
import {FormStep} from '../m-multi-step-form/m-multi-step-form.component';

@Component({
  selector: 'm-form-step-detail',
  templateUrl: './m-form-step-detail.component.html',
  styleUrls: ['./m-form-step-detail.component.scss']
})
export class MFormStepDetailComponent implements AfterViewInit, OnChanges {

  @Input()
  public formStep: FormStep;

  @Input()
  public data: any;

  @Input()
  public isFirstStep: boolean;

  @Input()
  public isLastStep: boolean;

  @Input()
  public isValidationStep: boolean;

  @ViewChild('component', {static: false, read: ViewContainerRef})
  public injectionElement: ViewContainerRef;

  @Output()
  public dataChange: EventEmitter<any> = new EventEmitter();

  @Output()
  public previous: EventEmitter<any> = new EventEmitter();

  @Output()
  public next: EventEmitter<any> = new EventEmitter();

  @Output()
  public validate: EventEmitter<any> = new EventEmitter();

  @Output()
  public reset: EventEmitter<any> = new EventEmitter();

  public validationState: boolean;
  public hiddenButtons: boolean;
  public isConfirmReset: boolean;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formStep && changes.formStep.currentValue && this.injectionElement) {
      this.loadComponent();
    }
  }

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  public loadComponent(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.formStep.component);
    this.injectionElement.clear();
    const componentRef = this.injectionElement.createComponent(componentFactory);

    (<StepFormComponent>componentRef.instance).data = this.data;
    (<StepFormComponent>componentRef.instance).dataChange = this.dataChange;
    (<StepFormComponent>componentRef.instance).dataInitiated();
    (<StepFormComponent>componentRef.instance).validationState.asObservable()
      .subscribe(validationState => this.validationState = validationState);
    (<StepFormComponent>componentRef.instance).hideParentFormButtons.asObservable()
      .subscribe(hide => this.hiddenButtons = hide);
  }

  public onPrevious(): void {
    this.previous.emit();
  }

  public onNext(): void {
    this.next.emit();
  }

  public onValidate(): void {
    this.validate.emit();
  }

  public onReset(): void {
    this.isConfirmReset = true;
  }

  public confirmReset(): void {
    this.reset.emit();
    this.isConfirmReset = false;
  }

  public cancelReset(): void {
    this.isConfirmReset = false;
  }
}

