import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';

@Component({
  selector: 'a-input-mail-administration-validator',
  templateUrl: './a-input-mail-administration-validator.component.html',
  styleUrls: ['./a-input-mail-administration-validator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AInputMailAdministrationValidatorComponent),
      multi: true
    }
  ]
})
export class AInputMailAdministrationValidatorComponent implements ControlValueAccessor, AfterViewInit {

  @Input()
  public inputType: string = 'text';

  @Input()
  public name: string;

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public displayLink: boolean = false;

  @Input()
  public placeholder: string = '';

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public formSubmitted: boolean;

  public hasIcon: boolean;

  @Input()
  public delete: boolean;

  @Input()
  public faIcon: string;

  @Input()
  public max: number;

  @Input()
  public min: number;

  @Input()
  public initialData: string;

  public data: string;

  private onNgChange: (value: string) => void;
  private onNgTouched: () => void;

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('email') inputField: ElementRef;
  @ViewChild('email') inputNgModel: NgModel;
  @Input() public focus: boolean = false;

  ngAfterViewInit() {
    if (this.focus) {
      this.inputField.nativeElement.focus();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(obj: string): void {
    this.data = obj;
  }

  public onChange(data: string): void {
    this.data = data;
    this.onNgChange(data);
  }

  public onDelete(): void {
    this.deleted.emit();
  }
}
