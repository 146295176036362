import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FeedbackSearchResult} from '../../../models/entity/feedbacks/FeedbackSearchResult';
import {FeedbackService} from '../../../../app-root/services/feedback.service';
import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {noop, of} from 'rxjs';
import {AlertService} from '../../../../app-root/services/alert.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'tr[m-feedback]',
  templateUrl: './m-feedback.component.html',
  styleUrls: ['./m-feedback.component.scss']
})
export class MFeedbackComponent {

  @Input()
  public feedback: FeedbackSearchResult;

  @Output()
  public feedbackListReloading = new EventEmitter();

  constructor(private feedbackService: FeedbackService,
              private router: Router,
              private notificationService: NotificationService,
              private alertService: AlertService,
              private translateService: TranslateService) {}

  public onDeleteFeedback(): void {
    this.alertService.confirmWithCustomBtn(
      this.translateService.instant('feedback.popup.delete.title'),
      this.translateService.instant('feedback.popup.delete.message'),
      this.translateService.instant('action.validate'),
      this.translateService.instant('action.cancel')
    ).subscribe(
      () => this.deleteFeedback(),
      () => noop()
    );
  }

  public deleteFeedback(): void {
    this.feedbackService.delete(this.feedback.id)
      .pipe(
        tap(() => {
            this.notificationService.success('notification.feedback.deleted');
            this.feedbackListReloading.emit();
          }
        ),
        catchError( () => {
          this.notificationService.error('notification.feedback.error.deleted');
          return of(null);
        } )
      ).subscribe();
  }
}
