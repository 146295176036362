import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {SessionService} from '../services/session.service';

@Injectable()
export class HasActiveWorkspaceGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.sessionService.getCurrentUser().pipe(
      map(currentUser => currentUser.activeWorkspace ? true : this.router.createUrlTree(['noWorkspace']))
    );
  }
}
