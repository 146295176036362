import {WorkspaceSimple} from './Workspace';

export class WorkspaceSearchResult extends WorkspaceSimple {

  public description?: string;
  public nbUsers: number;
  public nbCompanies: number;

  public toWorkspaceSimple(): WorkspaceSimple {
    const workspaceSimple = new WorkspaceSimple();
    workspaceSimple.id = this.id;
    workspaceSimple.code = this.code;
    workspaceSimple.name = this.name;
    return workspaceSimple;
  }
}

