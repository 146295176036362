import {Component, OnInit} from '@angular/core';
import {NewsService} from '../../app-root/services/news.service';
import {NewsSearchCriteria} from '../../shared/models/criterias/NewsSearchCriteria';
import {NgxSpinnerService} from 'ngx-spinner';
import {NotificationService} from '../../app-root/services/notification.service';
import {forkJoin} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {TagService} from '../../app-root/services/tag.service';
import {PaginatedNews} from '../../shared/models/entity/paginated/paginated-entities/PaginatedNews';
import {SortOrder} from '../../shared/models/entity/paginated/PaginatedCriteria';
import {DashboardCustomization} from '../../shared/models/entity/workspaces/DashboardCustomization';
import {WorkspaceUserService} from '../../app-root/services/workspace-user.service';
import {SessionService} from '../../app-root/services/session.service';
import {filter, switchMap} from 'rxjs/operators';
import {News} from '../../shared/models/entity/News';
import {WorkspaceUser} from '../../shared/models/entity/users/WorkspaceUser';
import {Company} from '../../shared/models/entity/companies/Company';
import {NewsStatus} from '../../shared/models/entity/enums/NewsStatus';

@Component({
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  public newsList: Array<News>;
  public companyAffectedAgents: Array<WorkspaceUser>;
  public dashboardCustomization: DashboardCustomization;

  public searchCriteria: NewsSearchCriteria = new NewsSearchCriteria();

  public existingTags: string[] = [];

  public company: Company;

  constructor(private newsService: NewsService,
              private spinner: NgxSpinnerService,
              private notificationService: NotificationService,
              private workspaceUserService: WorkspaceUserService,
              private sessionService: SessionService,
              private tagService: TagService,
              route: ActivatedRoute
  ) {
    this.searchCriteria.status = NewsStatus.PUBLISHED;
    this.searchCriteria.sort = 'BY_PRIORITY';
    this.searchCriteria.sortOrder = SortOrder.ASC;
    route.data.subscribe( data => this.dashboardCustomization = data.dashboardHeader);
  }

  ngOnInit(): void {
    this.spinner.show();
    this.sessionService.getCurrentUser().pipe(
      filter(user => !!user && !!user.activeWorkspace),
      switchMap( _ => forkJoin([
        this.workspaceUserService.findAutoAffectedAgentsForCurrentCompany(),
        this.newsService.find(this.searchCriteria, true)
      ]))
    ).subscribe(
      ([companyAffectedAgents, newsList]: [Array<WorkspaceUser>, PaginatedNews]) => {
          this.companyAffectedAgents = companyAffectedAgents;
          this.newsList = newsList.result;
          this.spinner.hide();
        },
        () => {
          this.notificationService.error('dashboard.new.list.search.error');
          this.spinner.hide();
        }
    );
    this.tagService.getExistingTagsForNews().subscribe(tags => this.existingTags = tags);
  }

  public onSelectedTagsChanged(tags: Array<string>): void {
    this.searchCriteria.tags = tags;
    this.spinner.show();
    this.newsService.find(this.searchCriteria, true).subscribe(
      paginatedResult => {
        this.newsList = paginatedResult.result;
        this.spinner.hide();
      },
      () => {
        this.notificationService.error('dashboard.new.list.search.error');
        this.spinner.hide();
      });
  }
}
