import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {PaginatedWorkspaces} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaces';
import {PaginatedAdministrationUsers} from '../../../shared/models/entity/paginated/paginated-entities/PaginatedAdministrationUsers';
import {WorkspaceSimple} from '../../../shared/models/entity/workspaces/Workspace';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';

export enum AdministrationTabFragment {
  users,
  workspaces,
}

@Component({
  templateUrl: './administration-page.component.html',
  styleUrls: ['./administration-page.component.scss']
})
export class AdministrationPageComponent implements OnInit {

  public currentUser: CompleteCurrentUser;
  public initialWorkspaces: PaginatedWorkspaces;
  public initialUsers: PaginatedAdministrationUsers;
  public allWorkspaces: Array<WorkspaceSimple>;
  public tabIndex: number = 0;

  constructor(private sessionService: SessionService,
              private activatedRoute: ActivatedRoute,
              route: ActivatedRoute,
              private router: Router
  ) {
    route.data.subscribe((data: { initialWorkspaces: PaginatedWorkspaces, initialUsers: PaginatedAdministrationUsers }) => {
      this.initialWorkspaces = data.initialWorkspaces;
      this.initialUsers = data.initialUsers;
      this.allWorkspaces = this.initialWorkspaces.result.map( workspace => workspace.toWorkspaceSimple());
    });

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activatedRoute.fragment.subscribe(fragment => this.tabIndex = AdministrationTabFragment[fragment]);
      }
    });
  }

  public ngOnInit(): void {
    this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user);
  }

  public onTabChange(event: MatTabChangeEvent): void {
    this.router.navigate(['/administration'], {fragment: AdministrationTabFragment[event.index]}).then();
  }

  public goToDashboardPage(): void {
    this.router.navigate(['/dashboard']);
  }
}
