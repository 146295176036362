import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-select-fontawesome-icon',
  templateUrl: './a-select-fontawesome-icon.component.html',
  styleUrls: ['./a-select-fontawesome-icon.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectFontawesomeIconComponent),
      multi: true
    }
  ]
})
export class ASelectFontawesomeIconComponent implements ControlValueAccessor {

  public iconClasses: string[] = [
    'fas fa-cogs',
    'fas fa-handshake',
    'fas fa-wheelchair',
    'fas fa-mobile-alt',
    'fas fa-desktop',
    'fas fa-laptop',
    'far fa-calendar-alt',
    'far fa-credit-card',
    'fas fa-battery-half',
    'far fa-file-alt',
    'far fa-building',
    'fas fa-gavel',
    'fas fa-bug',
    'fas fa-arrow-alt-circle-up',
    'fas fa-users',
    'fas fa-balance-scale',
    'far fa-envelope',
    'fas fa-exclamation-triangle',
    'fas fa-question-circle',
    'fas fa-plus-square',
    'fas fa-sign-in-alt',
    'fas fa-sign-out-alt',
    'fas fa-truck',
    'fas fa-pallet',
    'fas fa-list-alt',
    'fas fa-cubes',
    'fas fa-list-ol',
    'fas fa-poll',
    'fab fa-sellsy'
  ];

  public selectedIcon: string;
  private onNgChange: (value: string) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'iconClassSelect';

  @Input()
  public clearable: boolean = true;

  @Input()
  public disabled: boolean;

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(iconClass: string): void {
    this.selectedIcon = iconClass;
  }

  public onChange(iconSelect: string): void {
    this.selectedIcon = iconSelect;
    iconSelect ? this.onNgChange(iconSelect) : this.onNgChange(undefined);
  }

  public compare(iconClass: string, iconClass2: string): boolean {
    return iconClass != null && iconClass2 != null && iconClass === iconClass2;
  }
}
