import {Component} from '@angular/core';
import {PaginatedCriteria} from "../../../../shared/models/entity/paginated/PaginatedCriteria";
import {WorkspaceAgentSearchCriteria} from "../../../../shared/models/criterias/WorkspaceAgentSearchCriteria";
import {SessionService} from "../../../../app-root/services/session.service";
import {ActivatedRoute} from "@angular/router";
import {Company} from "../../../../shared/models/entity/companies/Company";
import {WorkspaceUserService} from "../../../../app-root/services/workspace-user.service";
import {PaginatedWorkspaceUsers} from "../../../../shared/models/entity/paginated/paginated-entities/PaginatedWorkspaceUsers";

@Component({
  templateUrl: './agents-tab.component.html',
  styleUrls: ['./agents-tab.component.scss']
})
export class AgentsTabComponent {

  public defaultStartingPage: number = 0;
  public defaultStep: number = 10;

  public allCompanies: Array<Company>;

  public initialAgents: PaginatedWorkspaceUsers;

  constructor(
    private sessionService: SessionService,
    private workspaceUserService: WorkspaceUserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.data.subscribe(data => {
      this.initialAgents = data['initialAgents'];
      this.allCompanies = data['allCompanies'];
    });
  }

  public reloadAgents(paginatedCriteria?: PaginatedCriteria): void {
    if (paginatedCriteria == null) {
      paginatedCriteria = new PaginatedCriteria(this.defaultStartingPage, this.defaultStep);
    }

    this.workspaceUserService.findAgents(
      WorkspaceAgentSearchCriteria.newEmptyWithPagination(paginatedCriteria.page, paginatedCriteria.step)
    ).subscribe(agents => this.initialAgents = agents);
  }

}
