import {Routes} from '@angular/router';
import {LoginPageComponent} from './pages/login/login-page.component';
import {EmailSentPageComponent} from './pages/email-sent/email-sent-page.component';
import {PasswordPageComponent} from './pages/password/password-page.component';

export const loginRoutes: Routes = [
  { path: '', component: LoginPageComponent },
  { path: 'emailSent', component: EmailSentPageComponent },
  { path: 'password', component: PasswordPageComponent }
];
