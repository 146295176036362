import {Component} from '@angular/core';
import {PaginatedNews} from "../../../../shared/models/entity/paginated/paginated-entities/PaginatedNews";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-news-tab',
  templateUrl: './news-tab.component.html',
  styleUrls: ['./news-tab.component.scss']
})
export class NewsTabComponent {

  public initialNews: PaginatedNews;

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(data => {
      this.initialNews = data['initialNews'];
    });
  }

}
