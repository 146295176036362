import {PaginatedResult} from '../PaginatedResult';
import {Type} from 'class-transformer';
import {ResponseTemplate} from '../../ResponseTemplate';
import {PaginatedMetadata} from '../PaginatedMetadata';

export class PaginatedResponseTemplates extends PaginatedResult {

  @Type(() => ResponseTemplate)
  public result: Array<ResponseTemplate>;

  static emptyResult(): PaginatedResponseTemplates {
    const empty = new PaginatedResponseTemplates(new PaginatedMetadata(0, 0, 0));
    empty.result = [];
    return empty;
  }
}
