import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class NotificationService {

  constructor(private translateService: TranslateService, private toastrService: ToastrService) {}

  public error(key: string, params?: any): void {
    this.toastrService.error(this.translateService.instant(key, params), null, {
      closeButton: true,
      timeOut: null
    });
  }

  public success(key: string, params?: any): void {
    this.toastrService.success(this.translateService.instant(key, params));
  }

  public warning(key: string, params?: any): void {
    this.toastrService.warning(this.translateService.instant(key, params));
  }
}
