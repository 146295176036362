import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {RequestStatus, requestStatuses} from '../../../../models/entity/enums/RequestStatus';

@Component({
  selector: 'a-select-statuses',
  templateUrl: './a-select-statuses.component.html',
  styleUrls: ['./a-select-statuses.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectStatusesComponent),
      multi: true
    }
  ]
})
export class ASelectStatusesComponent implements ControlValueAccessor {

  public statusWithLabel: { label: string; status: RequestStatus }[];
  public selectedStatuses:  { label: string; status: RequestStatus }[];
  private onNgChange: (value: Array<RequestStatus>) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'statuses';

  @Input()
  public disabled: boolean;

  @Input()
  public clearable: boolean = true;

  constructor(private translateService: TranslateService) {
    this.statusWithLabel = requestStatuses().map(status =>  {
      return {label: translateService.instant('request.status.' + status), status: status} ;
    });
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(statuses: Array<RequestStatus>): void {
    this.selectedStatuses = statuses ?
      statuses.map(status => ({label: this.translateService.instant('request.status.' + status), status: status})) : undefined;
  }

  public onChange(statusesWithLabel: { label: string; status: RequestStatus }[]): void {
    this.selectedStatuses = statusesWithLabel;
    statusesWithLabel ? this.onNgChange(statusesWithLabel.map(statusWithLabel => statusWithLabel.status)) : this.onNgChange(undefined);
  }

  public compare(status1: { label: string; status: RequestStatus },
                 status2: { label: string; status: RequestStatus }): boolean {
    return status1 != null && status2 != null && status2.status === status1.status;
  }
}
