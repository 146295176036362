import {Injectable} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Injectable()
export class SpinnerService {
  private spinners: Map<string, number> = new Map<string, number>();

  constructor(private spinnerService: NgxSpinnerService) {}

  public show(name: string) {
    this.add(name, 1);
    this.spinnerService.show(name);
  }

  public hide(name: string) {
    if (this.add(name, - 1) === 0) {
      this.spinnerService.hide(name);
    }
  }

  private add(name, quantity): number {
    let current = this.spinners.get(name);
    current = current ? current : 0;
    let newValue = current + quantity;
    newValue = newValue < 0 ? 0 : newValue;

    this.spinners.set(name, newValue);
    return newValue;
  }
}
