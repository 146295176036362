import {Component, Input} from '@angular/core';
import {Attachment} from '../../../models/entity/Attachment';
import {buildEntityAttachmentUrls} from '../../../models/entity/enums/SharedCollection';

@Component({
  selector: 'a-avatar-attachment',
  templateUrl: './a-avatar-attachment.component.html',
  styleUrls: ['./a-avatar-attachment.component.scss']
})
export class AAvatarAttachmentComponent {

  @Input()
  public sizeAvatar: SizeAvatar = 'DEFAULT';

  @Input()
  public roleAvatar: RoleAvatar = 'DEFAULT';

  public url: string;

  private _avatar: Attachment;

  get avatar(): Attachment {
    return this._avatar;
  }

  @Input()
  set avatar(value: Attachment) {
    this._avatar = value;
    if (value && !value.localFileUrl) {
      this.url = buildEntityAttachmentUrls(value.targetRef.collection, value.targetRef.id, value.id).getUrl();
    }
  }

  @Input()
  label: string;

  public fromSizeAvatar = fromSizeAvatar;
  public fromRoleAvatar = fromRoleAvatar;
}

export type SizeAvatar = 'DEFAULT' | 'LARGE' | 'SMALL' | 'TINY';

export function fromSizeAvatar(sizeAvatar: SizeAvatar): string {
  switch (sizeAvatar) {
    case 'DEFAULT':
      return '_default';
    case 'LARGE':
      return '_large';
    case 'SMALL':
      return '_small';
    case 'TINY':
      return '_tiny';
  }
}

export type RoleAvatar = 'DEFAULT' | 'TAG' | 'USER' | 'AGENT' | 'AGENT_ADMIN' | 'COMPANY';

export function fromRoleAvatar(roleAvatar: RoleAvatar): string {
  switch (roleAvatar) {
    case 'DEFAULT':
      return '_default';
    case 'TAG':
      return '_tag';
    case 'USER':
      return '_user';
    case 'AGENT':
      return '_agent';
    case 'AGENT_ADMIN':
      return '_admin';
    case 'COMPANY':
      return '_company';
  }
}
