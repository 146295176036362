import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NewsStatus, newsStatuses} from '../../../../models/entity/enums/NewsStatus';

@Component({
  selector: 'a-select-news-status',
  templateUrl: './a-select-news-status.component.html',
  styleUrls: ['./a-select-news-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ASelectNewsStatusComponent),
      multi: true
    }
  ]
})
export class ASelectNewsStatusComponent implements ControlValueAccessor {

  public statusWithLabel: { label: string; status: NewsStatus }[];
  public selectedStatus:  { label: string; status: NewsStatus };
  private onNgChange: (value: NewsStatus) => void;
  private onNgTouched: () => void;

  @Input()
  public name: string = 'status';

  @Input()
  public disabled: boolean;

  @Input()
  public clearable: boolean = true;

  constructor(private translateService: TranslateService) {
    this.statusWithLabel = newsStatuses().map(status =>  {
      return {label: translateService.instant('news.status.' + status), status: status} ;
    });
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(status: NewsStatus): void {
    this.selectedStatus = status ?
      {label: this.translateService.instant('request.status.' + status), status: status} : undefined;
  }

  public onChange(statusWithLabel: { label: string; status: NewsStatus }): void {
    this.selectedStatus = statusWithLabel;
    statusWithLabel ? this.onNgChange(statusWithLabel.status) : this.onNgChange(undefined);
  }

  public compare(status1: { label: string; status: NewsStatus },
          status2: { label: string; status: NewsStatus }): boolean {
    return status1 != null && status2 != null && status2.status === status1.status;
  }
}
