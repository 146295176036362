import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'a-button-submit',
    templateUrl: './a-button-submit.component.html',
    styleUrls: ['./a-button-submit.component.scss'],
    // tslint:disable-next-line:no-host-metadata-property
})
export class AButtonSubmitComponent implements OnInit {

  // Expanded host binding
  @HostBinding('class._expanded') isExpanded: boolean = false;

  @Output()
  public btnClick = new EventEmitter<void>();

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public validate: boolean;

  @Input()
  public loading: false;

  @Input()
  public expanded: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public faIcon: string;

  ngOnInit(): void {
    if (this.expanded) {
      this.isExpanded = true;
    }
  }
}
