import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {BaseComponentsModule} from '../shared/lib-components/base.components.module';
import {FormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MatSortModule} from '@angular/material/sort';
import {SharedModule} from '../shared/shared.module';
import {DirectivesModule} from '../shared/directives/directives.module';
import {FeedbacksListPageComponent} from './pages/feedbacks-list/feedbacks-list-page.component';
import {FeedbackDetailsPageComponent} from './pages/feedback-details/feedback-details-page.component';
import {FeedbackCardComponent} from './components/feedback-card/feedback-card.component';
import {QuillModule} from 'ngx-quill';

@NgModule({
  declarations: [
    FeedbacksListPageComponent,
    FeedbackDetailsPageComponent,
    FeedbackCardComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    BaseComponentsModule,
    FormsModule,
    NgxSpinnerModule,
    MatSortModule,
    SharedModule,
    DirectivesModule,
    QuillModule
  ]
})
export class FeedbackModule {
}
