import {Component, OnInit} from '@angular/core';
import {SessionService} from '../../../app-root/services/session.service';
import {NavigationEnd, Router} from '@angular/router';
import {CompleteCurrentUser} from '../../../shared/models/entity/users/CompleteCurrentUser';
import {StatsService} from "../../../app-root/services/stats.service";
import {switchMap, tap} from "rxjs/operators";
import {Stats} from "../../../shared/models/Stats";

class Tab {
  public label: string;
  public badge?: number;
  public link: string;
}

@Component({
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {

  public currentUser: CompleteCurrentUser;

  public route: string;
  public tabIndex: number = 0;

  public tabs: Tab[] = [
    {label: "settings.tabs.information", link: "information"},
    {label: "settings.tabs.agents", link: "agents"},
    {label: "settings.tabs.customization", link: "customization"}
  ];

  constructor(private sessionService: SessionService,
              private statsService: StatsService,
              private router: Router
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route = event.url.split('#')[0].split('/').pop();
        this.selectCurrentTab();
      }
    });
  }

  public ngOnInit(): void {
    this.sessionService.getCurrentUser()
      .pipe(
        tap(user => this.currentUser = user),
        switchMap(_ => this.statsService.getSettings())
      )
      .subscribe(stats => {
        this.tabs = this.getTabs(this.currentUser.isAgentAdmin(), stats);
        this.selectCurrentTab();
      });
  }

  private getTabs(isAgentAdmin: boolean, stats: Stats) {
    const tabs = [
      {label: "settings.tabs.information", link: "information"},
      {label: "settings.tabs.agents", badge: stats.agentsCount, link: "agents"}
    ];
    if (isAgentAdmin) {
      tabs.push(
        {label: "settings.tabs.users", badge: stats.usersCount, link: "users"},
        {label: "settings.tabs.companies", badge: stats.companiesCount, link: "companies"},
        {label: "settings.tabs.news", badge: stats.newsCount, link: "news"},
        {label: "settings.tabs.templates", badge: stats.responseTemplatesCount, link: "templates"},
      );
    }
    tabs.push({label: "settings.tabs.customization", link: "customization"})
    return tabs;
  }

  public selectCurrentTab() {
    this.tabIndex = this.tabs.findIndex(tab => tab.link === this.route);
  }
}
