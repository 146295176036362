import {Component, EventEmitter, Input, Output} from '@angular/core';
import { fromSize, Size } from '../buttons-utils';

@Component({
  selector: 'a-button-round',
  templateUrl: './a-button-round.component.html',
  styleUrls: ['./a-button-round.component.scss']
})
export class AButtonRoundComponent {

  @Output()
  public btnClick = new EventEmitter<UIEvent>();

  @Input()
  public disabled = false;

  @Input()
  public validate: boolean;

  @Input()
  public pulse: boolean;

  @Input()
  public collapse: boolean;

  @Input()
  public hollow = false;

  @Input()
  public white: boolean;

  @Input()
  public faIcon: string;

  @Input()
  public type: 'button' | 'submit' = 'button';

  @Input()
  public size: Size = 'DEFAULT';

  public fromSize = fromSize;

}
