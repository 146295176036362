import {CompleteRequest} from './CompleteRequest';
import {TransferredRequest} from './TransferredRequest';

export class RequestWrapper {
  public type: RequestType;
  public request: CompleteRequest | TransferredRequest;

  constructor(type: RequestType, request: CompleteRequest | TransferredRequest) {
    this.type = type;
    this.request = request;
  }
}

export type RequestType = 'complete' | 'transferred';
