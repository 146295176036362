import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Attachment} from '../../../models/entity/Attachment';

@Component({
  selector: 'm-attachment-preview',
  templateUrl: './m-attachment-preview.component.html',
  styleUrls: ['./m-attachment-preview.component.scss']
})
export class MAttachmentPreviewComponent {

  @Input()
  public file: File | Attachment;

  @Output()
  public removeAttachment: EventEmitter<File | Attachment> = new EventEmitter<File | Attachment>();

  public removeAttachments(file: File | Attachment): void {
    this.removeAttachment.next(file);
  }
}
