import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {
  ServiceDeskAuthToken,
  ServiceDeskAuthTokenWithLogin,
  ServiceDeskSession,
  TokenContext
} from '../../shared/models/ServiceDeskSession';
import {plainToClass} from 'class-transformer';
import {BaererSessionService} from './baerer-session.service';
import {UtilsService} from './utils.service';
import {WorkspaceSimple} from '../../shared/models/entity/workspaces/Workspace';
import {CompleteCurrentUser} from '../../shared/models/entity/users/CompleteCurrentUser';
import {CompanySimple} from '../../shared/models/entity/companies/CompanySimple';
import {Company} from '../../shared/models/entity/companies/Company';
import {ContextualWorkspace} from '../../shared/models/entity/workspaces/ContextualWorkspace';

@Injectable()
export class SessionService {

  // undefined is no value, null is no user connected
  private connectedUser: BehaviorSubject<CompleteCurrentUser> = new BehaviorSubject<CompleteCurrentUser>(undefined);
  private activatedWorkspace: BehaviorSubject<ContextualWorkspace> = new BehaviorSubject<ContextualWorkspace>(undefined);
  private activatedCompany: BehaviorSubject<CompanySimple> = new BehaviorSubject<CompanySimple>(undefined);

  constructor(private router: Router,
              private http: HttpClient,
              private bearerSessionService: BaererSessionService,
              private utilsService: UtilsService) {
    this.fetchCurrentUser().subscribe();
  }

  public fetchCurrentUser(): Observable<CompleteCurrentUser> {
    return this.http.get('api/users/me', {withCredentials: true})
      .pipe(map((principal: any) => plainToClass(CompleteCurrentUser, principal as Object)))
      .pipe(tap((user: CompleteCurrentUser) => {
        this.connectedUser.next(user);
        this.activatedCompany.next(user.activeCompany || undefined);
        this.activatedWorkspace.next(user.contextualWorkspaces.find(ws => ws.id === user.activeWorkspace.id));
      }))
      .pipe(catchError((err: any, caught: Observable<CompleteCurrentUser>) => {
        this.connectedUser.next(null);
        this.activatedCompany.next(null);
        this.activatedWorkspace.next(null);
        return this.connectedUser.asObservable();
      }));
  }

  public authenticate(login: string, password: string): Observable<CompleteCurrentUser> {
    return this.http.get<ServiceDeskAuthToken>('api/users/me/token',
      {
        withCredentials: true,
        headers: new HttpHeaders()
          .append('Authorization', `Basic ${btoa(login + ':' + password)}`)
      })
      .pipe(map((token: any) => plainToClass(ServiceDeskAuthToken, token as Object)))
      .pipe(map((token: ServiceDeskAuthToken) => new ServiceDeskSession(login, token)))
      .pipe(tap((session: ServiceDeskSession) => this.bearerSessionService.store(session)))
      .pipe(switchMap(() => this.fetchCurrentUser()));
  }

  public authenticateFromToken(externalToken: string): Observable<CompleteCurrentUser> {
    let context: TokenContext;
    return this.http.post<ServiceDeskAuthTokenWithLogin>('api/tokenized/users/me/token?token=' + externalToken, {})
      .pipe(
        tap((token: any) => context = token.context),
        map((token: any) => plainToClass(ServiceDeskAuthTokenWithLogin, token as Object)),
        map((token: ServiceDeskAuthTokenWithLogin) =>
          new ServiceDeskSession(token.login, new ServiceDeskAuthToken(token.token, token.timestamp, token.validUntil), context)),
        tap((session: ServiceDeskSession) => this.bearerSessionService.store(session)),
        switchMap(() => this.fetchCurrentUser()));
  }

  public logoutFrom(workspaceCode: string = null): void {
    this.http.delete('api/users/me/token').subscribe(_ => {
      const backUrl = this.bearerSessionService.current()?.context?.backUrl;
      this.clearSession();

      if (!backUrl || !this.utilsService.isUrl(backUrl)) {
        const queryParams = workspaceCode ? {workspaceCode: workspaceCode} : {};
        this.router.navigate(['/login'], {state: { logout: true }, queryParams});
      } else {
        window.location.href = backUrl;
      }
    });
  }

  public clearSession(): void {
    this.bearerSessionService.clear();
    this.connectedUser.next(null);
    this.activatedCompany.next(null);
    this.activatedWorkspace.next(null);
  }

  public activeContext(workspace: WorkspaceSimple, company: CompanySimple): Observable<CompleteCurrentUser> {
    return this.http.post<Company>(`api/users/me/context`, {workspace: workspace, company: company})
      .pipe(
        tap(() => this.router.url === `/workspaces/${workspace.code}/dashboard` ?
          window.location.reload() :
          this.router.navigate(['/workspaces', workspace.code, 'dashboard'])),
        switchMap(() => this.fetchCurrentUser())
      );
  }

  public activeWorkspace(workspaceCode: string, companyCode?: string): Observable<CompleteCurrentUser> {
    return this.http.post<WorkspaceSimple>(`api/users/me/context/workspace`, {code: workspaceCode, companyCode: companyCode})
      .pipe(
        switchMap(() => this.fetchCurrentUser())
      );
  }

  public getCurrentUser(): Observable<CompleteCurrentUser> {
    return this.connectedUser.pipe(filter(v => v !== undefined));
  }

  public getActivatedCompany(): Observable<CompanySimple> {
    return this.activatedCompany.pipe(filter(v => v !== undefined));
  }

  public getActivatedWorkspace(): Observable<ContextualWorkspace> {
    return this.activatedWorkspace.pipe(filter(v => v !== undefined));
  }
}
