import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {BaererSessionService} from './baerer-session.service';
import {NotificationService} from './notification.service';
import {RequestService} from './request.service';
import {SessionService} from './session.service';
import {HttpInterceptorService} from './interceptors/http-interceptor.service';
import {CompanyService} from './company.service';
import {UtilsService} from './utils.service';
import {AttachmentService} from './attachment.service';
import {SecurePipe} from './pipes/secure.pipe';
import {MetricsService} from './metrics.service';
import {PasswordService} from './password.service';
import {NewsService} from './news.service';
import {DelayPipe} from './pipes/delay.pipe';
import {FeedbackService} from './feedback.service';
import {AlertService} from './alert.service';
import {AppService} from './app.service';
import {ClassificationService} from './classification.service';
import {ResourceSecurePipe} from './pipes/resourceSecure.pipe';
import {ResponseTemplateService} from './responseTemplate.service';
import {WorkspaceService} from './workspace.service';
import {AdministrationUserService} from './administration-user.service';
import {StatsService} from './stats.service';
import {SpinnerService} from './spinner.service';

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule
  ],
  providers: [
    NotificationService,
    AlertService,
    SecurePipe,
    NewsService,
    RequestService,
    AttachmentService,
    BaererSessionService,
    SessionService,
    CompanyService,
    WorkspaceService,
    UtilsService,
    MetricsService,
    PasswordService,
    DelayPipe,
    FeedbackService,
    AppService,
    ResponseTemplateService,
    AdministrationUserService,
    ClassificationService,
    StatsService,
    SpinnerService,
    ResourceSecurePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  declarations: [],
})

export class ServicesModule {
}
