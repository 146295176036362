import {Component, Input} from '@angular/core';
import {RequestStatus} from '../../../models/entity/enums/RequestStatus';
import {UtilsService} from '../../../../app-root/services/utils.service';
import {TranslateService} from '@ngx-translate/core';
import {RequestService} from '../../../../app-root/services/request.service';
import {NotificationService} from '../../../../app-root/services/notification.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {AlertService} from '../../../../app-root/services/alert.service';
import {noop, Observable} from 'rxjs';
import {DatePipe} from '@angular/common';
import {SearchResultRequest} from '../../../models/entity/requests/RequestSearchResult';
import {CompleteCurrentUser} from '../../../models/entity/users/CompleteCurrentUser';
import {Role} from '../../../models/entity/enums/Role';

@Component({
  selector: 'tr[m-request]',
  templateUrl: './m-request.component.html',
  styleUrls: ['./m-request.component.scss']
})
export class MRequestComponent {

  public CREATED_REQUEST = RequestStatus.CREATED;
  public ROLE_AGENT = Role.AGENT;
  public ROLE_USER = Role.USER;

  public requestReminderInfo: RequestReminderInfos;
  private _request: SearchResultRequest;

  private _routerLink: string;
  private _queryParams: any;
  get requestUrl(): string {
    return this._routerLink;
  }

  get queryParams(): any {
    return this._queryParams;
  }

  @Input()
  set requestUrl(value: string) {
    const splitUrl = this.splitUrl(value);
    this._routerLink = splitUrl.url;
    this._queryParams = splitUrl.params;
  }

  @Input()
  get request(): SearchResultRequest {
    return this._request;
  }

  set request(request: SearchResultRequest) {
    this._request = request;
    if (request) {
      this.requestReminderInfo = {
        isEnabled: (request.status === RequestStatus.WAITING_FOR_CUSTOMER && request.users.length > 0)
          || (request.users.length === 0 && request.agents.length > 1),
        hoverMsg: request.status !== RequestStatus.WAITING_FOR_CUSTOMER ?
          this.translateService.instant('request.reminder.infos.not.waitingForCustomer') :
            request.users.length === 0 && request.agents.length <= 1 ?
            this.translateService.instant('request.reminder.infos.not.users') : null
      };
    }
  }

  @Input()
  public currentUser: CompleteCurrentUser;

  constructor(private utilsService: UtilsService,
              private translateService: TranslateService,
              private requestService: RequestService,
              private notificationService: NotificationService,
              private spinner: NgxSpinnerService,
              private alertService: AlertService,
              private datePipe: DatePipe) {
  }

  public onSendReminder(): void {
    if (this.requestReminderInfo.isEnabled) { // Manage here because of strange behavior with event.stopPropagation()
      this.beforeSendingReminder().subscribe(
        () => this.sendReminder(),
        () => noop());

    }
  }

  public getRelatedRequestsInfo(): string {
    return this.request.relatedRequests.map((req) => `${req.code}: ${req.topic}`).join('\n');
  }

  private beforeSendingReminder(): Observable<void> {
    if (!this.request.lastReminderDate) {
      return this.alertService.confirmWithCustomBtn(
        this.translateService.instant('request.reminder.alert.title'),
        this.translateService.instant('request.reminder.alert.message.first'),
        this.translateService.instant('action.send'),
        this.translateService.instant('action.cancel'));
    } else {
      return this.alertService.confirmWithCustomBtn(
        this.translateService.instant('request.reminder.alert.title'),
        this.translateService.instant('request.reminder.alert.message',
          {reminderDate: this.datePipe.transform(this.request.lastReminderDate, 'dd/MM/yyyy HH:mm')}),
        this.translateService.instant('action.send'),
        this.translateService.instant('action.cancel'));
    }
  }

  private sendReminder(): void {
    this.spinner.show(this.request.id);
    this.requestService.sendReminder(this.request.id).subscribe(
      () => {
        this.request.lastReminderDate = new Date();
        this.notificationService.success('request.send.reminder.success');
        this.spinner.hide(this.request.id);
      },
      () => {
        this.notificationService.error('request.send.reminder.error');
        this.spinner.hide(this.request.id);
      });
  }

  private splitUrl(urlWithParams: string): SplitUrl {
    const split = urlWithParams.split('?');
    const params = {};
    if (split.length > 1) {
      split[1].split('&').forEach(paramPair => {
        const pair = paramPair.split('=');
        const key =  pair[0];
        let value = null;
        if (pair.length > 1) {
          value = pair[1];
        }
        if (params[key] != null && value != null) {
          if (Array.isArray(params[key])) {
            params[key].push(value);
            value = params[key];
          } else {
            value = [params[key], value];
          }
        }
        params[key] = value;
      });
    }
    return {url: split[0], params: params};
  }
}

export interface RequestReminderInfos {
  isEnabled: boolean;
  hoverMsg: string;
}

interface SplitUrl {
  url: string;
  params: any;
}
