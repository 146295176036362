import {Type} from 'class-transformer';
import {Role} from '../enums/Role';
import {ContextualUser} from './ContextualUser';
import {CompanyWithAutoAffectation} from '../companies/CompanyAutoAffectation';
import {Company} from '../companies/Company';

export class CompleteUser extends ContextualUser {

  @Type(() => Company)
  public activeCompany?: Company;
  @Type(() => CompanyWithAutoAffectation)
  public companies?: Array<CompanyWithAutoAffectation>;

  static newUser(role: Role): CompleteUser {
    const user = new CompleteUser();
    user.companies = [];
    user.role = role;
    return user;
  }

  static newUserWithEmail(role: Role, email: string): CompleteUser {
    const user = new CompleteUser();
    user.role = role;
    user.email = email;
    user.companies = [];
    return user;
  }
}
