import {Component} from '@angular/core';
import {SessionService} from '../../app-root/services/session.service';
import {CompleteCurrentUser} from '../../shared/models/entity/users/CompleteCurrentUser';
import {AdministrationUserService} from '../../app-root/services/administration-user.service';

@Component({
  templateUrl: './no-workspace-page.component.html',
  styleUrls: ['./no-workspace-page.component.scss']
})
export class NoWorkspacePageComponent {

  private currentUser: CompleteCurrentUser;
  public adminEmail: string;

  constructor(private sessionService: SessionService, private administrationUserService: AdministrationUserService) {
    this.sessionService.getCurrentUser().subscribe(currentUser => this.currentUser = currentUser);
    this.administrationUserService.getSuperAdminEmail().subscribe(adminEmail => this.adminEmail = adminEmail);
  }

  public logout(): void {
    this.sessionService.logoutFrom(this.currentUser.activeWorkspace?.code);
  }
}
