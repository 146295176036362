import { AfterContentInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from './modal.directive';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'a-popup',
    styleUrls: ['./a-popup.component.scss'],
    templateUrl: './a-popup.component.html'
})
export class APopupComponent implements AfterContentInit {

    @Input()
    public size?: 'full' | 'large' | 'medium' | 'small' | 'tiny' = 'large';
    @Output()
    public closed = new EventEmitter();
    @ViewChild(ModalDirective)
    private modal: ModalDirective;
    private opened: boolean = false;

    constructor(private router: Router) {}

    ngAfterContentInit(): void {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationStart),
                filter((event: NavigationStart) => this.getPath(this.router.url) !== this.getPath(event.url)),
                filter((_: NavigationStart) => this.opened)
            )
            .subscribe(() => this.close());
    }

    private getPath(url: string): string {
        const separatorIndex = url.indexOf('?');
        if (url && separatorIndex > 0) {
            return url.substring(0, separatorIndex);
        }
        return url;
    }

    public open(): void {
        this.modal.open();
        this.opened = true;
    }

    public close(): void {
        this.modal.close();
        this.opened = false;
        this.closed.emit();
    }

    public isOpened(): boolean {
        return this.opened;
    }
}
