import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-breadcrumb-item',
  templateUrl: './a-breadcrumb-item.component.html',
  styleUrls: ['./a-breadcrumb-item.component.scss']
})
export class ABreadcrumbItemComponent {

  @Input()
  public link: string;

  @Input()
  public label: string;

  @Input()
  public current: boolean = false;
}
