import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'a-button-bolt',
  templateUrl: './a-button-bolt.component.html',
  styleUrls: ['./a-button-bolt.component.scss']
})
export class AButtonBoltComponent implements OnInit {

  // Expanded host binding
  @HostBinding('class._expanded') isExpanded: boolean = false;

  @Output() public btnClick = new EventEmitter<void>();

  @Input()
  public labelKey: string;

  @Input()
  public labelKeyI18nParams: any;

  @Input()
  public loading: false;

  @Input()
  public expanded: boolean = false;

  @Input()
  public disabled: boolean = false;

  ngOnInit(): void {
    if (this.expanded) {
      this.isExpanded = true;
    }
  }
}
