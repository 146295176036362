import {Component, EventEmitter, Output} from '@angular/core';
import {ResponseTemplateSearchCriteria} from '../../../models/criterias/ResponseTemplateSearchCriteria';
import {Domain} from '../../../models/entity/classifications/Domain';

@Component({
  selector: 'm-input-search-response-templates',
  templateUrl: './m-input-search-response-templates.component.html',
  styleUrls: ['./m-input-search-response-templates.component.scss']
})
export class MInputSearchResponseTemplatesComponent {

  @Output()
  public searchFired: EventEmitter<ResponseTemplateSearchCriteria> = new EventEmitter<ResponseTemplateSearchCriteria>();

  public searchCriteria: ResponseTemplateSearchCriteria = ResponseTemplateSearchCriteria.empty();

  public filter(): void {
    this.searchFired.emit(this.searchCriteria);
  }

  public onDomainChange(selectedDomain: Domain): void {
    this.searchCriteria.domain = selectedDomain;
    this.searchCriteria.category = null;
  }
}
