import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {WorkspaceService} from '../../services/workspace.service';
import {Injectable} from '@angular/core';
import {DashboardCustomization} from '../../../shared/models/entity/workspaces/DashboardCustomization';
import {AbstractResolver} from '../abstract-resolver';
import {SpinnerService} from '../../services/spinner.service';

@Injectable()
export class CurrentDashboardCustomizationResolver extends AbstractResolver<DashboardCustomization> {

  constructor(spinnerService: SpinnerService, private workspaceService: WorkspaceService) {
    super(spinnerService);
  }

  public doResolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DashboardCustomization> {
      return this.workspaceService.getCurrentDashboardCustomization();
  }
}
