import {PaginatedMetadata} from '../PaginatedMetadata';
import {News} from '../../News';
import {PaginatedResult} from '../PaginatedResult';
import {Type} from 'class-transformer';

export class PaginatedNews extends PaginatedResult {

  @Type(() => News)
  public result: Array<News>;

  static emptyResult(): PaginatedNews {
    const paginatedNews = new PaginatedNews(new PaginatedMetadata(0, 0, 0));
    paginatedNews.result = [];
    return paginatedNews;
  }
}
