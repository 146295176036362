import {Entity} from '../common/Entity';
import {RequestStatus} from '../enums/RequestStatus';
import {RequestPriority} from '../enums/RequestPriority';
import {User} from '../users/User';
import {Type} from 'class-transformer';
import {CompleteCurrentUser} from '../users/CompleteCurrentUser';

export class RequestCommon extends Entity {

  public code?: string;
  public topic?: string;
  public tags: Array<string> = [];
  @Type(() => User)
  public agents: Array<User> = [];
  @Type(() => User)
  public users: Array<User> = [];
  public status: RequestStatus;
  public priority?: RequestPriority;
  public userPriority: RequestPriority;
  public description?: string;
  public assignedToCurrent?: boolean;
  @Type(() => Date)
  public lastStatusChangeDate: Date;
  @Type(() => Date)
  public lastReminderDate?: Date;
  @Type(() => Date)
  public createdDate: Date;

  public getDisplayableLastStatusChangeDate(): Date {
    return this.lastStatusChangeDate ? this.lastStatusChangeDate : this.createdDate;
  }

  public getVisiblePriority(user: CompleteCurrentUser): RequestPriority {
    return user.isUser() ? this.userPriority : this.priority;
  }
}
