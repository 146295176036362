import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {PaginatedCompanies} from '../../shared/models/entity/paginated/paginated-entities/PaginatedCompanies';
import {CompanySearchCriteria} from '../../shared/models/criterias/CompanySearchCriteria';
import {CompanyWithAgents} from '../../shared/models/entity/companies/CompanyWithAgents';
import {Company} from '../../shared/models/entity/companies/Company';

@Injectable()
export class CompanyService {

  private baseUrl = '/api/companies';

  constructor(private http: HttpClient) {}

  public findWithAgentsById(companyId: string): Observable<CompanyWithAgents> {
    return this.http.get<CompanyWithAgents>(`${this.baseUrl}/withAgents/${companyId}`)
      .pipe(map((principal: any) => plainToClass(CompanyWithAgents, principal as Object)));
  }

  public findAllCurrent(): Observable<Array<Company>> {
    return this.http.get<Array<Company>>(`${this.baseUrl}/current`)
      .pipe(map((principal: any) => plainToClass(Company, principal as [Object])));
  }

  public findWithAgentsAttachments(companySearchCriteria: CompanySearchCriteria): Observable<PaginatedCompanies> {
    return this.http.get<PaginatedCompanies>(
      this.baseUrl + '/withAgents?loadAttachments=true',
      {params: companySearchCriteria.toHttpParams()}
    )
      .pipe(map((principal: any) => plainToClass(PaginatedCompanies, principal as Object)));
  }

  public isExistingCodeInWorkspace(code: string, workspaceId: string): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + `/code/${code}/workspaces/${workspaceId}/exists`);
  }

  public save(companyWithAgents: CompanyWithAgents): Observable<CompanyWithAgents> {
    return companyWithAgents.id ? this.update(companyWithAgents) : this.create(companyWithAgents);
  }

  private update(companyWithAgents: CompanyWithAgents): Observable<CompanyWithAgents> {
    return this.http.put<CompanyWithAgents>(this.baseUrl + `/withAgents/${companyWithAgents.id}`, companyWithAgents)
      .pipe(map((principal: any) => plainToClass(CompanyWithAgents, principal as Object)));
  }

  private create(companyWithAgents: CompanyWithAgents): Observable<CompanyWithAgents> {
    return this.http.post<CompanyWithAgents>(this.baseUrl + '/withAgents', companyWithAgents)
      .pipe(map((principal: any) => plainToClass(CompanyWithAgents, principal as Object)));
  }
}
