import {Role} from '../enums/Role';
import {Type} from 'class-transformer';
import {WorkspaceSimple} from './Workspace';
import {CompanyWithAutoAffectationSimple} from '../companies/CompanyAutoAffectation';
import {WorkspaceParameters} from './WorkspaceParameters';

export class ContextualWorkspace extends WorkspaceSimple {
  public role: Role;
  public disabled: boolean;
  @Type(() => CompanyWithAutoAffectationSimple)
  public companies: Array<CompanyWithAutoAffectationSimple> = [];
  public parameters?: WorkspaceParameters;
}
