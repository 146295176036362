import {Component, Input} from '@angular/core';

@Component({
  selector: 'a-avatar',
  templateUrl: './a-avatar.component.html',
  styleUrls: ['./a-avatar.component.scss']
})
export class AAvatarComponent {

  @Input()
  public sizeAvatar: SizeAvatar = 'DEFAULT';

  @Input()
  public roleAvatar: RoleAvatar = 'DEFAULT';

  private _userAvatar: string;

  @Input()
  get userAvatar() {
    return this._userAvatar;
  }

  set userAvatar(userAvatar: string) {
    this._userAvatar = userAvatar && !userAvatar.startsWith('data:') ? 'data:image/png;base64, ' + userAvatar : userAvatar;
  }

  @Input()
  label: string;

  public fromSizeAvatar = fromSizeAvatar;
  public fromRoleAvatar = fromRoleAvatar;
}

export type SizeAvatar = 'DEFAULT' | 'LARGE' | 'SMALL' | 'TINY';

export function fromSizeAvatar(sizeAvatar: SizeAvatar): string {
  switch (sizeAvatar) {
    case 'DEFAULT':
      return '_default';
    case 'LARGE':
      return '_large';
    case 'SMALL':
      return '_small';
    case 'TINY':
      return '_tiny';
  }
}

export type RoleAvatar = 'DEFAULT' | 'TAG' | 'USER' | 'AGENT' | 'AGENT_ADMIN' | 'COMPANY';

export function fromRoleAvatar(roleAvatar: RoleAvatar): string {
  switch (roleAvatar) {
    case 'DEFAULT':
      return '_default';
    case 'TAG':
      return '_tag';
    case 'USER':
      return '_user';
    case 'AGENT':
      return '_agent';
    case 'AGENT_ADMIN':
      return '_admin';
    case 'COMPANY':
      return '_company';
  }
}
