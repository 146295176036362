import {NgModule} from '@angular/core';
import {EmailValidValidatorDirective} from './email-valid.validator.directive';
import {UniqueEmailValidatorDirective} from './unique-email.validator.directive';
import {DndDirective} from './dnd.directive';
import {CodeValidValidatorDirective} from './code-valid.validator.directive';
import {UniqueCompanyCodeValidatorDirective} from './unique-company-code.validator.directive';
import {UniqueTemplateNameValidatorDirective} from './unique-template-name.validator.directive';
import {UniqueWorkspaceCodeValidatorDirective} from './unique-workspace-code.validator.directive';
import {UniqueEmailAdministrationValidatorDirective} from './unique-email-administration.validator.directive';
import {I18nTranslateDirective} from './i18n-translate.directive';

const directives = [
  EmailValidValidatorDirective,
  CodeValidValidatorDirective,
  UniqueTemplateNameValidatorDirective,
  UniqueEmailAdministrationValidatorDirective,
  UniqueEmailValidatorDirective,
  UniqueCompanyCodeValidatorDirective,
  UniqueWorkspaceCodeValidatorDirective,
  DndDirective,
  I18nTranslateDirective
];

@NgModule({
  imports: [],
  exports: [directives],
  declarations: [directives],
  providers: [],
})
export class DirectivesModule {
}
