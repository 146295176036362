import {PaginatedResult} from '../PaginatedResult';
import {CompanyWithAgents} from '../../companies/CompanyWithAgents';
import {Type} from 'class-transformer';
import {PaginatedMetadata} from '../PaginatedMetadata';

export class PaginatedCompanies extends PaginatedResult {

  @Type(() => CompanyWithAgents)
  public result: Array<CompanyWithAgents>;

  static emptyResult(): PaginatedCompanies {
    const paginatedCompanies = new PaginatedCompanies(new PaginatedMetadata(0, 0, 0));
    paginatedCompanies.result = [];
    return paginatedCompanies;
  }
}
