import {AbstractControl, AsyncValidator, AsyncValidatorFn, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Directive, Injectable, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {CompanyService} from '../../app-root/services/company.service';

@Directive({
  selector: '[uniqueCompanyCodeContext]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueCompanyCodeValidatorDirective, multi: true}]
})
@Injectable()
export class UniqueCompanyCodeValidatorDirective implements AsyncValidator {

  @Input('uniqueCompanyCodeContext') initialCodeContext: CompanyCodeContext;

  constructor(private companyService: CompanyService) {
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.existingCompanyCode(this.companyService)(control);
  }

  existingCompanyCode(companyService: CompanyService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!this.initialCodeContext.workspaceId) {
        return of(null);
      } else {
        return companyService.isExistingCodeInWorkspace(control.value, this.initialCodeContext.workspaceId)
            .pipe(first())
            .pipe(
                map((isExisting: boolean) => {
                  if (isExisting && this.initialCodeContext.initialCode !== control.value) {
                    const error = {};
                    error['alreadyExistingCode'] = true;
                    return error;
                  }
                  return null;
                })
            );
      }
    };
  }
}

export class CompanyCodeContext {
  public initialCode?: string;
  public workspaceId?: string;
}
