import {HttpParams} from '@angular/common/http';
import {PaginatedCriteria} from '../entity/paginated/PaginatedCriteria';

export class AdministrationUserSearchCriteria extends PaginatedCriteria {

  public q?: string;
  public workspaceId?: string;

  public toHttpParams(): HttpParams {
    let params = super.toHttpParams();

    if (this.q) {
      params = params.set('q', this.q);
    }

    if (this.workspaceId) {
      params = params.set('workspaceId', this.workspaceId);
    }

    return params;
  }
}
