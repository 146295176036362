import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ALinkCancelComponent } from './a-link-cancel/a-link-cancel.component';
import { ALinkDefaultComponent } from './a-link-default/a-link-default.component';
import { ALinkIconComponent } from './a-link-icon/a-link-icon.component';

const components = [
    ALinkCancelComponent,
    ALinkDefaultComponent,
    ALinkIconComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: components,
    exports: components
})
export class LinksModule {
}
