import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'delay'
})
export class DelayPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    if (value == null || value === '0') {
      return this.translateService.instant('metrics.undefined');
    }

    const totalMinutes = +value;
    const days = Math.floor(totalMinutes / 1440);
    const daysMinutesRem = totalMinutes % 1440;
    const hours = Math.floor(daysMinutesRem / 60);
    const minutes = daysMinutesRem % 60;

    const strArr = [];
    if (days !== 0) {
      strArr.push(this.translateService.instant('delay.days', {days: days}));
    }
    if (hours !== 0) {
      strArr.push(this.translateService.instant('delay.hours', {hours: hours}));
    }
    if (minutes !== 0) {
      strArr.push(this.translateService.instant('delay.minutes', {minutes: minutes}));
    }

    return strArr.join(' ').toString();
  }
}
