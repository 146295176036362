import {Component, Input} from '@angular/core';
import {CompleteFeedback} from '../../../shared/models/entity/feedbacks/CompleteFeedback';

@Component({
  selector: 'feedback-card',
  templateUrl: './feedback-card.component.html',
  styleUrls: ['./feedback-card.component.scss']
})
export class FeedbackCardComponent {

  @Input()
  public feedback: CompleteFeedback;

}
