import { Component, Input } from '@angular/core';
import { fromSize, Size } from '../links-utils';

@Component({
    selector: 'a-link-cancel',
    templateUrl: './a-link-cancel.component.html',
    styleUrls: [
        '../links.scss',
        './a-link-cancel.component.scss'
    ]
})
export class ALinkCancelComponent {

  @Input()
  public disabled: boolean = false;

  @Input()
  public size: Size = 'DEFAULT';

  public fromSize = fromSize;

}
