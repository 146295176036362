import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponseTemplate} from '../../../shared/models/entity/ResponseTemplate';

@Component({
  templateUrl: './response-template-details-page.component.html',
  styleUrls: ['./response-template-details-page.component.scss']
})
export class ResponseTemplateDetailsPageComponent implements OnInit {

  private workspaceCode: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  @Input()
  public responseTemplate: ResponseTemplate;

  ngOnInit(): void {
    this.route.data.subscribe(data => this.responseTemplate = data.responseTemplate);
    this.route.parent.data.subscribe(data => this.workspaceCode = data.workspace.code);
  }

  public goToSettingsPage(): void {
    this.router.navigate(['workspaces', this.workspaceCode, 'settings', 'templates']);
  }
}
