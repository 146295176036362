import {Component, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FeedbackSearchResult} from '../../../shared/models/entity/feedbacks/FeedbackSearchResult';
import {Sort} from '@angular/material/sort';
import {UtilsService} from '../../../app-root/services/utils.service';
import {FeedbackService} from '../../../app-root/services/feedback.service';
import {SessionService} from "../../../app-root/services/session.service";
import {CompleteCurrentUser} from "../../../shared/models/entity/users/CompleteCurrentUser";

@Component({
  templateUrl: './feedbacks-list-page.component.html',
  styleUrls: ['./feedbacks-list-page.component.scss']
})
export class FeedbacksListPageComponent implements OnInit {

  public currentUser: CompleteCurrentUser;
  public reloadMenuCounter: EventEmitter<void> = new EventEmitter<void>();
  private feedbackList: Array<FeedbackSearchResult>;
  public sortedFeedbackList: Array<FeedbackSearchResult>;

  constructor(private sessionService: SessionService,
              private route: ActivatedRoute,
              private utilsService: UtilsService,
              private router: Router,
              private feedbackService: FeedbackService
  ) {
    this.route.data.subscribe((data: { feedbackList: Array<FeedbackSearchResult> }) => {
      this.feedbackList = data.feedbackList;
      this.sortedFeedbackList = data.feedbackList;
    });
  }

  ngOnInit() {
    this.sessionService.getCurrentUser().subscribe(user => this.currentUser = user)
  }

  public sortFeedbackList(sort: Sort): void {
    const feedbackList = this.feedbackList;
    if (!sort.active || sort.direction === '') {
      this.sortedFeedbackList = feedbackList;
      return;
    }

    this.sortedFeedbackList = feedbackList.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'senderEmail': return this.utilsService.compareStrings(a.senderEmail, b.senderEmail, isAsc);
        case 'topic': return this.utilsService.compareStrings(a.topic, b.topic, isAsc);
        case 'createdDate': return this.utilsService.compareDate(a.createdDate, b.createdDate, isAsc);
        default: return 0;
      }
    });
  }

  public goToFeedbackPage(feedbackId: string): void {
    this.router.navigate(['workspaces', this.currentUser.activeWorkspace.code, 'feedbacks', feedbackId]);
  }

  public reloadFeedbacks(): void {
      this.feedbackService.findCurrent().subscribe(data => {
        this.feedbackList = data;
        this.sortedFeedbackList = data;
      });
  }
}
